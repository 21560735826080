<template>

  <body class="logged-in uc-page" style="transform: none;">


  <head-top></head-top>

  <main style="transform: none;">


    <div class="container mt-2 mt-sm-4" style="transform: none;">

      <div class="row g-2 g-md-3 g-lg-4" style="transform: none;">

        <profile-left></profile-left>

        <div class="col-md-12 col-lg-9" data-sticky-content="">

          <div class="card">
            <div class="card-header mb-2"><h5 class="fw-bold mb-0">下载记录</h5></div>

            <div class="card-body">
              <div class="card-header mb-2">最近20条</div>
              <div class="list-group">				<a target="_blank" href="https://hanbaoyx.com/7363/" class="list-group-item list-group-item-action">
                <div class="d-block d-md-flex w-100 justify-content-between">
                  <h6 class="mb-1">无主之地2：高清重置版/Borderlands Game of the Year Enhanced</h6>
                  <small class="text-muted">2024-05-15 08:57</small>
                </div>
                <small class="text-muted d-block d-md-inline-block">下载IP：112.10.201.144</small>
                <small class="text-muted">下载次数：3</small>
              </a>
              </div>	</div>

          </div>
        </div>
      </div>

    </div>

  </main>

  </body>
</template>

<script>
import ProfileLeft from "@/components/profileLeft.vue";
import HeadTop from "@/components/headTop.vue";
import http from "@/api/http";
import portal from "@/api/portal";

export default {
  name: "profileBalance",
  components: {HeadTop, ProfileLeft},
  methods: {
    loadCSS(filename) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = require(`../assets/css/${filename}`);
      link.id = filename;
      document.head.appendChild(link);
    },
    unloadCSS(filename) {
      const link = document.getElementById(filename);
      if (link) {
        document.head.removeChild(link);
      }
    }
  },

  mounted() {
    this.$store.commit('setActiveItem', 4);
    this.loadCSS('a.css');

  },
  beforeDestroy() {
    this.unloadCSS('a.css');
  }
}
</script>

<style scoped>
/*.coin-balance-body {*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  background: linear-gradient(50deg, #fff, #fffde9)*/
/*}*/

/*.coin-pay-card {*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  border: 2px solid rgba(0, 0, 0, .1);*/
/*  border-radius: .5rem;*/
/*  overflow: hidden;*/
/*  background: rgba(0, 0, 0, .03);*/
/*  padding: 1rem .3rem;*/
/*  transition: all .3s ease-in-out*/
/*}*/

/*.balance-qiandao {*/
/*  position: absolute;*/
/*  right: .3rem;*/
/*  top: .3rem*/
/*}*/
/*.uc-page .coin-balance-body .balance-info {*/
/*  text-align: center;*/
/*  color: #ff9800*/
/*}*/
/*.uc-page .coin-balance-body .balance-info hr {*/
/*  margin: .5rem 0*/
/*}*/

</style>