<template>
  <div>

    <header class="bg-light">
      <!-- navbar -->
      <navbar-kit></navbar-kit>
      <!-- end navbar -->
    </header>

    <main style="transform: none;">


      <div class="container mt-2 mt-sm-4" style="transform: none;">

        <div class="row g-2 g-md-3 g-lg-4" style="transform: none;">


          <div class="col-md-12 col-lg-9" data-sticky-content="">


            <div class="card mb-2 mb-md-4">
              <div class="card-header mb-3">
                <h5 class="fw-bold mb-0">会员中心</h5>
              </div>

              <div class="card-body mb-4">
                <div class="d-flex align-items-center mb-3">
                  <div class="me-2">
                    <div class="avatar avatar-xl mb-2">
                      <img class="avatar-img rounded-circle border border-white border-3 shadow" src="//hanbaoyx.com/wp-content/uploads/1234/01/avatar-62af085b18266b98.png" alt="">
                    </div>
                  </div>
                  <div class="ms-2 lh-1">
                    <h5 class="d-flex align-items-center mb-1">
                      kurt<span title="9999-09-09到期" class="badge bg-warning text-warning bg-opacity-10 mb-0 ms-2"><i class="far fa-gem me-1"></i>永久会员</span>				</h5>
                    <div class="mb-1">
                      <span>kurt</span>
                      <span>9999-09-09到期</span>				</div>
                  </div>
                </div>

                <div class="row row-cols-2 row-cols-md-4 g-2 g-md-4">
                  <div class="col">
                    <div class="card bg-success bg-opacity-25 p-4 h-100 rounded-2">
                      <h4 class="fw-bold text-success">40</h4>
                      <span class="h6 mb-0 text-muted">每天可下载数</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card bg-warning bg-opacity-25 p-4 h-100 rounded-2">
                      <h4 class="fw-bold text-warning">0</h4>
                      <span class="h6 mb-0 text-muted">今日已用次数</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card bg-info bg-opacity-25 p-4 h-100 rounded-2">
                      <h4 class="fw-bold text-info">40</h4>
                      <span class="h6 mb-0 text-muted">今日剩余次数</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card bg-secondary bg-opacity-25 p-4 h-100 rounded-2">
                      <h4 class="fw-bold text-secondary">0%</h4>
                      <span class="h6 mb-0 text-muted">下载使用率</span>
                    </div>
                  </div>

                </div>
              </div>



              <div class="card-body mb-4">
                <div class="card-header mb-3">
                  <h5 class="fw-bold mb-0">会员开通</h5>
                </div>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item list-group-item-light text-muted">指会员所享有根据选择赞助的会员选项所享有的特殊服务，具体以本站公布的服务内容为准。</li><li class="list-group-item list-group-item-light text-muted">在遵守VIP会员协议前提下，赞助会员在会员有效期内可以享受免费或折扣权限获取资源。</li><li class="list-group-item list-group-item-light text-muted">赞助会员属于虚拟服务，因不支持申请退款。</li><li class="list-group-item list-group-item-light text-muted">VIP可在有效期内补差价升级;在赞助后一周内补差价升级。</li>		</ol>
              </div>

              <div class="card-body">

                <div class="row row-cols-1 row-cols-md-3 g-3 justify-content-center">

                  <div class="col">
                    <div class="price-card text-center">
                      <div class="price-header bg-success bg-opacity-75">

                        <span class="price-plan">包月会员</span>

                        <span class="price-sub"><i class="far fa-gem me-1"></i>会员有效期30天</span>

                      </div>
                      <div class="price-body">
                        <h3 class="price-ammount">9<sup>积分</sup></h3>

                        <p class="price-day">尊享一年会员特权30天</p>

                        <ul class="price-desc">
                          <li>全站游戏免费下载
                          </li>
                          <li>每日刷新20个免费下载次数
                          </li>
                          <li>第一时间获取优质资源</li>
                        </ul>
                      </div>
                      <div class="price-footer">
                        <button class="btn btn-success rounded-pill px-4 js-pay-action" data-id="0" data-type="3" data-info="30" disabled=""><i class="far fa-gem me-1"></i>已获得权限</button>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="price-card text-center">
                      <div class="price-header bg-success bg-opacity-75">

                        <span class="price-plan">一年会员</span>

                        <span class="price-sub"><i class="far fa-gem me-1"></i>会员有效期365天</span>

                      </div>
                      <div class="price-body">
                        <h3 class="price-ammount">18<sup>积分</sup></h3>

                        <p class="price-day">尊享一年会员特权365天</p>

                        <ul class="price-desc">
                          <li>全站游戏免费下载
                          </li>
                          <li>每日刷新20个免费下载次数
                          </li>
                          <li>第一时间获取优质资源</li>
                        </ul>
                      </div>
                      <div class="price-footer">
                        <button class="btn btn-success rounded-pill px-4 js-pay-action" data-id="0" data-type="3" data-info="365" disabled=""><i class="far fa-gem me-1"></i>已获得权限</button>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="price-card text-center">
                      <div class="price-header bg-warning bg-opacity-75">

                        <span class="price-plan">永久会员</span>

                        <span class="price-sub"><i class="far fa-gem me-1"></i>会员有效期永久</span>

                      </div>
                      <div class="price-body">
                        <h3 class="price-ammount">38<sup>积分</sup></h3>

                        <p class="price-day">尊享永久会员特权永久</p>

                        <ul class="price-desc">
                          <li>会员时长：永久
                          </li>
                          <li>全站游戏永久免费下载
                          </li>
                          <li>每日刷新40个免费下载次数
                          </li>
                          <li>第一时间获取优质资源
                          </li>
                          <li>联系网址客服免费领取阿里下载器,不限速下载</li>
                        </ul>
                      </div>
                      <div class="price-footer">
                        <button class="btn btn-warning rounded-pill px-4 js-pay-action" data-id="0" data-type="3" data-info="9999" disabled=""><i class="far fa-gem me-1"></i>已获得权限</button>
                      </div>
                    </div>
                  </div>

                </div>



              </div>

            </div>

            <div class="card mb-2 mb-md-4 vip-cdk-body">
              <div class="card-header mb-3">
                <h5 class="fw-bold mb-0">会员兑换</h5>
              </div>
              <div class="card-body">
                <h5 class="text-center mb-4 text-muted">使用CDK码兑换VIP特权</h5>
                <form class="row" id="vip-cdk-action">
                  <div class="col-12 mb-3">
                    <input type="text" class="form-control" name="cdk_code" placeholder="兑换码/CDK卡号" value="">
                  </div>
                  <div class="col-12 input-group mb-3">
                    <input type="text" class="form-control rounded-2" name="captcha_code" placeholder="验证码">
                    <img id="captcha-img" class="rounded-2 lazy entered loaded" role="button" data-src="https://hanbaoyx.com/wp-content/themes/ripro-v5/assets/img/captcha.png" title="点击刷新验证码" data-ll-status="loaded" src="https://hanbaoyx.com/wp-content/themes/ripro-v5/assets/img/captcha.png">
                  </div>
                  <div class="col-12 mb-3 mt-3 text-center">
                    <input type="hidden" name="action" value="zb_vip_cdk_action">
                    <button type="submit" id="vip-cdk-submit" class="btn btn-danger text-white px-4"><i class="fas fa-gift me-1"></i>立即兑换</button>
                    <a class="btn btn-warning" target="_blank" href="" rel="nofollow noopener noreferrer"><i class="fas fa-external-link-alt me-1"></i>购买CDK</a>

                  </div>
                </form>
              </div>
            </div>

            <div class="card">
              <div class="card-header mb-2">
                VIP获取记录（最近10条）
              </div>

              <div class="card-body pay-vip-log">
                <ul class="list-group mt-2">	            <div class="list-group-item list-group-item-action">
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1">订单类型：永久会员</h6>
                    <small class="text-muted">2024-05-15 08:52</small>
                  </div>
                  <small class="text-muted">支付金额：￥38.00（38积分）</small>

                  <small class="text-muted">支付方式：卡密支付</small>

                </div>
                </ul>	</div>
            </div>


          </div>

        </div>

      </div>

    </main>


    <section class="wrapper__section p-0">
      <div class="wrapper__section__components">
        <home-footer></home-footer>
      </div>
    </section>


    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>
  </div>
</template>

<script>
import NavbarKit from "@/components/navbarKit.vue";
import HomeFooter from "@/components/homeFooter.vue";
import http from "@/api/http";
import portal from "@/api/portal";

export default {
  name: "gameDownLoad",
  components: {HomeFooter, NavbarKit},

  mounted() {
    try {
      http.get2(portal.gameDownLoad + "?gameId=" +this.$route.query.gameId+"&downLoadType="+this.$route.query.downLoadType, {}).catch((data) => {
        console.log(data)
        this.gameDetail = data.data
        console.log('raw2', data.data.articleTextRaw)
      }).then(() => {
        // if(this.gameDetail.mayLikeArticleList[0].id!==''){
        //   this.slickShow()
        // }
      })
    } catch (err) {
      this.$message.error(err);
    }
  }
}
</script>

<style scoped>

</style>