<template>
  <body class="logged-in uc-page" style="transform: none;">

  <head-top></head-top>




  <home-footer></home-footer>

  </body>
</template>

<script>
import HeadTop from "@/components/headTop.vue";
import HomeFooter from "@/components/homeFooter.vue";
import ProfileLeft from "@/components/profileLeft.vue";
import router from "@/router";

export default {
  name: "profileBalance",
  components: { HomeFooter, HeadTop},
  mounted() {
    localStorage.clear();
    router.push({path: "/"})
  },
  beforeDestroy() {
    this.unloadCSS('a.css');
  },
  methods: {
    loadCSS(filename) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = require(`../assets/css/${filename}`);
      link.id = filename;
      document.head.appendChild(link);
    },
    unloadCSS(filename) {
      const link = document.getElementById(filename);
      if (link) {
        document.head.removeChild(link);
      }
    }
  },
}
</script>

<style scoped>
/*.coin-balance-body {*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  background: linear-gradient(50deg, #fff, #fffde9)*/
/*}*/

/*.coin-pay-card {*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  border: 2px solid rgba(0, 0, 0, .1);*/
/*  border-radius: .5rem;*/
/*  overflow: hidden;*/
/*  background: rgba(0, 0, 0, .03);*/
/*  padding: 1rem .3rem;*/
/*  transition: all .3s ease-in-out*/
/*}*/

/*.balance-qiandao {*/
/*  position: absolute;*/
/*  right: .3rem;*/
/*  top: .3rem*/
/*}*/
/*.uc-page .coin-balance-body .balance-info {*/
/*  text-align: center;*/
/*  color: #ff9800*/
/*}*/
/*.uc-page .coin-balance-body .balance-info hr {*/
/*  margin: .5rem 0*/
/*}*/

</style>