<template>

  <router-view></router-view>
</template>

<script>


export default {
  name: 'App',

}
</script>

<style lang="less">

html,body,p{
  margin: 0;
  padding: 0;
}
</style>
