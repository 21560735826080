<template>

  <body class="logged-in uc-page" style="transform: none;">


  <head-top></head-top>

  <main style="transform: none;">


    <div class="container mt-2 mt-sm-4" style="transform: none;">

      <div class="row g-2 g-md-3 g-lg-4" style="transform: none;">

        <profile-left></profile-left>

        <div class="col-md-12 col-lg-9" data-sticky-content="">

          <!-- 工单列表 -->
          <div class="card">
            <div class="card-header mb-4">
              <h5 class="fw-bold mb-0 d-flex align-content-center">工单列表			<a href="https://hanbaoyx.com/user/ticket?action=new" class="badge bg-dark btn-dark bg-opacity-75 ms-2">新建工单</a>
              </h5>
            </div>
            <div class="card-body">

              <div class="row row-cols-2 row-cols-md-4 g-2 g-md-4 mb-4">
                <div class="col">
                  <div class="card text-center bg-primary bg-opacity-25 p-4 h-100 rounded-2">
                    <h4 class="fw-bold text-primary">1</h4>
                    <span class="h6 mb-0 text-muted">待回复</span>
                  </div>
                </div>
                <div class="col">
                  <div class="card text-center bg-success bg-opacity-25 p-4 h-100 rounded-2">
                    <h4 class="fw-bold text-success">0</h4>
                    <span class="h6 mb-0 text-muted">处理中</span>
                  </div>
                </div>
                <div class="col">
                  <div class="card text-center bg-warning bg-opacity-25 p-4 h-100 rounded-2">
                    <h4 class="fw-bold text-warning">0</h4>
                    <span class="h6 mb-0 text-muted">已回复</span>
                  </div>
                </div>
                <div class="col">
                  <div class="card text-center bg-info bg-opacity-25 p-4 h-100 rounded-2">
                    <h4 class="fw-bold text-info">0</h4>
                    <span class="h6 mb-0 text-muted">已关闭</span>
                  </div>
                </div>
              </div>

              <div class="card-header mb-2">最近20条</div>

              <div class="list-group">

                <a href="https://hanbaoyx.com/user/ticket?action=view&amp;id=20" class="ticket-item list-group-item list-group-item-light">
                  <div class="d-flex align-items-sm-center">
                    <div class="avatar flex-shrink-0 me-2">
                      <img class="avatar-img rounded-pill" src="//hanbaoyx.com/wp-content/uploads/1234/01/avatar-62af085b18266b98.png">
                    </div>
                    <div class="w-100">
                      <div class="d-block d-md-flex w-100 justify-content-between">
                        <h6 class="fw-bold mb-1">AAAAAh啊哈哈哈</h6>
                        <small class="text-muted">2024-07-14 16:54</small>
                      </div>
                      <span class="badge bg-opacity-10 text-danger bg-danger">待回复</span>
                      <span class="badge text-muted">资源问题</span>
                    </div>
                  </div>
                </a>

              </div>
            </div>
          </div>

        </div>


        <div class="col-md-12 col-lg-9" data-sticky-content="">

          <!-- 新建工单 -->
          <div class="card">
            <div class="card-header mb-3">
              <h5 class="fw-bold mb-0 d-flex align-content-center">新建工单			<a href="https://hanbaoyx.com/user/ticket?action=list" class="badge bg-dark btn-dark bg-opacity-75 ms-2">返回工单列表</a>
              </h5>
              <hr>
            </div>
            <div class="card-body">
              <form class="row g-4" id="ticket-form">
                <!-- Input item -->
                <div class="col-lg-6">
                  <label class="form-label">创建人</label>
                  <input type="text" class="form-control" value="kurt (kurt)" disabled="">
                </div>
                <!-- Input item -->
                <div class="col-lg-6">
                  <label class="form-label">工单类型</label>
                  <select name="type" class="form-select">
                    <option value="1">资源问题</option><option value="2">会员问题</option><option value="3">网站BUG</option><option value="4">其他问题</option>				</select>

                </div>
                <div class="col-12">
                  <label class="form-label">工单标题</label>
                  <input type="text" class="form-control" name="title" placeholder="" value="">
                </div>
                <!-- Textarea item -->
                <div class="col-12">
                  <label class="form-label">描述</label>
                  <textarea class="form-control" rows="8" name="content"></textarea>
                </div>

                <!-- Save button -->
                <div class="d-sm-flex justify-content-end mt-3">
                  <input type="hidden" name="action" value="zb_user_save_ticket">
                  <button type="submit" id="save-ticket" class="btn btn-dark mb-0">提交工单</button>
                </div>
              </form>
            </div>
          </div>
        </div>



        <div class="col-md-12 col-lg-9" data-sticky-content="">

          <!-- 查看工单详情 -->
          <div class="card">
            <div class="card-header mb-2">
              <h5 class="fw-bold mb-0 d-flex align-content-center">工单详情			<a href="https://hanbaoyx.com/user/ticket?action=list" class="badge bg-dark btn-dark bg-opacity-75 ms-2">返回工单列表</a>
              </h5>
              <hr>
            </div>
            <div class="card-body">


              <div class="mb-3">
                <div class="mb-3 small text-muted d-flex align-items-center flex-wrap">
                	<span class="avatar avatar-xs">
						<img class="avatar-img rounded-circle" src="//hanbaoyx.com/wp-content/uploads/1234/01/avatar-62af085b18266b98.png">
					</span>
                  <span class="ms-1">kurt 2024-07-14 16:54 提交</span>
                  <span class="ms-2">【资源问题】</span>
                  <span class="ms-2">状态：(待回复)</span>
                </div>
                <h5 class="fw-bold mb-2"><i class="fas fa-question-circle me-1"></i>AAAAAh啊哈哈哈</h5>
                <div class="p-2 p-lg-3 bg-info bg-opacity-25 rounded-2">
                  啊啊啊啊啊啊                	                </div>

              </div>
              <div data-v-fa82b46c="" class="mb-3"><label data-v-fa82b46c="" class="form-label">客服回复</label><textarea data-v-fa82b46c="" rows="8" name="content" class="form-control" readonly></textarea></div>

              <div class="d-sm-flex justify-content-end mt-3">
                <a href="https://hanbaoyx.com/user/ticket?action=view&amp;id=20&amp;delete=1" class="delete-ticket btn btn-danger mb-0">删除工单</a>
              </div>



            </div>
          </div>

        </div>
      </div>

    </div>

  </main>

  </body>
</template>

<script>
import ProfileLeft from "@/components/profileLeft.vue";
import HeadTop from "@/components/headTop.vue";

export default {
  name: "profileBalance",
  components: {HeadTop, ProfileLeft},
  methods: {
    loadCSS(filename) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = require(`../assets/css/${filename}`);
      link.id = filename;
      document.head.appendChild(link);
    },
    unloadCSS(filename) {
      const link = document.getElementById(filename);
      if (link) {
        document.head.removeChild(link);
      }
    }
  },
  mounted() {
    this.$store.commit('setActiveItem', 6);
    this.loadCSS('a.css');
  },

  beforeDestroy() {
    this.unloadCSS('a.css');
  }

}
</script>

<style scoped>
/*.coin-balance-body {*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  background: linear-gradient(50deg, #fff, #fffde9)*/
/*}*/

/*.coin-pay-card {*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  border: 2px solid rgba(0, 0, 0, .1);*/
/*  border-radius: .5rem;*/
/*  overflow: hidden;*/
/*  background: rgba(0, 0, 0, .03);*/
/*  padding: 1rem .3rem;*/
/*  transition: all .3s ease-in-out*/
/*}*/

/*.balance-qiandao {*/
/*  position: absolute;*/
/*  right: .3rem;*/
/*  top: .3rem*/
/*}*/
/*.uc-page .coin-balance-body .balance-info {*/
/*  text-align: center;*/
/*  color: #ff9800*/
/*}*/
/*.uc-page .coin-balance-body .balance-info hr {*/
/*  margin: .5rem 0*/
/*}*/

</style>