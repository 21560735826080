<template>
  <div>

  <!-- Form Login -->
  <div class="card mx-auto" style="max-width: 520px; margin-top: 5%; margin-bottom: 5%">
    <div class="card-body">
      <h4 class="card-title mb-4"  align="center">登录</h4>
<!--        <a href="#" class="btn btn-facebook btn-block mb-2 text-white"> <i class="fa fa-facebook"></i> &nbsp; Sign-->
<!--          in-->
<!--          with-->
<!--          Facebook</a>-->
<!--        <a href="#" class="btn btn-primary btn-block mb-4"> <i class="fa fa-google"></i> &nbsp; Sign in with-->
<!--          Google</a>-->
        <el-form :model=loginNormalForm ref="loginNormalForm"  label-width="100px" :rules="rules"  >
          <el-form-item label="用户名" prop="userName" class="form-item-inline">
            <el-input  placeholder="请输入您的用户名" v-model="loginNormalForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="密码"  prop="password"  class="form-item-inline">
            <el-input type="password" placeholder="请输入您的密码" v-model="loginNormalForm.password"></el-input>
          </el-form-item>
          <el-form-item label="图片验证码" prop="picCaptcha" >
              <el-input class="check-code-box"  v-model="loginNormalForm.picCaptcha" placeholder="请输入图片中的验证码" style="display: inline-block;max-width: 230px"></el-input>
              <img :src="captchaUrl" @click="reloadCaptcha" class="img-fluid" alt="CAPTCHA" style="display: inline-block;padding:0;border:0;min-height: 40px;max-width:100px"/>

          </el-form-item>

        </el-form>


        <div class="form-group">
          <a href="#" class="float-right">忘记密码？</a>
          <label class="float-left custom-control custom-checkbox"> <input type="checkbox" class="custom-control-input" checked="">
            <span class="custom-control-label"> 记住密码 </span>
          </label>
        </div> <!-- form-group form-check .// -->
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block"  @click="loginClick"> 登录 </button>
        </div> <!-- form-group// -->
      <p class="text-center mt-4">没有账号? <a href="/register">注册</a></p>
    </div> <!-- card-body.// -->


  </div> <!-- card .// -->



  </div>
</template>

<script>
import http from "@/api/http";
import login from "@/api/login";
import Vue from "vue";

export default {
  name: "login",

  data(){
    return {
      captchaUrl:'/web/portal/api/captcha',
      loginNormalForm:{
        userName:'',
        password:'',
        picCaptcha:'',
      },

      rules:{
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (/\s/.test(value)) {
                callback(new Error('用户名不能包含空格'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
          { pattern: /^[a-zA-Z][a-zA-Z0-9]*$/, message: '用户名只能包含字母和数字，且不能以数字开头', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, max: 30, message: '密码长度需要在 8 到 30 个字符之间', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (/\s/.test(value)) {
                callback(new Error('密码中不能包含空格'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
          { pattern: /^[a-zA-Z0-9@#&]*$/, message: '密码只能包含字母、数字和特殊符号 \'@\'、\'#\'、\'&\' ', trigger: 'blur' },
        ],
        picCaptcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 4, message: '验证码长度为 4 个字符', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9]*$/, message: '验证码只能包含字母和数字', trigger: 'blur' }
        ]

      }
    }
  },

  methods:{
    loginClick(){
      http.get2('/portal/api/verifyCaptcha', {
        captchaCode: this.loginNormalForm.picCaptcha
      }, null).catch(response =>{
        console.log("验证码结果",response.data)
        if (response.data) {
          try {
            http.post(login.loginByPass, {
              userName: this.loginNormalForm.userName,
              password: this.loginNormalForm.password,
              captchaCode: this.loginNormalForm.picCaptcha,
              loginType: "password",
            }, null).then(resp => {
              console.log("登录结果",resp)
              if (resp.code === 200) {
                console.log('321',resp.data)
                localStorage.setItem('AuthorizationToken', resp.data); //
                localStorage.setItem('userNameFromLogin',this.loginNormalForm.userName);
                console.log('123',localStorage.getItem('AuthorizationToken'))
                this.$message.success("登录成功")
                this.$router.push({
                      path: '/',
                      query: {},  // 传递参数，放在url?后面的
                    }
                )
              }
            })
          } catch (err) {
            this.$message.error(err);
            this.reloadCaptcha();
          }
        }  else {
          Vue.prototype.$message.warning('验证码错误,请重试');
          this.reloadCaptcha();
        }
      })
    },


    reloadCaptcha() {
      this.captchaUrl = '/web/portal/api/captcha?' + new Date().getTime();
    }
  }


}
</script>

<style scoped>
.btn-primary {
  color: #fff;
  background-color: #c00;
  border-color: #c00
}

.btn-primary:hover {
  color: #fff;
  background-color: #a60000;
  border-color: #900
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 .2rem rgba(212, 38, 38, 0.5)
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #c00;
  border-color: #c00
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #900;
  border-color: #8c0000
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(212, 38, 38, 0.5)
}
</style>


