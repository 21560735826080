export default {
    state: {
        searchDetail: {
            searchType:'',
            searchText:'',
        },

    },
    mutations: {

        setSearchDetail(state, [val1, val2]){
            state.searchDetail.searchType = val1;
            state.searchDetail.searchText = val2;
        },




    }
}