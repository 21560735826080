import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import router from '../router';
import store from '../store';
import Cookie from "js-cookie";
// 基础配置项
const timeout = 120000; // 请求最大限时
const baseURL = '/web'; // 请求路由前缀
const instance = axios.create({
    baseURL,
    timeout,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
    },
});
const { CancelToken } = axios; // 回调请求中止构造函数
/**
 * 请求拦截
 */
instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('AuthorizationToken');
    console.log("token",token)
    config.headers.Authorization = token ? `${token}` : ''
    return config;
}, (error) => Promise.reject(error));
/**
 * 请求结果拦截
 */
instance.interceptors.response.use((response) => {
    const res = response.data;
    const contentType = response.headers['content-type'];
    // 统一处理错误信息
    if (!res.success && contentType !== 'application/octet-stream') {
        // const mes = res.message;
        console.log(res)
        if (!res.success) {

            // Vue.prototype.$message.error(mes);
            let error = { response };
            console.log(res)
            console.log(error)
            return Promise.reject(error);
        }
    }
    return response;
}, (error) => {
    console.log("qqqqqq",error)
    console.log("ddddd",error.response)
    if (error.response) {
        console.log("ppppp",error.response)
        console.log("eeeee",error.response.status)
        switch (error.response.status) {
            case 401:
                // 如果用户未授权，则重置系统数据并退出
                // router.push({ name: 'LoginTimeOut' });
                // if (window.location.pathname !== '/login') {
                //if (store.state.globalConfig.needLogin) {
                //    store.commit('systemData/resetSystemData');
                //    error.response.data && error.response.data.message && Vue.prototype.$message.error(error.response.data && error.response.data.message);
                //    if (error.response.data && error.response.data.data) {
                //        window.location.href = error.response.data.data;
                //    } else {
                //        window.location.href = process.env.VUE_APP_SSO_URL;
                //    }
                // eslint-disable-next-line no-case-declarations
                const allCookies = Cookie.get();
                for (let cookie in allCookies) {
                    Cookie.remove(cookie);
                }
                Vue.prototype.$message.warning('您的登录状态过期，请重新登录');
                localStorage.clear();
                router.push({path: "/login"})

                // }
                break;
            case 400:
                break;
            case 429:
                Vue.prototype.$message.warning('操作太快了，请稍微休息一下');
                break;
            case 503:
                Vue.prototype.$message.warning('系统繁忙，请稍后重试');
                break;
            default:
        }
    }
    return Promise.reject(error);
});

/**
 * 格式化url
 * @param {string} url 需要格式化的url
 * @param {object} params 请求参数对象
 * @returns {string} 处理过后的url
 */
function formatUrl(url, params) {
    const match = url.match(/\/\:[a-zA-Z]+/g);
    if (match && params) {
        for (const i of match) {
            const key = i.replace('/:', '');
            if (params[key] !== null) {
                // params[key] = params[key].replace(/(^\s*)|(\s*$)/g, '');
                url = url.replace(i, `/${params[key]}`);
                delete params[key];
            }
        }
    }
    // 清除params的""属性
    for (const key in params) {
        if (params[key] === '') {
            delete params[key];
        }
        if (typeof (params[key]) === 'string') {
            params[key] = params[key].replace(/(^\s*)|(\s*$)/g, '');
        }
    }
    return url;
}

/**
 * 错误处理
 * @param {object} res 接口返回结果
 * @param {string} bid 跟踪ID
 */
function errorExec(res) {
    if (!res) return;
    let mes = res.response && res.response.data && res.response.data.message;
    Vue.prototype.$message({
        type: 'error',
        message: mes || '后台处理异常',
        offset: 50
    });
    // 记录错误日志
    const error = {
        route: router.history.current,
        time: new Date(), ...res.response
    };
    store.commit('systemData/addErrorLog', error);
}

export default {
    /**
     * get请求
     * @param {string} url 接口路径
     * @param {object} params 请求参数对象
     * @param {function} cancelCb 函数中止回调
     * @returns {Promise<any>} promise对象
     */
    get(url, params, cancelCb) {
        const _params = { ...params };
        const paramRes = {
            params: _params,
        };
        const bid = VueCookies.get('BID');
        // 如果有取消发送的回调
        if (cancelCb) {
            paramRes.cancelToken = new CancelToken((c) => {
                cancelCb(c);
            });
        }
        return new Promise((resolve, reject) => {
            instance.get(formatUrl(url, _params), paramRes)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    errorExec(err, bid);
                    reject(err.response);
                });
        });
    },
    /**
     * get请求
     * @param {string} url 接口路径
     * @param {object} params 请求参数对象
     * @returns {Promise<any>} promise对象
     */
    get2(url, params) {
        const _params = { ...params };
        const paramRes = {
            params: _params,
        };

        return new Promise((resolve, reject) => {
            instance.get(formatUrl(url, _params), paramRes)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response);
                });
        });
    },
    /**
     * delete请求
     * @param {string} url 接口路径
     * @param {object} params 请求参数对象
     * @returns {Promise<any>} promise对象
     */
    delete(url, params) {
        const _params = { ...params };
        const bid = VueCookies.get('BID');
        return new Promise((resolve, reject) => {
            instance.delete(formatUrl(url, _params), { data: _params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    errorExec(err, bid);
                    reject(err.response);
                });
        });
    },
    /**
     * post请求
     * @param {string} url 接口路径
     * @param {object} params 请求参数对象
     * @param {function} cancelCb 函数中止回调
     * @returns {Promise<any>} promise对象
     */
    post(url, params, cancelCb) {
        let _params = { ...params };
        // 如果有取消发送的回调
        if (cancelCb) {
            _params.cancelToken = new CancelToken((c) => {
                cancelCb(c);
            });
        }
        return new Promise((resolve, reject) => {
            instance.post(formatUrl(url, _params), _params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    errorExec(err);
                    reject(err.response);
                });
        });
    },
    /**
     * put请求
     * @param {string} url 接口路径
     * @param {object} params 请求参数对象
     * @returns {Promise<any>} promise对象
     */
    put(url, params) {
        const _params = { ...params };
        const bid = VueCookies.get('BID');
        return new Promise((resolve, reject) => {
            instance.put(formatUrl(url, _params), _params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    errorExec(err, bid);
                    reject(err.response);
                });
        });
    },
    /**
     * 文件上传请求
     * @param {string} url 接口路径
     * @param {object} data 文件流数据
     * @param sourceToken {string} 上传的标志，主要用来取消当前上传请求
     * @param onUploadProgress {object} 上传进度方法回调
     * @returns {Promise<AxiosResponse<any> | never>} axios返回值
     */
    upload(url, data, sourceToken, onUploadProgress) {
        return axios({
            method: 'post',
            baseURL,
            url,
            data,
            timeout: 60000,
            canceltoken: sourceToken,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                onUploadProgress && onUploadProgress(progressEvent);
            },
        });
    },
    downloadPost(url, params) {
        const _params = { ...params };
        return instance.post(formatUrl(url, _params), _params, { responseType: 'arraybuffer' });
    },
    /**
     * 文件下载
     * @param url 接口地址
     * @param params 参数
     * @param fileName 文件名，如果有文件名，则直接触发下载
     * @param isPrivate 是否自主实现
     * @param privateBaseURL 自定义baseURL
     * @returns [arrayBuffer,boolean]
     */
    download(url, params = {}, fileName = '导出文件.xls', isPrivate, privateBaseURL) {
        return axios({
            method: 'get',
            timeout: 60000,
            url: (privateBaseURL || baseURL) + url,
            params,
            responseType: 'arraybuffer'
        })
            .then((res) => {
                if (isPrivate) {
                    return res;
                }
                // 导出接口 返回非文件流 错误提示处理 —— 返回数据转为json
                let enc = new TextDecoder('utf-8');
                let unit8Msg = new Uint8Array(res.data);
                let returnString = enc.decode(unit8Msg);
                if (returnString.indexOf('message') !== -1 || returnString.indexOf('succes') !== -1) {
                    let returnData = JSON.parse(returnString);
                    if (returnData && !returnData.success && returnData.message) {
                        Vue.prototype.$message.warning(returnData.message ? returnData.message : '导出接口报错');
                        return;
                    }
                }
                const cname = res.headers['content-disposition'] && res.headers['content-disposition'].split(';')[1].split('filename=')[1];
                const name = cname && decodeURIComponent(cname)
                    .replace(/(^['"]|['"]$)/g, '');
                const filename = name || fileName;
                let blob = new Blob([res.data]);
                let link = document.createElement('a');
                let objectUrl = URL.createObjectURL(blob);
                link.href = objectUrl;
                link.download = filename;
                link.click();
                URL.revokeObjectURL(objectUrl);
                return true;
            });
    },
    /**
     * 导入模板下载
     * @param {string} fileName 文件名
     * @param {string} downloadName 下载文件名
     */
    downloadTemp(fileName, downloadName) {
        this.download(`/${fileName}`, null, fileName, true, '/template')
            .then((res) => {
                let blob = new Blob([res.data]);
                let link = document.createElement('a');
                let objectUrl = URL.createObjectURL(blob);
                link.href = objectUrl;
                link.download = downloadName || fileName;
                link.click();
                URL.revokeObjectURL(objectUrl);
            });
    },
};
