<template>

  <body class="logged-in uc-page" style="transform: none;">


  <head-top></head-top>

  <main style="transform: none;">


    <div class="container mt-2 mt-sm-4" style="transform: none;">

      <div class="row g-2 g-md-3 g-lg-4" style="transform: none;">

        <profile-left></profile-left>

        <div class="col-md-12 col-lg-9" data-sticky-content="">


          <div class="card">
            <div class="card-header mb-4">
              <h5 class="fw-bold mb-0">收藏列表</h5>
            </div>
            <div class="card-body">

              <div class="row g-2 g-md-2 row-cols-2 row-cols-md-3">


                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/11743/" title="对马岛之魂：导演剪辑版/Ghost of Tsushima DIRECTOR’S CUT" data-bg="https://media.st.dl.eccdnx.com/steam/apps/2215430/header.jpg?t=1715880413" data-ll-status="loaded" style="background-image: url(&quot;https://media.st.dl.eccdnx.com/steam/apps/2215430/header.jpg?t=1715880413&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8a%a8%e4%bd%9c%e5%86%92%e9%99%a9/">动作冒险</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%83%ad%e9%97%a8%e6%b8%b8%e6%88%8f/">热门游戏</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/11743/" title="对马岛之魂：导演剪辑版/Ghost of Tsushima DIRECTOR’S CUT">对马岛之魂：导演剪辑版/Ghost of Tsushima DIRECTOR’S CUT</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-07-13T12:23:11+08:00">1 天前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>4</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>32</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>15.9K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/9334/" title="消逝的光芒2：人与仁之战（终极版）/Dying Light 2 Stay Human" data-bg="http://n.sinaimg.cn/games/504/w338h166/20190610/1309-hyeztys4879800.jpg" data-ll-status="loaded" style="background-image: url(&quot;http://n.sinaimg.cn/games/504/w338h166/20190610/1309-hyeztys4879800.jpg&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8a%a8%e4%bd%9c%e5%86%92%e9%99%a9/">动作冒险</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%b0%84%e5%87%bb%e6%b8%b8%e6%88%8f/">射击游戏</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/9334/" title="消逝的光芒2：人与仁之战（终极版）/Dying Light 2 Stay Human">消逝的光芒2：人与仁之战（终极版）/Dying Light 2 Stay Human</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-07-05T22:31:50+08:00">1 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>0</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>26</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>10.4K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/12172/" title="光年拓荒/Lightyear Frontier" data-bg="https://shared.st.dl.eccdnx.com/store_item_assets/steam/apps/1677110/header_alt_assets_1.jpg?t=1719475045" data-ll-status="loaded" style="background-image: url(&quot;https://shared.st.dl.eccdnx.com/store_item_assets/steam/apps/1677110/header_alt_assets_1.jpg?t=1719475045&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e4%bc%91%e9%97%b2%e7%9b%8a%e6%99%ba/">休闲益智</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8a%a8%e4%bd%9c%e5%86%92%e9%99%a9/">动作冒险</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/12172/" title="光年拓荒/Lightyear Frontier">光年拓荒/Lightyear Frontier</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-07-05T22:26:08+08:00">1 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>0</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>3</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>1.1K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/9646/" title="城市：天际线2/都市天际线2/Cities: Skylines II" data-bg="https://media.st.dl.eccdnx.com/steam/apps/949230/header_alt_assets_0_schinese.jpg?t=1711388424" data-ll-status="loaded" style="background-image: url(&quot;https://media.st.dl.eccdnx.com/steam/apps/949230/header_alt_assets_0_schinese.jpg?t=1711388424&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e6%a8%a1%e6%8b%9f%e7%bb%8f%e8%90%a5/">模拟经营</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%89%b9%e5%88%ab%e6%8e%a8%e8%8d%90/">特别推荐</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/9646/" title="城市：天际线2/都市天际线2/Cities: Skylines II">城市：天际线2/都市天际线2/Cities: Skylines II</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-07-05T16:31:15+08:00">1 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>0</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>17</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>10.6K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/9577/" title="星空/豪华版/Starfield" data-bg="https://media.st.dl.eccdnx.com/steam/apps/1716740/header.jpg?t=1692798833" data-ll-status="loaded" style="background-image: url(&quot;https://media.st.dl.eccdnx.com/steam/apps/1716740/header.jpg?t=1692798833&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8a%a8%e4%bd%9c%e5%86%92%e9%99%a9/">动作冒险</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%83%ad%e9%97%a8%e6%b8%b8%e6%88%8f/">热门游戏</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/9577/" title="星空/豪华版/Starfield">星空/豪华版/Starfield</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-07-04T16:45:41+08:00">1 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>2</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>20</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>10.0K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/9423/" title="幽灵行者2/Ghostrunner 2" data-bg="https://media.st.dl.eccdnx.com/steam/apps/2144740/header_schinese.jpg?t=1697621135" data-ll-status="loaded" style="background-image: url(&quot;https://media.st.dl.eccdnx.com/steam/apps/2144740/header_schinese.jpg?t=1697621135&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8a%a8%e4%bd%9c%e5%86%92%e9%99%a9/">动作冒险</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%83%ad%e9%97%a8%e6%b8%b8%e6%88%8f/">热门游戏</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/9423/" title="幽灵行者2/Ghostrunner 2">幽灵行者2/Ghostrunner 2</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-06-29T23:23:18+08:00">2 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>0</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>16</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>5.7K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/9304/" title="漫威蜘蛛侠：迈尔斯·莫拉莱斯/Marvel’s Spider-Man: Miles Morales" data-bg="https://media.st.dl.eccdnx.com/steam/apps/1817190/header.jpg?t=1668787110" data-ll-status="loaded" style="background-image: url(&quot;https://media.st.dl.eccdnx.com/steam/apps/1817190/header.jpg?t=1668787110&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8a%a8%e4%bd%9c%e5%86%92%e9%99%a9/">动作冒险</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%89%b9%e5%88%ab%e6%8e%a8%e8%8d%90/">特别推荐</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/9304/" title="漫威蜘蛛侠：迈尔斯·莫拉莱斯/Marvel’s Spider-Man: Miles Morales">漫威蜘蛛侠：迈尔斯·莫拉莱斯/Marvel’s Spider-Man: Miles Morales</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-06-29T23:16:22+08:00">2 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>0</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>11</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>4.8K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/9655/" title="骑马与砍杀2霸主/骑马与砍杀2领主/骑砍2霸主/Mount &amp; Blade II: Bannerlord" data-bg="https://media.st.dl.eccdnx.com/steam/apps/261550/header.jpg?t=1707212548" data-ll-status="loaded" style="background-image: url(&quot;https://media.st.dl.eccdnx.com/steam/apps/261550/header.jpg?t=1707212548&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8d%b3%e6%97%b6%e6%88%98%e7%95%a5/">即时战略</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%83%ad%e9%97%a8%e6%b8%b8%e6%88%8f/">热门游戏</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/9655/" title="骑马与砍杀2霸主/骑马与砍杀2领主/骑砍2霸主/Mount &amp; Blade II: Bannerlord">骑马与砍杀2霸主/骑马与砍杀2领主/骑砍2霸主/Mount &amp; Blade II: Bannerlord</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-06-29T23:14:27+08:00">2 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>2</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>17</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>10.0K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/10546/" title="地平线 西之绝境完整版/Horizon Forbidden West Complete Edition" data-bg="https://media.st.dl.eccdnx.com/steam/apps/2420110/header.jpg?t=1711032993" data-ll-status="loaded" style="background-image: url(&quot;https://media.st.dl.eccdnx.com/steam/apps/2420110/header.jpg?t=1711032993&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e5%8a%a8%e4%bd%9c%e5%86%92%e9%99%a9/">动作冒险</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%83%ad%e9%97%a8%e6%b8%b8%e6%88%8f/">热门游戏</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/10546/" title="地平线 西之绝境完整版/Horizon Forbidden West Complete Edition">地平线 西之绝境完整版/Horizon Forbidden West Complete Edition</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-06-28T22:33:00+08:00">2 周前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>6</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>44</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>23.7K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>




                <div class="col">
                  <article class="post-item item-grid grid-overlay">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">

                      <div class="badge bg-warning bg-opacity-75">VIP</div>

                    </div>



                    <div class="entry-media ratio ratio-3x2">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded" href="https://hanbaoyx.com/8905/" title="刺客信条9：英灵殿完全版/全DLC/Assassin’s Creed: Valhalla Complete Edition" data-bg="https://cdn.max-c.com/pc_game/screenshot/51e00650ae5fafeff496658b89358b62.jpg" data-ll-status="loaded" style="background-image: url(&quot;https://cdn.max-c.com/pc_game/screenshot/51e00650ae5fafeff496658b89358b62.jpg&quot;);">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%83%ad%e9%97%a8%e6%b8%b8%e6%88%8f/">热门游戏</a> <a href="https://hanbaoyx.com/category/%e5%85%a8%e9%83%a8%e6%b8%b8%e6%88%8f/%e7%89%b9%e5%88%ab%e6%8e%a8%e8%8d%90/">特别推荐</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" href="https://hanbaoyx.com/8905/" title="刺客信条9：英灵殿完全版/全DLC/Assassin’s Creed: Valhalla Complete Edition">刺客信条9：英灵殿完全版/全DLC/Assassin’s Creed: Valhalla Complete Edition</a>
                      </h2>



                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" datetime="2024-02-21T12:08:54+08:00">5 月前</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="far fa-heart me-1"></i>0</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="far fa-star me-1"></i>22</span>

                        <span class="meta-views"><i class="far fa-eye me-1"></i>6.7K</span>

                        <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                      </div>

                    </div>
                  </article>
                </div>


              </div>


            </div>

          </div>        </div>
      </div>

    </div>

  </main>

  </body>
</template>

<script>
import ProfileLeft from "@/components/profileLeft.vue";
import HeadTop from "@/components/headTop.vue";

export default {
  name: "profileBalance",
  components: {HeadTop, ProfileLeft},
  methods: {
    loadCSS(filename) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = require(`../assets/css/${filename}`);
      link.id = filename;
      document.head.appendChild(link);
    },
    unloadCSS(filename) {
      const link = document.getElementById(filename);
      if (link) {
        document.head.removeChild(link);
      }
    }
  },
  mounted() {
    this.$store.commit('setActiveItem', 5);
    this.loadCSS('a.css');
  },

  beforeDestroy() {
    this.unloadCSS('a.css');
  }

}
</script>

<style scoped>
/*.coin-balance-body {*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  background: linear-gradient(50deg, #fff, #fffde9)*/
/*}*/

/*.coin-pay-card {*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  border: 2px solid rgba(0, 0, 0, .1);*/
/*  border-radius: .5rem;*/
/*  overflow: hidden;*/
/*  background: rgba(0, 0, 0, .03);*/
/*  padding: 1rem .3rem;*/
/*  transition: all .3s ease-in-out*/
/*}*/

/*.balance-qiandao {*/
/*  position: absolute;*/
/*  right: .3rem;*/
/*  top: .3rem*/
/*}*/
/*.uc-page .coin-balance-body .balance-info {*/
/*  text-align: center;*/
/*  color: #ff9800*/
/*}*/
/*.uc-page .coin-balance-body .balance-info hr {*/
/*  margin: .5rem 0*/
/*}*/

</style>