import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import pubParam from "./pubParam";
import breadCrumbStore from "./breadCrumbStore";
import searchStore from "./searchStore";
import menuItem from "@/store/menuItem";

Vue.use(Vuex);

export default new Vuex.Store({
    modules:{
        user,
        pubParam,
        breadCrumbStore,
        searchStore,
        menuItem,
    }
})