<template>

<!--    <head-top></head-top>-->
<!--    <profile-left></profile-left>-->
<!--    <profile-basic-info></profile-basic-info>-->
<!--    &lt;!&ndash; 网站底部   &ndash;&gt;-->
<!--    <home-footer></home-footer>-->

  <body class="logged-in uc-page" style="transform: none;">


  <head-top></head-top>



  <!-- **************** MAIN CONTENT START **************** -->
    <main style="transform: none;" >


      <div class="container mt-2 mt-sm-4" style="transform: none;">

        <div class="row g-2 g-md-3 g-lg-4" style="transform: none;">

         <profile-left></profile-left>
          <div class="col-md-12 col-lg-9" data-sticky-content="">

            <div class="card mb-sm-4">
              <div class="mb-3">
                <h5 class="fw-bold mb-0">基本信息</h5>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-start mb-4">
                  <!-- Image -->
                  <div class="position-relative">
                    <div class="d-flex align-items-center">
                      <div class="position-relative me-3">
                        <div class="avatar avatar-xl">
                          <img class="avatar-img rounded-circle border border-white border-3 shadow" src="//hanbaoyx.com/wp-content/uploads/1234/01/avatar-62af085b18266b98.png" alt="">
                        </div>
                      </div>
                      <div class="d-block">
                        <label for="inputAvatarFile" type="button" class="btn btn-primary-soft">上传头像</label>
                        <input class="d-none" type="file" name="inputAvatarFile" id="inputAvatarFile" accept=".jpg, .gif, .png" resetonclick="true">
                      </div>
                    </div>
                  </div>
                </div>

                <form class="row g-4" id="user-profile">
                  <!-- Input item -->
                  <div class="col-lg-6">
                    <label class="form-label">显示昵称</label>
                    <input type="text" class="form-control" name="display_name" placeholder="" value="kurt">
                  </div>
                  <!-- Input item -->
                  <div class="col-lg-6">
                    <label class="form-label">联系QQ</label>
                    <input type="text" class="form-control" name="uc_lxqq" value="">
                  </div>
                  <!-- Textarea item -->
                  <div class="col-12">
                    <label class="form-label">个人介绍</label>
                    <textarea class="form-control" rows="3" name="description"></textarea>
                  </div>
                  <!-- Save button -->
                  <div class="d-sm-flex justify-content-end mt-3">
                    <input type="hidden" name="action" value="zb_update_profile">
                    <button type="submit" id="save-submit" class="btn btn-dark mb-0">保存资料</button>
                  </div>
                </form>
              </div>
            </div>



            <div class="card mb-sm-4">
              <!-- Content -->
              <div class=" mb-3">
                <h5 class="fw-bold mb-0">账户绑定</h5>
              </div>
              <!-- Button -->

              <form class="row g-4" id="edit-email-form">
                <div class="col-lg-6">
                  <label class="form-label">当前邮箱</label>
                  <input type="text" class="form-control" name="user_email" value="zjxcy123@163.com" disabled="">
                </div>

                <div class="col-lg-6">
                  <label class="form-label">新邮箱地址</label>
                  <input type="email" class="form-control" name="new_user_email" value="" autocomplete="off">
                </div>

                <div class="col-lg-12">
                </div>

                <!-- Save button -->
                <div class="d-sm-flex justify-content-end mt-3">
                  <input type="hidden" name="action" value="zb_update_new_email">
                  <button type="submit" id="edit-email" class="btn btn-danger mb-0">确认修改邮箱</button>
                </div>
              </form>




            </div>



            <div class="card">
              <!-- Content -->
              <div class=" mb-3">
                <h5 class="fw-bold mb-0">密码修改</h5>
              </div>
              <!-- Button -->

              <form class="row g-4" id="edit-password-form">
                <div class="col-lg-6">
                  <label class="form-label">邮箱信息</label>
                  <input type="text" class="form-control" name="user_email" value="zjxcy123@163.com" disabled="">
                </div>

                <div class="col-lg-6">
                  <label class="form-label">旧密码</label>
                  <input type="text" class="form-control" name="old_password" value="" autocomplete="off">
                </div>
                <div class="col-lg-6">
                  <label class="form-label">新密码</label>
                  <input type="text" class="form-control" name="new_password" value="" autocomplete="off">
                </div>
                <div class="col-lg-6">
                  <label class="form-label">确认新密码</label>
                  <input type="text" class="form-control" name="new_password2" value="" autocomplete="off">
                </div>
                <!-- Save button -->
                <div class="d-sm-flex justify-content-end mt-3">
                  <input type="hidden" name="action" value="zb_update_password">
                  <button type="submit" id="edit-password" class="btn btn-danger mb-0">确认修改密码</button>
                </div>
              </form>

            </div>






          </div>

        </div>

      </div>

    </main>
  <!-- **************** MAIN CONTENT END **************** -->

  <!-- =======================
  Footer START -->
  <!-- =======================
  Footer END -->

<div class="header-gap"></div>
<!-- 网站底部   -->
<home-footer></home-footer>




  <!-- 自定义js代码 统计代码 -->
  <!-- 自定义js代码 统计代码 END -->



  </body>
</template>

<script>



import HeadTop from "@/components/headTop.vue";
import ProfileLeft from "@/components/profileLeft.vue";
import HomeFooter from "@/components/homeFooter.vue";



export default {
  name: "profileBasicInfo",
  components: { HomeFooter, ProfileLeft, HeadTop },
  methods: {
    loadCSS(filename) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = require(`../assets/css/${filename}`);
      link.id = filename;
      document.head.appendChild(link);
    },
    unloadCSS(filename) {
      const link = document.getElementById(filename);
      if (link) {
        document.head.removeChild(link);
      }
    }
  },
  mounted() {
    this.loadCSS('a.css');
    this.$store.commit('setActiveItem', 1);
    console.log("111",this.$store.state.menuItem.activeItem)
  },
  beforeDestroy() {
    this.unloadCSS('a.css');
  }
};
</script>

<style lang="less" scoped>



</style>