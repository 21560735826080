<template>
<div>
  <!-- Article Detail -->
  <div class="wrap__article-detail">
    <div class="wrap__article-detail-title">
      <h1>
        {{this.articleDetail.articleTitle}}
      </h1>
<!--      <h3>-->
<!--        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae, hic.-->
<!--      </h3>-->
    </div>
    <hr>
    <div class="wrap__article-detail-info">
      <ul class="list-inline">
        <li class="list-inline-item">
          <figure class="image-profile">
            <img src="../assets/pic/gscmini.jpg">
          </figure>
        </li>
        <li class="list-inline-item">

                <span>
                    作者:
                </span>
          <a href="/">
            {{this.articleDetail.articleAuthor}}
          </a>
        </li>
        <li class="list-inline-item">
                <span class="text-dark text-capitalize ml-1">
                   &nbsp; &nbsp; 发布时间: {{ this.articleDetail.publishDate| moment }}
                </span>
        </li>
        <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                 &nbsp; &nbsp; 分类:
                </span>
          <a :href="'/category/'+this.articleDetail.categoryId">
            {{this.articleDetail.category}}
          </a>
        </li>
        <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                 &nbsp; &nbsp; 浏览量:
                </span>
          <span >
            {{this.articleDetail.articleView}}
          </span>
        </li>
      </ul>
    </div>
    <!--展示图    -->
    <div class="wrap__article-detail-image mt-4">
      <figure>
        <img :src="this.articleDetail.articleCoverPicPath" alt="" class="img-fluid">
      </figure>
    </div>
    <video-player src="https://www.xdgame.com/tool/video/m3u8.php?url=https://media.st.dl.eccdnx.com/steam/apps/256852451/movie480_vp9.webm?t=1632235723"></video-player>
    <!-- 文章内容-->
    <div v-html="this.articleDetail.articleTextRaw">
    </div>



  </div>
</div>
</template>

<script>

import moment from "moment";

export default {
  name: "cardArticleDetail",
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  computed:{

    getArticleDetail(){
      return this.$parent.articleDetail;
    }

  },
  watch:{
    getArticleDetail(newValue,oldValue){
      this.articleDetail = newValue;
      console.log('22222222',newValue);
    }
  },

  data(){
    return{
      articleDetail:{
        articleTitle:'',
        articleAuthor:'',
        category:'',
        categoryId:'',
        articleCoverPicPath:'',
        publishDate:'2024-1-1',
        articleView:'',
        articleTextRaw:''
      }
    }
  }

}
</script>

<style scoped>

</style>