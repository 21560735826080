<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <aside class="wrapper__list__article ">
          <h4 class="border_section">{{this.categoryDetail.categoryName}}</h4>

          <div class="row">
            <div class="col-md-6">
              <!-- Post Article -->
              <div class="article__entry" v-for="(item,index) in this.categoryDetail.categoryPage.detailList" :key="index">
                <div v-if="(index % 2 === 0) && categoryDetail.categoryPage.totalSize!==0">
                  <div class="article__image">
                    <a :href="categoryDetail.prefix+item.id">
                      <img :src="item.coverPicPath" alt="" class="img-fluid">
                    </a>
                  </div>
                  <div class="article__content">
                    <div class="article__category">
                      {{item.categoryName}}
                    </div>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                  {{ item.publish_date| moment }}
                </span>
                      </li>

                    </ul>
                    <h5>
                      <a :href="categoryDetail.prefix+item.id">
                        {{item.title}}
                      </a>
                    </h5>
                    <a :href="categoryDetail.prefix+item.id" class="btn btn-outline-primary mb-4 text-capitalize"> 查看详情</a>
                  </div>
                </div>

              </div>

            </div>
            <div class="col-md-6">
              <!-- Post Article -->
              <div class="article__entry" v-for="(item,index) in this.categoryDetail.categoryPage.detailList" :key="index">
                <div  v-if="(index % 2 !== 0) && categoryDetail.categoryPage.totalSize!==0">
                  <div class="article__image">
                    <a :href="categoryDetail.prefix+item.id">
                      <img :src="item.coverPicPath" alt="" class="img-fluid">
                    </a>
                  </div>
                  <div class="article__content">
                    <div class="article__category">
                      {{item.categoryName}}
                    </div>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                  {{ item.publish_date| moment }}
                </span>
                      </li>

                    </ul>
                    <h5>
                      <a :href="categoryDetail.prefix+item.id">
                        {{item.title}}
                      </a>
                    </h5>
                    <a :href="categoryDetail.prefix+item.id" class="btn btn-outline-primary mb-4 text-capitalize"> 查看详情</a>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </aside>

      </div>
      <div class="col-md-4">
        <div class="sidebar-sticky">
          <aside class="wrapper__list__article ">
            <h4 class="border_section">热门文章</h4>
            <div class="wrapper__list__article-small">
              <div class="mb-3">
                <!-- Post Article -->
                <div class="card__post card__post-list">
                  <div class="image-sm">
                    <a :href="'/article/'+this.categoryDetail.popularArticleList[0].id">
                      <img :src="categoryDetail.popularArticleList[0].articleCoverPicPath" class="img-fluid" alt="">
                    </a>
                  </div>


                  <div class="card__post__body ">
                    <div class="card__post__content">

                      <div class="card__post__author-info mb-2">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                            {{ this.categoryDetail.popularArticleList[0].publishDate| moment }}
                        </span>
                          </li>

                        </ul>
                      </div>
                      <div class="card__post__title">
                        <h6>
                          <a :href="'/article/'+this.categoryDetail.popularArticleList[0].id">
                            {{categoryDetail.popularArticleList[0].articleTitle}}
                          </a>
                        </h6>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
              <div class="mb-3">
                <!-- Post Article -->
                <div class="card__post card__post-list">
                  <div class="image-sm">
                    <a :href="'/article/'+categoryDetail.popularArticleList[1].id">
                      <img :src="categoryDetail.popularArticleList[1].articleCoverPicPath" class="img-fluid" alt="">
                    </a>
                  </div>


                  <div class="card__post__body ">
                    <div class="card__post__content">

                      <div class="card__post__author-info mb-2">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                            {{ this.categoryDetail.popularArticleList[1].publishDate| moment }}
                        </span>
                          </li>

                        </ul>
                      </div>
                      <div class="card__post__title">
                        <h6>
                          <a :href="'/article/'+categoryDetail.popularArticleList[1].id">
                            {{categoryDetail.popularArticleList[1].articleTitle}}
                          </a>
                        </h6>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
              <div class="mb-3">
                <!-- Post Article -->
                <div class="card__post card__post-list">
                  <div class="image-sm">
                    <a :href="'/article/'+categoryDetail.popularArticleList[2].id">
                      <img :src="categoryDetail.popularArticleList[2].articleCoverPicPath" class="img-fluid" alt="">
                    </a>
                  </div>


                  <div class="card__post__body ">
                    <div class="card__post__content">

                      <div class="card__post__author-info mb-2">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                            {{ this.categoryDetail.popularArticleList[2].publishDate| moment }}
                        </span>
                          </li>

                        </ul>
                      </div>
                      <div class="card__post__title">
                        <h6>
                          <a :href="'/article/'+categoryDetail.popularArticleList[2].id">
                            {{categoryDetail.popularArticleList[2].articleTitle}}
                          </a>
                        </h6>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

              <!-- Post Article -->
              <div class="article__entry">
                <div class="article__image">
                  <a :href="'/article/'+categoryDetail.popularArticleList[3].id">
                    <img :src="categoryDetail.popularArticleList[3].articleCoverPicPath" alt="" class="img-fluid">
                  </a>
                </div>
                <div class="article__content">
                  <div class="article__category">
                    {{categoryDetail.popularArticleList[3].category}}
                  </div>
                  <ul class="list-inline">

                    <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                    {{ this.categoryDetail.popularArticleList[3].publishDate| moment }}
                </span>
                    </li>

                  </ul>
                  <h5>
                    <a :href="'/article/'+categoryDetail.popularArticleList[3].id">
                      {{categoryDetail.popularArticleList[3].articleTitle}}
                    </a>
                  </h5>
                  <a :href="'/article/'+categoryDetail.popularArticleList[3].id" class="btn btn-outline-primary mb-4 text-capitalize"> 查看详情</a>
                </div>
              </div>
            </div>
          </aside>

          <aside class="wrapper__list__article">
            <h4 class="border_section">文章分类</h4>
            <!-- Widget Category -->
            <div class="widget widget__category">
              <ul class="list-unstyled ">
                <li v-for="(item,index) in this.categoryDetail.categoryList" :key="index">
                  <a :href="'/category/'+item.id">
                    {{item.categoryName}}
                    <span class="badge">{{item.size}}</span>
                  </a>
                </li>


              </ul>
            </div>
          </aside>


        </div>
      </div>

<!--      <div class="clearfix"></div>-->
    </div>
    <!-- Pagination -->
    <div class="pagination-area" v-if="categoryDetail.categoryPage.totalSize!==0">
        <el-pagination class="pagination wow fadeIn animated"
            layout="prev, pager, next"
                       @current-change="handleCurrentChange"
                       @prev-click="handlePrevClick"
                       @next-click="handleNextClick"
            :total="categoryDetail.categoryPage.totalSize" :current-page="page"   style="margin-right: 50%">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import http from "@/api/http";
import portal from "@/api/portal";

export default {

  name: "categoryCard",

  mounted() {

  },

  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  created() {

    try {
      http.get2(portal.getCategoryDetail+"?id="+this.$parent.$route.params.id, {}).catch((data) => {
        console.log(data)
        this.categoryDetail = data.data;
      }).then(() => {
        this.page = this.categoryDetail.categoryPage.page
      })
    }catch (err) {
      this.$message.error(err);
    }

  },
  methods:{

    // 当前页码改变事件处理函数
    handleCurrentChange(newPage) {
      console.log('当前页: ' + newPage);
      // 可以在这里更新数据，例如重新请求数据
      try {
        http.get2(portal.getCategoryDetail+"?id="+this.$parent.$route.params.id+"&current="+newPage, {}).catch((data) => {
          console.log(data)
          this.categoryDetail = data.data;
        }).then(() => {
          this.page = this.categoryDetail.categoryPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    // 上一页按钮点击事件处理函数
    handlePrevClick() {
      console.log('上一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.categoryDetail.categoryPage.page -1
      try {
        http.get2(portal.getCategoryDetail+"?id="+this.$parent.$route.params.id+"&current="+newPage, {}).catch((data) => {
          console.log(data)
          this.categoryDetail = data.data;
        }).then(() => {
          this.page = this.categoryDetail.categoryPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    // 下一页按钮点击事件处理函数
    handleNextClick() {
      console.log('下一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.categoryDetail.categoryPage.page +1
      try {
        http.get2(portal.getCategoryDetail+"?id="+this.$parent.$route.params.id+"&current="+newPage, {}).catch((data) => {
          console.log(data)
          this.categoryDetail = data.data;
        }).then(() => {
          this.page = this.categoryDetail.categoryPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }

    }

  },
  data(){
    return {
      prefix:'',
      page:1,
      categoryDetail:{
        prefix:'',
        id:'',
        categoryName:'',
        categoryType:'',
        categoryPage:{
          totalSize:0,
          pageSize:'',
          page:1,
          detailList:[{
            id:'',
            coverPicPath:'',
            categoryName:'',
            author:'',
            publish_date:'',
            title:''
          }]

        },
        categoryList: [{
          id:'',
          categoryName: '',
          size:''
        }],
        popularArticleList:[{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        }],
      }

    }
  }
}
</script>

<style scoped>

</style>