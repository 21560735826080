<template>
  <div>

    <header class="bg-light">
      <!-- navbar -->
      <navbar-kit></navbar-kit>
      <!-- end navbar -->
    </header>



    <section class="wrapper__section p-0">
      <div class="wrapper__section__components">
        <home-footer></home-footer>
      </div>
    </section>


    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>
  </div>
</template>

<script>
import NavbarKit from "@/components/navbarKit.vue";
import HomeFooter from "@/components/homeFooter.vue";
import http from "@/api/http";
import portal from "@/api/portal";

export default {
  name: "gameDownLoad",
  components: {HomeFooter, NavbarKit},

  mounted() {
    try {
      http.get2(portal.gameDownLoad + "?gameId=" +this.$route.query.gameId+"&downLoadType="+this.$route.query.downLoadType, {}).catch((data) => {
        console.log(data)
        this.gameDetail = data.data
        console.log('raw2', data.data.articleTextRaw)
      }).then(() => {
        // if(this.gameDetail.mayLikeArticleList[0].id!==''){
        //   this.slickShow()
        // }
      })
    } catch (err) {
      this.$message.error(err);
    }
  }
}
</script>

<style scoped>

</style>