<template>
  <div class="comment">
    <div class="comment-header">
      <el-tooltip class="item" effect="dark" content="点我更换头像" placement="top-start">
        <div @click="handleClick">
          <input type="file" style="display: none" @change="dealWithdAvatar" ref="avatar" />
          <el-avatar
              :src="
              avatarUrl
                ? avatarUrl
                : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
            "
              :size="40"
          ></el-avatar>
        </div>
      </el-tooltip>
      <span>&nbsp;{{bsUserGuest.userName}}</span>
      <el-input
          :placeholder="placeholderText"
          v-model="context"
          class="input"
          type="textarea"
          resize="none"
          size="mini"
          :maxlength="contentLength"
          @focus="isShowSecReply(undefined)"
      ></el-input>
      <el-button
          type="info"
          style="height: 40px"
          @click="addComment(articleIdInComment,undefined, undefined)"
      >{{ buttonText }}</el-button>
    </div>
    <br>
    <br>
    <h3 class="comments-title">{{this.BsArticleCommentVO.length}}条评论</h3>
    <br>
    <div class="comment-body" v-for="(item, index) in this.BsArticleCommentVO" :key="item.id + '' + index">
      <!-- 一级评论 -->
      <div class="first-comment">
        <el-avatar :size="40" :src="item.avatarUrl"></el-avatar>
        <div class="content">
          <!-- 一级评论用户昵称 -->
          <h3>{{ item.userName }}</h3>
          <!-- 一级评论发布时间 -->
          <span>{{ item.createTime | moment}}</span>
          <!-- 一级评论评论内容 -->
          <p>{{ item.content }}</p>
          <!-- 一级评论评论点赞 -->
          <div class="comment-right">
            <i
                class="el-icon-trophy"
                @click="giveLikeGuest(item)"
                :class="item.isFavor==='1' ? 'active' : ''"
            ></i>
            {{ item.likes }}
            <i
                class="el-icon-chat-dot-round"
                @click="isShowSecReply(item.id)"
            >回复</i>
<!--            <i-->
<!--                class="el-icon-delete"-->
<!--                @click="deleteComment(item._id, undefined)"-->
<!--                v-if="userId === item.userId"-->
<!--            >删除</i>-->
          </div>
          <!-- 回复一级评论 -->
          <div class="reply-comment" v-show="isShowSec === item.id">
            <el-input
                :placeholder="placeholderText"
                class="input"
                v-model.trim="replyContext"
                :maxlength="contentLength"
            ></el-input>
            <el-button
                type="info"
                size="mini"
                class="reply-button"
                @click="addComment(articleIdInComment,item.id, item.username)"
            >回复</el-button>
          </div>
          <!-- 次级评论 -->
          <div
              class="second-comment"
              v-for="(reply, index) in item.respComment"
              :key="reply.id + '' + index"
          >
            <!-- 次级评论头像,该用户没有头像则显示默认头像 -->
            <el-avatar :size="40" :src="reply.avatarUrl"></el-avatar>
            <div class="content">
              <!-- 次级评论用户昵称 -->
              <h3>{{ reply.userName }}</h3>
              <!-- 次级评论评论时间 -->
              <span style="margin-right: ">{{ reply.createTime|moment }}</span>
<!--              <span class="to_reply">{{ reply.userName }}</span>-->
              回复
              <span class="to_reply">{{ reply.quoteUserName }}</span>:
              <p>{{ reply.content }}</p>
              <!-- 次级评论评论点赞 -->
              <div class="comment-right">
                <i
                    class="el-icon-trophy"
                    @click="giveLikeGuest(reply)"
                    :class="reply.isFavor==='1' ? 'active' : ''"
                ></i>
                {{ reply.likes }}
                <i
                    class="el-icon-chat-dot-round"
                    @click="isShowSecReply(reply.id)"
                >回复</i>
<!--                <i-->
<!--                    class="el-icon-delete"-->
<!--                    @click="deleteComment(item._id, reply._id)"-->
<!--                    v-if="userId === reply.userId"-->
<!--                >删除</i>-->
              </div>
              <div class="reply-comment" v-show="isShowSec === reply.id">
                <el-input
                    :placeholder="placeholderText"
                    class="input"
                    v-model.trim="replyContext"
                    :maxlength="contentLength"
                ></el-input>
                <el-button
                    type="info"
                    size="mini"
                    class="reply-button"
                    @click="addComment(articleIdInComment,reply.id, reply.username)"
                >回复</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无评论的空状态 -->
    <el-empty :description="emptyText" v-show="BsArticleCommentVO.length === 0"></el-empty>
  </div>
</template>
<script>
import http from "@/api/http";
import portal from "@/api/portal";
import moment from "moment";
import Fingerprint2 from "fingerprintjs2";
import user from "@/api/user";
import comment from "@/api/comment";

export default {
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD HH:MM ');
    }
  },
  props: {
    articleId: {
      //评论所属文章 id
      type: String
    },
    emptyText: {
      // 评论为空的时候显示的文字
      type: String,
      default: "期待你的评论！"
    },
    buttonText: {
      // 按钮文字
      type: String,
      default: "评论"
    },
    contentLength: {
      // 评论长度
      type: Number,
      default: 150
    },
    placeholderText: {
      // 默认显示文字
      type: String,
      default: "请输入最多150字的评论..."
    }
  },
  data() {
    return {
      bsUserGuest:{
        id:'',
        userName:'',
        avatarUrl:'',
        browserPrint:'',
        ip:'',
      },
      browserPrint:this.$store.state.user.browserPrint,
      data: [],
      articleIdInComment: this.$parent.$route.params.id,
      BsArticleCommentVO:[{
        id:0,
        commentLevel:"",
        parentCommentId:0,
        relIdArticle:0,
        relIdComment:"",
        quoteUserName:"",
        quoteContent:"",
        userId:0,
        userName:"",
        content:"",
        likes:0,
        ip:"",
        ipLocation:"",
        createTime:"",
        updateTime:"",
        avatarUrl:"",
        isFavor:"",
        respComment:[
          {
            id:1,
            commentLevel:"",
            parentCommentId:0,
            relIdArticle:0,
            relIdComment:"",
            quoteUserName:"",
            quoteContent:"",
            userId:0,
            userName:"",
            content:"",
            likes:0,
            ip:"",
            ipLocation:"",
            createTime:"",
            updateTime:"",
            avatarUrl:"",
            isFavor:"",
          }
        ]
      }
      ],
       // 获取得到的评论
      context: "", // 评论内容
      replyContext: "", //一级评论回复
      isShowSec: "", //是否显示次级回复框
      isClickId: "", //记录点击回复的评论id
      userId: "", // 浏览器指纹
      username: "", //你的用户名
      firstIdx: 1,
      secIdx: 1,
      avatarUrl:
          "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
    };
  },
  mounted() {
    // 获取评论数据
    this.getGuestInfo();
  },
  created() {

  },
  methods: {
    getGuestInfo() {
      if (typeof this.browserPrint !== 'undefined' && this.browserPrint != null && this.browserPrint !== ' ') {

        new Promise((resolve) => {
          // 浏览器指纹
          const fingerPrint = Fingerprint2.get((components) => { // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
            const values = components.map(component => component.value); // 配置的值的数组
            const murmur = Fingerprint2.x64hash128(values.join(''), 31); // 生成浏览器指纹
            this.browserPrint = murmur;
            resolve();
          })
        }).then(() => {
          try {
            http.get2(user.getGuestInfo, {browserPrint: this.browserPrint}).catch((data) => {
              console.log("123123123",data)
              this.bsUserGuest = data.data
            }).then(() => {
              this.getCommentList2();
            })
          } catch (err) {
            this.$message.error(err);
          }
        })


      } else {
        try {
          http.get2(user.getGuestInfo, {browserPrint: this.browserPrint}).catch((data) => {
            this.bsUserGuest = data.data;
          }).then(() => {
            this.getCommentList2();
          })
        } catch (err) {
          this.$message.error(err);
        }
      }


    },


    // 唤起文件选择
    handleClick() {
      this.getCommentList2();
    },
    dealWithdAvatar(e) {
      const file = Array.prototype.slice.call(e.target.files)[0];
      console.log(file);
    },

    getCommentList2() {

      if (1) {
        //如果是游客
        try {
          http.get2(comment.getArticleCommentGuest, {
            id: this.$parent.$route.params.id,
            browserPrint: this.browserPrint,
            relIdUser: this.bsUserGuest.id
          }).catch((data) => {
            this.BsArticleCommentVO = data.data;
            console.log('1111', this.BsArticleCommentVO)
          }).then(() => {

          })
        } catch (err) {
          this.$message.error(err);
        }
      } else {
        //如果是登录用户

      }


    },


    // 评论点赞
    giveLikeGuest(item) {
      try {
        // 不允许同一个人重复点赞
        if (item.isFavor === '1') {
          this.$message.info("您已经点过赞啦！");
          return;
        }

        http.post(comment.commentLikeGuest, {
          relIdArticle: this.$parent.$route.params.id,
          relIdComment: item.id,
          content: this.context,
          browserPrint: this.$store.state.user.browserPrint,
          guestUserId: this.bsUserGuest.id
        }, null).catch((data) => {
          if (data.status === 200) {
            console.log(data)

            this.$message.success("点赞成功");
          }
        }).then(() => {
          this.getCommentList2();
        })
      } catch (err) {
        this.context = '';
        this.$message.error(err);
      }
    },
    isShowSecReply(id) {
      if (id) {
        this.isShowSec = id;
        if (this.isClickId === this.isShowSec) {
          this.isShowSec = "";
        } else {
          this.isShowSec = id;
        }
        this.isClickId = this.isShowSec;
      } else {
        this.isShowSec = this.isClickId = "";
      }
    },
    deleteComment(_id, replyId) {
      if (replyId) {
        // 删除二级评论，提交请求到后端

        // 成功后从本地记录中删除该评论
        const temp = this.comments.find(item => item._id == _id).replyInfo;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i]._id == replyId) {
            temp.splice(i, 1);
            break;
          }
        }
      } else {
        // 删除一级评论，提交请求到后端

        // 成功后从本地记录中删除该评论
        for (let i = 0; i < this.comments.length; i++) {
          if (this.comments[i]._id == _id) {
            this.comments.splice(i, 1);
          }
        }
      }
    },
    async addComment(articleId, commentId) {
      // 评论添加成功，返回的数据
      //本地更新评论列表
      if (commentId) {
        // 添加二级评论
        if (!this.replyContext) {
          this.$message.warning("评论或留言不能为空哦！");
          return;
        }

        try {
          http.post(comment.addArticleCommentGuest, {
            relIdArticle: this.$parent.$route.params.id,
            relIdComment: commentId,
            content: this.replyContext,
            browserPrint: this.$store.state.user.browserPrint,
            guestUserId: this.bsUserGuest.id
          }, null).then(response => {
            console.log("Response",response)
            if (response.success === true) {
              this.BsArticleCommentVO = response.data;
              this.$message.success("评论成功");
              this.context = '';
            }
          })
        } catch (err) {
          this.context = '';
          this.$message.error(err);
        }
      } else {
        // 添加一级评论，提交数据到后端
        if (!this.context) {
          this.$message.warning("评论或留言不能为空哦！");
          return;
        }

        try {
          http.post(comment.addArticleCommentGuest, {
            relIdArticle: this.$parent.$route.params.id,
            relIdComment: commentId,
            content: this.context,
            browserPrint: this.$store.state.user.browserPrint,
            guestUserId: this.bsUserGuest.id
          }, null).then(response => {
            // 请求成功，处理响应数据
            console.log('Response:', response);
            if (response.success === true) {
              this.BsArticleCommentVO = response.data;
              this.$message.success("评论成功");
              this.context = '';
            }
          })
        } catch (err) {
          this.context = '';
          this.$message.error(err);
        }

      }
      this.isShowSec = this.isClickId = "";
    }
  }
};
</script>

<style lang="less" scoped>
.comment {
  min-height: 26vh;
  border-radius: 5px;
  margin-top: 2px;
  overflow: hidden;
  .active {
    color: rgb(202, 4, 4);
  }
  .comment-header {
    position: relative;
    height: 50px;
    padding: 10px 5px;
    display: flex;
    align-items: center;

    .input {
      margin-left: 10px;
      margin-right: 20px;
      flex: 1;
      /deep/.el-input__inner:focus {
        border-color: #dcdfe6;
      }
    }
  }

  .comment-body {
    min-height: 70px;
    padding: 10px 20px;
    font-size: 14px;
    .first-comment {
      display: flex;
      .input {
        /deep/.el-input__inner:focus {
          border-color: #dcdfe6;
        }
      }
      i {
        margin-right: 5px;
        margin-left: 1vw;
        cursor: pointer;

        &:nth-child(3) {
          color: rgb(202, 4, 4);
        }
      }

      .content {
        margin-left: 10px;
        position: relative;
        flex: 1;

        & > span {
          font-size: 12px;
          color: rgb(130, 129, 129);
        }

        .comment-right {
          position: absolute;
          right: 0;
          top: 0;
        }

        .reply-comment {
          height: 60px;
          display: flex;
          align-items: center;

          .reply-button {
            margin-left: 20px;
            height: 35px;
          }
        }

        .second-comment {
          display: flex;
          padding: 10px 0 10px 5px;
          border-radius: 20px;
          background: #ffffff;
          .to_reply {
            color: rgb(126, 127, 128);
          }
        }
      }
    }
  }
}
</style>
