<template>
  <ul class="breadcrumbs bg-light mb-4">

    <li class="breadcrumbs__item breadcrumbs__item--current" v-for="(item,index) in this.$store.state.breadCrumbStore.breadCrumb" :key="index">
      <a :href="item.to" class="breadcrumbs__url">
        <i :class="{'fa':true,'fa-home':item.name=='首页'}"></i> {{item.name}}</a>
    </li>
<!--    <li class="breadcrumbs__item">-->
<!--      <a href="index.html" class="breadcrumbs__url">News</a>-->
<!--    </li>-->
<!--    <li class="breadcrumbs__item breadcrumbs__item&#45;&#45;current">-->
<!--      World-->
<!--    </li>-->
  </ul>
</template>



<script>

export default {

  mounted() {

  },
  name: "breadCrumbs",
  methods:{

  },
  data(){
    return {

    }
  }
}
</script>

<style scoped>
@import '../style/styles.css';
</style>