<template>
  <div>

    <header class="bg-light">
      <!-- navbar -->
      <navbar-kit></navbar-kit>
      <!-- end navbar -->
    </header>

    <search-result></search-result>
    <section class="wrapper__section p-0">
      <div class="wrapper__section__components">
        <home-footer></home-footer>
      </div>
    </section>


    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>



  </div>

</template>

<script>
import SearchResult from "@/components/searchResult";
import NavbarKit from "@/components/navbarKit";
import HomeFooter from "@/components/homeFooter";
import moment from "moment/moment";
export default {
  name: "SearchPage",
  components: {HomeFooter, NavbarKit, SearchResult},


}
</script>

<style scoped>

</style>