<template>
<div>

  <header class="bg-light">
    <!-- Navbar menu  -->
    <navbar-kit></navbar-kit>
    <!-- End Navbar menu  -->


  </header>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- Breadcrumb -->
          <bread-crumbs></bread-crumbs>
        </div>

      </div>
    </div>
    <game-tag-card></game-tag-card>
  </section>


  <section class="wrapper__section p-0">
    <div class="wrapper__section__components">
      <home-footer></home-footer>
    </div>
  </section>


  <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>


</div>
</template>

<script>
import BreadCrumbs from "@/components/breadCrumbs.vue";
import NavbarKit from "@/components/navbarKit.vue";
import HomeFooter from "@/components/homeFooter.vue";
import GameTagCard from "@/components/GameTagCard.vue";

export default {
  mounted() {
    this.$store.commit('setBreadCrumb', this.breadCrumb);
  },

  data(){
    return{
      breadCrumb: [{
        name:'首页',
        to:'/',
      },
        {
          name:'游戏标签',
          to:'/',
        },
        {
          name:'当前页',
          to:'',
        },
      ],
    }
  },


  name: "GameTagPage",
  components: {GameTagCard, HomeFooter, NavbarKit,BreadCrumbs}
}
</script>

<style scoped>

</style>