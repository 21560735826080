<template>
<div>
<!--<navbar-top></navbar-top>  &lt;!&ndash; End Navbar Top  &ndash;&gt;-->
  <!-- Navbar  -->
<navbar-kit></navbar-kit>


</div>
</template>

<script>

import NavbarKit from "@/components/navbarKit.vue";

export default {
  name: "headTop",
  components: {NavbarKit}
}
</script>

<style scoped>

</style>