export default {
    state: {
        activeItem:''

    },
    mutations: {

        setActiveItem(state, val1){
            state.activeItem = val1;
        },




    }
}