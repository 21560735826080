import Vue from 'vue'
import App from './App.vue'
import "../src/scripts/vendor/jquery";
import "../src/scripts/vendor/bootstrap";
import "../src/scripts/vendor/menukit";
import "../src/scripts/vendor/slick";
import "../src/scripts/vendor/sticky-sidebar";
import "../src/scripts/vendor/app";
import 'default-passive-events';
import {
  Button,
  Radio,
  Container,
  Main,
  Header,
  Footer,
  Aside,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  Table,
  TableColumn,
  Breadcrumb,
  BreadcrumbItem,
  Tag,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Switch,
  DatePicker,
  Dialog,
  Pagination,
  MessageBox,
  Message,
  Divider,
  Avatar,
    Tooltip,
    Empty,
    Image,
} from "element-ui";
import {library} from "@fortawesome/fontawesome-svg-core";
import 'font-awesome/css/font-awesome.min.css';
import VueCookies from "vue-cookies";
import 'bootstrap-icons/font/bootstrap-icons.css'
// import '../src/assets/css/main.min.css'
//font-awesome图标库导入
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

//import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from "@/router";
// import {store} from "core-js/internals/reflect-metadata";
// import router from '../router'
import store from './store';
import 'font-awesome/css/font-awesome.min.css'


//图片懒加载
// import VueLazyload from 'vue-lazyload'
// //注意内容中引用图片路径:src要改为:v-lazy(使用时一定给一个 key 属性)
// Vue.use(VueLazyload, {
//   loading: require('./assets/pic/loading.gif'),
//   error: require('./assets/pic/cangshu.png'),
// })
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.use(VueCookies);
Vue.use(Button);
Vue.use(Radio);
Vue.use(Container);
Vue.use(Main);
Vue.use(Header);
Vue.use(Footer);
Vue.use(Aside);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Tag);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Avatar)
Vue.use(Divider)
Vue.use(Tooltip)
Vue.use(Empty)
Vue.use(Image);
Vue.config.productionTip = false
let specialStyles = null;
router.beforeEach((to, from, next) => {
  if (to.path === '/profileBasicInfo'||to.path === '/gameStore'||to.path === '/profileBalance'||to.path === '/profileMember'||to.path === '/profileDownLoadDetail'||to.path === '/profileIssue'||to.path === '/profileLikes'||to.path === '/#') {
    if (!specialStyles) {
      specialStyles = document.createElement('link');
      // specialStyles.rel = 'stylesheet';
      specialStyles.href = require('./assets/css/main.min.css');
      document.head.appendChild(specialStyles);
    }
  } else {
    if (specialStyles) {
      document.head.removeChild(specialStyles);
      specialStyles = null;
    }
  }
  next();
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
