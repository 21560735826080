export default {

   getMainPageInfo:'portal/home/getMainPageInfo',
   getArticleDetail:'portal/article/getArticleDetail',
   getGameDetail:'portal/game/getGameDetail',
   getCategoryDetail:'portal/category/getCategoryDetail',
   getArticleTagDetail:'portal/tag/getArticleTagDetail',
   getGameTagDetail:'portal/tag/getGameTagDetail',
   getGameStoreDetail:'portal/gameStore/getGameStoreDetail',
   getCategoryOptions:'portal/category/getCategoryOptions',
   getSearchResult:'portal/search/getSearchResult',
   gameDownLoad:'portal/downLoad/gameDownLoad'

};