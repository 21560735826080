<template>
  <!-- Post Article -->
  <div class="article__entry">
    <div class="article__image">
      <a href="#">
        <img src="../assets/image/placeholder/500x400.jpg" alt="" class="img-fluid">
      </a>
    </div>
    <div class="article__content">
      <ul class="list-inline">
        <li class="list-inline-item">
				<span class="text-primary">
					by david hall
				</span>
        </li>
        <li class="list-inline-item">
				<span>
					descember 09, 2016
				</span>
        </li>

      </ul>
      <h5>
        <a href="#">
          Maecenas accumsan tortor ut velit pharetra mollis.
        </a>
      </h5>

    </div>
  </div>
</template>

<script>
export default {
  name: "cardArticle"
}
</script>

<style scoped>

</style>