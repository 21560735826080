<template>
  <!-- News Tags -->
  <div class="blog-tags">
    <ul class="list-inline">
      <li class="list-inline-item" v-for="(item,index) in this.referTagList" :key="index">
        <a :href="'/gameTag/'+item.id">
          {{item.tagName}}
        </a>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  computed:{

    getTagList(){
      return this.$parent.gameDetail.referTagList;
    }

  },
  watch:{
    getTagList(newValue,oldValue){
      this.referTagList = newValue;
    }
  },
  name: "referTagGame",

  data(){
    return{
      referTagList:[{
        id:'',
        tagName:''
      }]
    }


  }
}
</script>

<style scoped>

</style>