<template>

  <div>



    <header class="bg-light">
      <!-- navbar -->
      <navbar-kit></navbar-kit>
      <!-- end navbar -->


    </header>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- Breadcrumb -->
            <bread-crumbs></bread-crumbs>
          </div>

        </div>
      </div>
      <category-card></category-card>
    </section>


    <section class="wrapper__section p-0">
      <div class="wrapper__section__components">
        <home-footer></home-footer>
      </div>
    </section>


    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>

  </div>


</template>

<script>
import BreadCrumbs from "@/components/breadCrumbs.vue";
import NavbarKit from "@/components/navbarKit.vue";
import HomeFooter from "@/components/homeFooter.vue";
import CategoryCard from "@/components/categoryCard.vue";

export default {

  mounted() {
    this.$store.commit('setBreadCrumb', this.breadCrumb);
  },

  data(){
    return{
      breadCrumb: [{
        name:'首页',
        to:'/',
      },
        {
          name:'类别',
          to:'/',
        },
        {
          name:'当前页',
          to:'',
        },
      ],
    }
  },

  name: "categoryPage",
  components: {CategoryCard, HomeFooter, NavbarKit}
}
</script>

<style scoped>

</style>