<template>
<div>
<!--  <loading></loading>-->

  <header class="bg-light">
    <!-- navbar -->
    <navbar-kit></navbar-kit>
    <!-- end navbar -->
  </header>

  <section class="pb-80">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- breaddcrumb -->
          <bread-crumbs></bread-crumbs>
          <!-- end breadcrumb -->
        </div>
        <div class="col-md-8">
          <!-- content article detail -->
          <card-article-detail></card-article-detail>
          <!-- end content article detail -->

          <!-- tags -->
          <refer-tag></refer-tag>
          <!-- end tags-->

          <!-- comment -->
          <comment2></comment2>
          <!-- end comment -->
          <div class="row">
          </div>
          <div class="clearfix"></div>

          <div class="related-article" style="margin-top: 10%">
            <h4>
              您可能喜欢
            </h4>

            <div class="article__entry-carousel-three">
              <div class="item" v-for="(item,index) in this.articleDetail.mayLikeArticleList" :key="index">
                <div class="article__entry">
                  <div class="article__image">
                    <a :href="'/article/'+item.id">
                      <img :src="item.articleCoverPicPath" alt="" class="img-fluid">
                    </a>
                  </div>
                  <div class="article__content">
                    <ul class="list-inline">
                      <li class="list-inline-item">
				<span class="text-primary">
					by {{item.articleAuthor}}
				</span>
                      </li>
                      <li class="list-inline-item">
				<span>
					{{ item.publishDate| moment }}
				</span>
                      </li>

                    </ul>
                    <h5>
                      <a :href="'/article/'+item.id">
                        {{item.articleTitle}}
                      </a>
                    </h5>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="col-md-4">
          <div class="sticky-top">
            <aside class="wrapper__list__article ">
              <!--   边侧文章栏      -->
              <div class="mb-4">
              <!--搜索栏                -->
<!--              <form-searchbar></form-searchbar>-->
              </div>
              <div class="wrapper__list__article-small">
                <div class="mb-3">
                  <!--第一个文章栏                -->
                  <div class="card__post card__post-list">
                    <div class="image-sm">
                      <a :href="'/article/'+this.articleDetail.referArticleList[0].id">
                        <img :src="this.articleDetail.referArticleList[0].articleCoverPicPath" class="img-fluid" alt="">
                      </a>
                    </div>
                    <div class="card__post__body ">
                      <div class="card__post__content">

                        <div class="card__post__author-info mb-2">
                          <ul class="list-inline">
                            <li class="list-inline-item">
						<span class="text-primary">
							by {{this.articleDetail.referArticleList[0].articleAuthor}}
						</span>
                            </li>
                            <li class="list-inline-item">
						<span class="text-dark text-capitalize">
							{{ this.articleDetail.referArticleList[0].publishDate| moment }}
						</span>
                            </li>

                          </ul>
                        </div>
                        <div class="card__post__title">
                          <h6>
                            <a :href="'/article/'+this.articleDetail.referArticleList[0].id">
                              {{this.articleDetail.referArticleList[0].articleTitle}}
                            </a>
                          </h6>
                        </div>

                      </div>


                    </div>
                  </div>

                </div>
                <div class="mb-3">
                  <!--第二个文章栏                -->
                  <div class="card__post card__post-list">
                    <div class="image-sm">
                      <a :href="'/article/'+this.articleDetail.referArticleList[1].id">
                        <img :src="this.articleDetail.referArticleList[1].articleCoverPicPath" class="img-fluid" alt="">
                      </a>
                    </div>
                    <div class="card__post__body ">
                      <div class="card__post__content">

                        <div class="card__post__author-info mb-2">
                          <ul class="list-inline">
                            <li class="list-inline-item">
						<span class="text-primary">
							by {{this.articleDetail.referArticleList[1].articleAuthor}}
						</span>
                            </li>
                            <li class="list-inline-item">
						<span class="text-dark text-capitalize">
							{{ this.articleDetail.referArticleList[1].publishDate| moment }}
						</span>
                            </li>

                          </ul>
                        </div>
                        <div class="card__post__title">
                          <h6>
                            <a :href="'/article/'+this.articleDetail.referArticleList[1].id">
                              {{this.articleDetail.referArticleList[1].articleTitle}}
                            </a>
                          </h6>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <!--第三个文章栏                -->
                  <div class="card__post card__post-list">
                    <div class="image-sm">
                      <a :href="'/article/'+this.articleDetail.referArticleList[2].id">
                        <img :src="this.articleDetail.referArticleList[2].articleCoverPicPath" class="img-fluid" alt="">
                      </a>
                    </div>
                    <div class="card__post__body ">
                      <div class="card__post__content">

                        <div class="card__post__author-info mb-2">
                          <ul class="list-inline">
                            <li class="list-inline-item">
						<span class="text-primary">
							by {{this.articleDetail.referArticleList[2].articleAuthor}}
						</span>
                            </li>
                            <li class="list-inline-item">
						<span class="text-dark text-capitalize">
							{{ this.articleDetail.referArticleList[2].publishDate| moment }}
						</span>
                            </li>

                          </ul>
                        </div>
                        <div class="card__post__title">
                          <h6>
                            <a :href="'/article/'+this.articleDetail.referArticleList[2].id">
                              {{this.articleDetail.referArticleList[2].articleTitle}}
                            </a>
                          </h6>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
                <!--第四个文章栏                -->
                <div class="article__entry">
                  <div class="article__image">
                    <a :href="'/article/'+this.articleDetail.referArticleList[2].id">
                      <img :src="this.articleDetail.referArticleList[3].articleCoverPicPath" alt="" class="img-fluid">
                    </a>
                  </div>
                  <div class="article__content">
                    <ul class="list-inline">
                      <li class="list-inline-item">
				<span class="text-primary">
					by {{this.articleDetail.referArticleList[3].articleAuthor}}
				</span>
                      </li>
                      <li class="list-inline-item">
				<span>
					{{ this.articleDetail.referArticleList[3].publishDate| moment }}
				</span>
                      </li>

                    </ul>
                    <h5>
                      <a :href="'/article/'+this.articleDetail.referArticleList[3].id">
                        {{this.articleDetail.referArticleList[3].articleTitle}}
                      </a>
                    </h5>

                  </div>
                </div>
              </div>
            </aside>



            <aside class="wrapper__list__article">
              <h4 class="border_section">热门标签</h4>
            <popular-tag></popular-tag>
            </aside>


          </div>
        </div>
      </div>
    </div>
  </section>




  <section class="wrapper__section p-0">
    <div class="wrapper__section__components">
    <home-footer></home-footer>
    </div>
  </section>


  <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>

</div>
</template>

<script>
import BreadCrumbs from "@/components/breadCrumbs";
import NavbarKit from "@/components/navbarKit";
import CardArticleDetail from "@/components/cardArticleDetail";
import CardArticle from "@/components/cardArticle.vue";
import FormSearchbar from "@/components/formSearchbar.vue";
import HomeFooter from "@/components/homeFooter.vue";
import Comment2 from "@/components/comment.vue";
import http from "@/api/http";
import portal from "@/api/portal";
import moment from "moment";
import PopularTag from "@/components/popularTag";
import ReferTag from "@/components/referTag";


export default {
  name: "ArticleDetail",
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  mounted() {
    this.$store.commit('setBreadCrumb', this.breadCrumb);

    try {
      http.get2(portal.getArticleDetail+"?id="+this.$parent.$route.params.id, {}).catch((data) => {
        console.log(data)
        this.articleDetail = data.data
        console.log('raw2',data.data.articleTextRaw)
      }).then(() => {
        if(this.articleDetail.mayLikeArticleList[0].id!==''){
          this.slickShow()
        }
      })
    }catch (err) {
      this.$message.error(err);
    }
  },

  data() {
    return {
      articleDetail:{
        articleTitle:'标题',
        articleAuthor:'作者',
        category:'分类',
        categoryId:'',
        articleCoverPicPath:'',
        publishDate:'2024-1-1',
        articleView:'999',
        articleTextRaw:'',
        referArticleList:[{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        }],
        mayLikeArticleList:[{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        }],
        referTagList:[{
          id:'',
          tagName:''
        }],
        popularTagList:[{
          id:'',
          tagName:''
        }],
      },
      articleId: this.$route.params.id,
      breadCrumb: [{
        name:'首页',
        to:'/',
      },
      {
        name:'新闻',
        to:'/',
      },
      {
        name:'当前页',
        to:'',
      },
      ],
    }
  },
  created() {
    // this.slickShow();
  },
  methods:{
    slickShow(){

      /*=============================
	  article entry 3 carousel
  =============================*/
      $(".article__entry-carousel-three").slick({
        slidesToShow: 3,
        autoplay: true,
        dots: false,
        lazyLoad: "progressive",
        prevArrow:
            "<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
        nextArrow:
            "<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

    },
  },
  components: {
    ReferTag,
    PopularTag,
    Comment2,
    HomeFooter,
    FormSearchbar,
    CardArticle, CardArticleDetail, NavbarKit,  BreadCrumbs}
}
</script>

<style scoped>
@import '../style/styles.css';
</style>