<template>
  <div class="container">
    <div class="row">
      <div class="container">

        <div class="filter-warp">
          <div class="container">

            <ul class="filter-item">
              <li class="filter-name"><i class="fas fa-layer-group me-1"></i>平台</li>
              <li class="filter-link" v-for="(item,index) of portalList" :key="index"
                  :class="{active: isPortalActive === item.id}"  @click="changePortalClass(item.id)"
                  >
                <span  @click="changePortalClass(item.id)">{{item.name}}</span>
              </li>
            </ul>
            <ul class="filter-item">
              <li class="filter-name"><i class="fas fa-layer-group me-1"></i>游戏类型</li>
              <li class="filter-link" v-for="(item,index) of gameTypeList" :key="index"
                  :class="{active: isGameTypeActive === item.id}"  @click="changeGameTypeClass(item.id)"
              >
                <span @click="changeGameTypeClass(item.id)" >{{item.name}}</span>
              </li>
            </ul>
            <ul class="filter-item">
              <li class="filter-name"><i class="fas fa-sort-amount-down-alt me-1"></i>排序</li>
              <li class="filter-link"  v-for="(item,index) of orderTypeList" :key="index"
                  :class="{active: isOrderTypeActive === item.id}"  @click="changeOrderType(item.id)">
                <span >{{item.name}}</span>
              </li>
            </ul>

          </div>
        </div>


      </div>
    </div>


<!--    <section class="wrap__section bg-light">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-md-12">-->
<!--            <div class="container">-->
<!--              <div class="row" v-for="(chunk, index) in chunkedGameList" :key="index">-->
<!--                <div class="col-lg-3" v-for="game in chunk" :key="game.id">-->
<!--                  &lt;!&ndash; Post Article &ndash;&gt;-->
<!--                  <div class="article__entry">-->
<!--                    <div class="article__image articel__image__transition">-->
<!--                      <a :href="/game/+game.id" >-->
<!--                        <img onerror="this.src='/pic-error-default-img.jpg'" :src="game.coverPicPath" alt="" class="img-fluid" style="height: 100px;width: 200px">-->
<!--                      </a>-->
<!--                    </div>-->
<!--                    <div class="articel__content">-->
<!--                      <div class="article__post__title">-->
<!--                        <h5><a :href="/game/+game.id">-->
<!--                          {{ game.title }}-->
<!--                        </a></h5>-->
<!--                        <ul class="list-inline article__post__author">-->
<!--                          <li class="list-inline-item">-->
<!--                            <span>{{ game.publishDate }}</span>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


<!--    </section>-->


    <section class="wrap__section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="container">
              <div class="row" v-for="(chunk, index) in chunkedGameList" :key="index" style="margin-bottom: 20px">
                <div class="col-lg-3" v-for="game in chunk" :key="game.id" >
                  <article class="post-item item-grid">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">


                    </div>


                    <div class="entry-media ratio ratio-21x9">
                      <a target="_blank"  class="media-img lazy bg-cover bg-center entered loaded"  :href="'/game/'+game.id" title="game.title"  data-ll-status="loaded" :style="{ backgroundImage: 'url(' + game.coverPicPath + ')' }">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>			<div class="entry-wrapper">
                    <div class="entry-cat-dot"><a :href="'/gameStore?isGameTypeActive='+game.gameTypeId">{{game.gameType}}</a></div>

                    <h2 class="entry-title">
                      <a target="_blank" :href="'/game/'+game.id" :title="game.title">{{game.title}}</a>
                    </h2>

                    <div class="entry-desc">{{game.gameAbstract}}</div>


                    <div class="entry-meta">

                      <span class="meta-date"><i class="far fa-clock me-1"></i><time class="pub-date" >{{ game.publishDate }}</time></span>

                      <span class="meta-likes d-none d-md-inline-block"><i class="fa fa-heart me-1"></i>0</span>

                      <span class="meta-fav d-none d-md-inline-block"><i class="fa fa-star me-1"></i>12</span>

                      <span class="meta-views"><i class="fa fa-eye me-1"></i>6.0K</span>

                      <span class="meta-price"><i class="fas fa-coins me-1"></i>5</span>

                    </div>			</div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>

    <!-- Pagination -->
    <div class="pagination-area" v-if="gameStorePage.totalSize!==0" style="margin-left: 30%">
      <el-pagination class="pagination wow fadeIn animated"
                     layout="prev, pager, next"
                     @current-change="handleCurrentChange"
                     @prev-click="handlePrevClick"
                     @next-click="handleNextClick"
                     :total="gameStorePage.totalSize" :current-page="page"   style="margin-right: 50%">
      </el-pagination>
    </div>
    
  </div>
</template>


<script>

import moment from "moment";
import http from "@/api/http";
import portal from "@/api/portal";

export default {
  name: "gameStoreCard",
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  created() {
    this.gameTypeList.forEach((element, index) => {
      if (element.typeId===this.$route.query.isGameTypeActive){
        this.isGameTypeActive = element.id
      }
    })
    this.portalList.forEach((element, index) => {
      if (element.typeId===this.$route.query.isPortalActive){
        this.isPortalActive = element.id
      }
    })
    this.orderTypeList.forEach((element, index) => {
      if (element.typeId===this.$route.query.isOrderTypeActive){
        this.isOrderTypeActive = element.id
      }
    })
    try {
      http.get2(portal.getGameStoreDetail,
          {id:this.$parent.$route.params.id,current:this.page,isPortalActive:this.portalList[this.isPortalActive].typeId,isGameTypeActive:this.gameTypeList[this.isGameTypeActive].typeId,isOrderTypeActive:this.orderTypeList[this.isOrderTypeActive].typeId
          }).catch((data) => {
        console.log(data)
        this.gameStorePage = data.data;
      })
    }catch (err) {
      this.$message.error(err);
    }

  },
  methods:{


    // 当前页码改变事件处理函数
    handleCurrentChange(newPage) {
      console.log('当前页: ' + newPage);
      // 可以在这里更新数据，例如重新请求数据
      try {
        http.get2(portal.getGameStoreDetail,
            {id:this.$parent.$route.params.id,current:newPage,isPortalActive:this.portalList[this.isPortalActive].typeId,isGameTypeActive:this.gameTypeList[this.isGameTypeActive].typeId,isOrderTypeActive:this.orderTypeList[this.isOrderTypeActive].typeId
        }).catch((data) => {
          console.log(data)
          this.gameStorePage = data.data;
        }).then(() => {
          this.page = newPage;
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    //
    changePortalClass(i) {
      this.isPortalActive = i;
      this.page = 1;
      this.handleCurrentChange(this.page);
    },
    changeGameTypeClass(i){
      this.isGameTypeActive = i;
      this.page = 1;
      this.handleCurrentChange(this.page);
    },
    changeOrderType(i){
      this.isOrderTypeActive = i;
      this.page = 1;
      this.handleCurrentChange(this.page);
    },
    // 上一页按钮点击事件处理函数
    handlePrevClick() {
      console.log('上一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.page -1
      try {
        http.get2(portal.getGameStoreDetail,
            {id:this.$parent.$route.params.id,current:newPage,isPortalActive:this.portalList[this.isPortalActive].typeId,isGameTypeActive:this.gameTypeList[this.isGameTypeActive].typeId,isOrderTypeActive:this.orderTypeList[this.isOrderTypeActive].typeId
            }).catch((data) => {
          console.log(data)
          this.gameStorePage = data.data;
        }).then(() => {
          this.page = newPage;
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    // 下一页按钮点击事件处理函数
    handleNextClick() {
      console.log('下一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.page +1
      try {
        http.get2(portal.getGameStoreDetail,
            {id:this.$parent.$route.params.id,current:newPage,isPortalActive:this.portalList[this.isPortalActive].typeId,isGameTypeActive:this.gameTypeList[this.isGameTypeActive].typeId,isOrderTypeActive:this.orderTypeList[this.isOrderTypeActive].typeId
            }).catch((data) => {
          console.log(data)
          this.gameStorePage = data.data;
        }).then(() => {
          this.page = newPage;
        })
      }catch (err) {
        this.$message.error(err);
      }

    }

  },
  computed: {
    chunkedGameList() {
      // 将文章数据分块，每行4篇文章
      const chunkSize = 4;
      const chunks = [];
      for (let i = 0; i < this.gameStorePage.gameList.length; i += chunkSize) {
        chunks.push(this.gameStorePage.gameList.slice(i, i + chunkSize));
      }
      return chunks;
    }
  },
  data(){
    return {
      page:1,
      isPortalActive:0,
      isGameTypeActive:0,
      isOrderTypeActive:0,
      portalList:[
        {
          id: 0,
          typeId: "PTO00",
          name:"全部",
        },{
          id: 1,
          typeId: "PTO01",
          name:"PC游戏",
        },{
          id: 2,
          typeId:"PTO02",
          name:"主机游戏",
        },{
          id: 3,
          typeId:"PTO03",
          name:"安卓游戏",
        },
      ],
      gameTypeList:[
        {
          id: 0,
          typeId:"GTO00",
          name:"全部",
        },
        {
          id: 1,
          typeId:"GTO01",
          name:"动作冒险",
        },
        {
          id: 2,
          typeId:"GTO02",
          name:"模拟经营",
        },
        {
          id: 3,
          typeId:"GTO03",
          name:"角色扮演",
        },
        {
          id: 4,
          typeId:"GTO04",
          name:"策略战棋",
        },
        {
          id: 5,
          typeId:"GTO05",
          name:"休闲益智",
        },
        {
          id: 6,
          typeId:"GTO06",
          name:"恐怖惊悚",
        },
        {
          id: 7,
          typeId:"GTO07",
          name:"枪战射击",
        },
        {
          id: 8,
          typeId:"GTO08",
          name:"体育竞速",
        },
        {
          id: 9,
          typeId:"GTO09",
          name:"即时战略",
        },
        {
          id: 10,
          typeId:"GTO10",
          name:"策略塔防",
        },
        {
          id: 11,
          typeId:"GTO11",
          name:"飞行射击",
        },
        {
          id: 12,
          typeId:"GTO12",
          name:"音乐节奏",
        },
        {
          id: 13,
          typeId:"GTO13",
          name:"Switch",
        },
        {
          id: 14,
          typeId:"GTO14",
          name:"PS3/4",
        },
      ],
      orderTypeList:[
        {
          id: 0,
          typeId:"OTO00",
          name:'热度',
        },
        {
          id: 1,
          typeId:"OTO01",
          name:'最新',
        }
      ],
      gameStorePage:{
        gameList:[

        ],
        totalSize:0,
        pageSize:'',
        page:1,
      },
    }
  }
}
</script>

<style scoped>
span { cursor: pointer;}
</style>