<template>
<div>

  <header class="bg-light">
    <!-- navbar -->
    <navbar-kit></navbar-kit>
    <!-- end navbar -->
  </header>

  <register></register>
  <section class="wrapper__section p-0">
    <div class="wrapper__section__components">
      <home-footer></home-footer>
    </div>
  </section>


  <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>



</div>

</template>

<script>
import Register from "@/components/register.vue";
import HomeFooter from "@/components/homeFooter.vue";
import NavbarKit from "@/components/navbarKit.vue";

export default {
  name: "registerPage",
  components: {NavbarKit, HomeFooter,  Register}
}
</script>

<style scoped>

</style>