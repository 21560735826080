<template>

  <div class="home">

    <div class="loading-container">
      <div class="h-100 d-flex align-items-center justify-content-center">
        <ul class="list-unstyled">
          <li>
            <img src="../assets/pic/cangshu.png" alt="Alternate Text" height="100"/>

          </li>
          <li>

            <div class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>

            </div>

          </li>
          <li>
            <p>Loading</p>
          </li>
        </ul>
      </div>
    </div>

    <!-- 导航菜单框   -->
<!--    <home-header></home-header>-->
<head-top></head-top>
    <!--  首页图片轮播上方三个小新闻  -->
    <section class="bg-light">
      <div class="container">
        <div class="row" style="width: 100%">
          <div class="col-md-12">
            <div class="wrapp__list__article-responsive wrapp__list__article-responsive-carousel">

              <div class="item" v-for="(item,index) in this.data.mainPageInfo.headArticle.bsPortalPresentList" :key="index">
                <!-- Post Article -->
                <div class="card__post card__post-list" style="width: 100%">
                  <div class="image-sm" style="width: 50%">
                    <router-link :to="{path:item.prefix+'/'+item.id}">
                      <img onerror="this.src='/tupianbujianle.png'" :src="item.coverPicPath" class="img-fluid" alt="">
                    </router-link>
                    <!--                    <a :href="item.prefix+'/'+item.id">-->
                    <!--                      <img onerror="this.src='/tupianbujianle.png'" :src="item.coverPicPath" class="img-fluid" alt="">-->
                    <!--                    </a>-->
                  </div>


                  <div class="card__post__body "  style="margin-right: 0">
                    <div class="card__post__content">
                      <div style="margin-right: 10px" class="card__post__category">
                        {{ item.category }}
                      </div>
                      <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>

                      <div class="card__post__title ">
                        <h5>
                          <router-link style="color: #0f0f10" :to="{path:item.prefix+'/'+item.id}">
                            {{ item.title }}
                          </router-link>
                        </h5>
                        <!--                         <p class="d-none d-lg-block d-xl-block">-->
                        <!--                            Maecenas accumsan tortor ut velit pharetra mollis. Proin eu nisl et arcu iaculis placerat-->
                        <!--                            sollicitudin ut est. In fringilla dui dui.-->
                        <!--                        </p>-->
                      </div>
                      <div class="card__post__author-info mb-2">
                        <ul class="list-inline">

                          <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                          {{ item.publishDate| moment }}
                        </span>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>


    <!--   首页图片轮播及下方一行新闻 -->
    <section>

      <!-- 图片轮播-->
      <div class="popular__news-header">
        <div class="container">

          <div class="row no-gutters">

<!--      中间轮播大屏，可按需求新增      -->
            <div class="col-md-8 ">
              <div class="card__post-carousel">
                <div class="item">
                  <!-- Post Article -->
                  <div class="card__post">
                    <div class="card__post__body">
                      <a :href="this.data.mainPageInfo.broadArticle.centerPresentList[0].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[0].id">
                        <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.broadArticle.centerPresentList[0].coverPicPath" class="img-fluid" alt="" style="object-fit: fill">
                      </a>
                      <div class="card__post__content bg__post-cover">
                        <div class="card__post__category">
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[0].category }}
                        </div>
                        <div class="card__post__title">
                          <h2>
                            <router-link :to="this.data.mainPageInfo.broadArticle.centerPresentList[1].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[0].id">
                              {{ this.data.mainPageInfo.broadArticle.centerPresentList[0].title }}
                            </router-link>
                          </h2>
                        </div>
                        <div class="card__post__author-info">
                          <ul class="list-inline">
                            <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[0].publishDate| moment }}
                        </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <!-- Post Article -->
                  <div class="card__post">
                    <div class="card__post__body">
                      <a :href="this.data.mainPageInfo.broadArticle.centerPresentList[1].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[1].id">
                        <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.broadArticle.centerPresentList[1].coverPicPath" class="img-fluid" alt="" style="object-fit: fill">
                      </a>
                      <div class="card__post__content bg__post-cover">
                        <div class="card__post__category">
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[1].category }}
                        </div>
                        <div class="card__post__title">
                          <h2>
                            <a :href="this.data.mainPageInfo.broadArticle.centerPresentList[1].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[1].id">
                              {{ this.data.mainPageInfo.broadArticle.centerPresentList[1].title }}
                            </a>
                          </h2>
                        </div>
                        <div class="card__post__author-info">
                          <ul class="list-inline">

                            <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[1].publishDate| moment }}
                        </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="item">
                  <!-- Post Article -->
                  <div class="card__post">
                    <div class="card__post__body">
                      <a :href="this.data.mainPageInfo.broadArticle.centerPresentList[2].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[2].id">
                        <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.broadArticle.centerPresentList[2].coverPicPath" class="img-fluid" alt="" style="object-fit: fill">
                      </a>
                      <div class="card__post__content bg__post-cover">
                        <div class="card__post__category">
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[2].category }}
                        </div>
                        <div class="card__post__title">
                          <h2>
                            <a :href="this.data.mainPageInfo.broadArticle.centerPresentList[2].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[2].id">
                              {{ this.data.mainPageInfo.broadArticle.centerPresentList[2].title }}
                            </a>
                          </h2>
                        </div>
                        <div class="card__post__author-info">
                          <ul class="list-inline">

                            <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[2].publishDate| moment }}
                        </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <!-- Post Article -->
                  <div class="card__post">
                    <div class="card__post__body">
                      <a :href="this.data.mainPageInfo.broadArticle.centerPresentList[3].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[3].id">
                        <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.broadArticle.centerPresentList[3].coverPicPath" class="img-fluid" alt="" style="object-fit: fill">
                      </a>
                      <div class="card__post__content bg__post-cover">
                        <div class="card__post__category">
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[3].category }}
                        </div>
                        <div class="card__post__title">
                          <h2>
                            <a :href="this.data.mainPageInfo.broadArticle.centerPresentList[3].prefix+'/'+this.data.mainPageInfo.broadArticle.centerPresentList[3].id">
                              {{ this.data.mainPageInfo.broadArticle.centerPresentList[3].title }}
                            </a>
                          </h2>
                        </div>
                        <div class="card__post__author-info">
                          <ul class="list-inline">

                            <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.broadArticle.centerPresentList[3].publishDate| moment }}
                        </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
<!--      轮播右侧两个标签               -->

            <div class="col-md-4">
              <div class="popular__news-right">
                <!-- 轮播右侧-上 -->
                <div class="card__post ">
                  <div class="card__post__body card__post__transition">
                    <a :href="this.data.mainPageInfo.broadArticle.rightPresentList[0].prefix+'/'+this.data.mainPageInfo.broadArticle.rightPresentList[0].id">
                      <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.broadArticle.rightPresentList[0].coverPicPath"
                           class="img-fluid" alt="">
                    </a>
                    <div class="card__post__content bg__post-cover">
                      <div class="card__post__category">
                        {{ this.data.mainPageInfo.broadArticle.rightPresentList[0].category }}
                      </div>
                      <div class="card__post__title">
                        <h5>
                          <a :href="this.data.mainPageInfo.broadArticle.rightPresentList[0].prefix+'/'+this.data.mainPageInfo.broadArticle.rightPresentList[0].id">
                            {{ this.data.mainPageInfo.broadArticle.rightPresentList[0].title }}
                          </a>
                        </h5>
                      </div>
                      <div class="card__post__author-info">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.broadArticle.rightPresentList[0].publishDate| moment }}
                        </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- 轮播右侧-下 -->
                <div class="card__post ">
                  <div class="card__post__body card__post__transition">
                    <router-link :to="{path:this.data.mainPageInfo.broadArticle.rightPresentList[1].prefix+'/'+this.data.mainPageInfo.broadArticle.rightPresentList[1].id}">
                      <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.broadArticle.rightPresentList[1].coverPicPath"
                           class="img-fluid" alt="">
                    </router-link>
                    <div class="card__post__content bg__post-cover">
                      <div class="card__post__category">
                        {{ this.data.mainPageInfo.broadArticle.rightPresentList[1].category }}
                      </div>
                      <div class="card__post__title">
                        <h5>
                          <router-link
                              :to="{path:this.data.mainPageInfo.broadArticle.rightPresentList[1].prefix+'/'+this.data.mainPageInfo.broadArticle.rightPresentList[1].id}">
                            {{ this.data.mainPageInfo.broadArticle.rightPresentList[1].title }}
                          </router-link>
                        </h5>
                      </div>
                      <div class="card__post__author-info">
                        <ul class="list-inline">

                          <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.broadArticle.rightPresentList[1].publishDate| moment }}
                        </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 轮播下方一行新闻 -->
      <div class="popular__news-header-carousel" id="mianfei">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="top__news__slider">
                <div class="item" v-for="(item,index) in this.data.mainPageInfo.broadArticle.belowPresentList" :key="index">
                  <!-- Post Article -->
                  <div class="article__entry">
                    <div class="article__image">
                      <router-link :to="{path:item.prefix+'/'+item.id}">
                        <img onerror="this.src='/tupianbujianle.png'" :src="item.coverPicPath" alt="" class="img-fluid" style="max-height: 100px;min-width: 200px">
                      </router-link>
                    </div>
                    <div class="article__content">
                      <div style="margin-right: 10px" class="article__category">
                        {{ item.category }}
                      </div>
                      <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>
                      <h5>
                        <router-link :to="{path:item.prefix+'/'+item.id}">
                          {{ item.title }}
                        </router-link>
                      </h5>
                      <ul class="list-inline">

                        <li class="list-inline-item">
                <span>
                          {{ item.publishDate| moment }}
                </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </section>

    <!-- 下方多模块   -->
    <section class="pt-0">
      <!-- 热门游戏 -->
      <div class="popular__section-news">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="wrapper__list__article">
                <h4 class="border_section">热门游戏</h4>
              </div>
              <!--     上二         -->
              <div class="row ">
                <!--         上二-左       -->
                <div class="col-sm-12 col-md-6 mb-4">

                  <!-- Post Article -->
                  <div class="card__post ">
                    <div class="card__post__body card__post__transition">
                      <router-link
                          :to="{path:'/game/'+this.data.mainPageInfo.popularGame.recentTopPresentList[0].id}">
                        <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.popularGame.recentTopPresentList[0].coverPicPath"
                             class="img-fluid" alt="">
                      </router-link>
                      <div class="card__post__content bg__post-cover">
                        <div class="card__post__category">
                          {{ this.data.mainPageInfo.popularGame.recentTopPresentList[0].category }}
                        </div>
                        <div class="card__post__title">
                          <h5>
                            <router-link
                                :to="{path:'/game/'+this.data.mainPageInfo.popularGame.recentTopPresentList[0].id}">
                              {{ this.data.mainPageInfo.popularGame.recentTopPresentList[0].title }}
                            </router-link>
                          </h5>
                        </div>
                        <div class="card__post__author-info">
                          <ul class="list-inline">
<!--                            <li class="list-inline-item">-->
<!--&lt;!&ndash;                              <router-link&ndash;&gt;-->
<!--&lt;!&ndash;                                  :to="{path:'/game/'+this.data.mainPageInfo.popularGame.recentTopPresentList[0].id}">&ndash;&gt;-->
<!--&lt;!&ndash;                                by {{ this.data.mainPageInfo.popularGame.recentTopPresentList[0].articleAuthor }}&ndash;&gt;-->
<!--&lt;!&ndash;                              </router-link>&ndash;&gt;-->
<!--                            </li>-->
                            <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.popularGame.recentTopPresentList[0].publishDate| moment }}
                        </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!--         上二-右       -->
                <div class="col-sm-12 col-md-6 mb-4">
                  <!-- Post Article -->
                  <div class="card__post ">
                    <div class="card__post__body card__post__transition">
                      <router-link
                          :to="{path:'/game/'+this.data.mainPageInfo.popularGame.recentTopPresentList[1].id}">
                        <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.popularGame.recentTopPresentList[1].coverPicPath"
                             class="img-fluid" alt="">
                      </router-link>
                      <div class="card__post__content bg__post-cover">
                        <div class="card__post__category">
                          {{ this.data.mainPageInfo.popularGame.recentTopPresentList[1].category }}
                        </div>
                        <div class="card__post__title">
                          <h5>
                            <router-link
                                :to="{path:'/game/'+this.data.mainPageInfo.popularGame.recentTopPresentList[1].id}">
                              {{ this.data.mainPageInfo.popularGame.recentTopPresentList[1].title }}
                            </router-link>
                          </h5>
                        </div>
                        <div class="card__post__author-info">
                          <ul class="list-inline">
<!--                            <li class="list-inline-item">-->
<!--                              <router-link-->
<!--                                  :to="{path:'/game/'+this.data.mainPageInfo.popularGame.recentTopPresentList[1].id}">-->
<!--                                by {{ this.data.mainPageInfo.popularGame.recentTopPresentList[1].articleAuthor }}-->
<!--                              </router-link>-->
<!--                            </li>-->
                            <li class="list-inline-item">
                        <span>
                          {{ this.data.mainPageInfo.popularGame.recentTopPresentList[1].publishDate| moment }}
                        </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!--       下四       -->
              <div class="row">
                <div class="col-sm-12 col-md-6" v-for="(item, index) in data.mainPageInfo.popularGame.recentBelowPresentList" :key="index" v-if="index < 2">
                  <div class="wrapp__list__article-responsive">
                    <div class="mb-3">
                      <!-- Post Article -->
                      <div class="card__post card__post-list">
                        <div class="image-sm">
                          <router-link :to="{ path: '/game/' + item.id }">
                            <img :src="item.coverPicPath" class="img-fluid" alt="">
                          </router-link>
                        </div>
                        <div class="card__post__body">
                          <div class="card__post__content">
                            <div style="margin-right: 10px" class="card__post__category">
                              {{ item.category }}
                            </div>
                            <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>

                            <div class="card__post__title">
                              <h5>
                                <a :href=" '/game/' + item.id " style="color: #000000">
                                  {{ item.title }}
                                </a>
                              </h5>
                            </div>
                            <div class="card__post__author-info mb-2">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                    <span class="text-dark text-capitalize">
                      {{ item.publishDate | moment }}
                    </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6" v-for="(item, index) in data.mainPageInfo.popularGame.recentBelowPresentList" :key="index" v-if="index >= 2 && index < 4">
                  <div class="wrapp__list__article-responsive">
                    <div class="mb-3">
                      <!-- Post Article -->
                      <div class="card__post card__post-list">
                        <div class="image-sm">
                          <router-link :to="{ path: '/game/' + item.id }">
                            <img :src="item.coverPicPath" class="img-fluid" alt="">
                          </router-link>
                        </div>
                        <div class="card__post__body">
                          <div class="card__post__content">
                            <div style="margin-right: 10px" class="card__post__category">
                              {{ item.category }}
                            </div>
                            <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>
                            <div class="card__post__title">
                              <h5>
                                <a :href="'/game/' + item.id" style="color: #000000">
                                  {{ item.title }}
                                </a>
                              </h5>
                            </div>
                            <div class="card__post__author-info mb-2">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                    <span class="text-dark text-capitalize">
                      {{ item.publishDate | moment }}
                    </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- 右边流行排行-->
            <div class="col-md-12 col-lg-4" >
              <aside class="wrapper__list__article">
                <h4  class="border_section">免费下载（每周更新）</h4>
                <div class="wrapper__list-number">
                  <!-- List Article -->
                  <div class="card__post__list"
                       v-for="(item,index) in this.data.mainPageInfo.popularGame.freePresentList" :key="index">
                    <ul class="list-inline">
                      <li class="list-inline-item" style="display: flex;align-items: center">
                        <div class="list-number" style="margin-right: 10px">
                          <span  style="">
                              {{ index + 1 }}
                          </span>
                        </div>
                        <div class="game-title" style="display: flex;align-items: center">
                          <h5>
                            <router-link :to="{path:'/game/'+item.id}">
                              <span  style="font-size: large;max-width: 100px">  {{ item.title }}</span>
                            </router-link>
                          </h5>
                        </div>
                      </li>
                    </ul>

                  </div>

                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>

      <!-- Post news carousel -->
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <aside class="wrapper__list__article">
              <h4 class="border_section">必玩大作</h4>
            </aside>
          </div>
          <div class="col-md-12">

            <!-- 又是一行新闻           -->
            <div class="article__entry-carousel">
              <div class="item" v-for="(item,index) in data.mainPageInfo.themeArticle.theme1PresentList" :key="index">
                <!-- Post Article -->
                <div class="article__entry">
                  <div class="article__image">
                    <router-link :to="{path:item.prefix+'/'+item.id}">
                      <img onerror="this.src='/tupianbujianle.png'" :src="item.coverPicPath" alt="" class="img-fluid" style="max-height: 100px;min-width: 200px">
                    </router-link>
                  </div>
                  <div class="article__content">
                    <div style="margin-right: 10px" class="article__category">
                      {{ item.category }}
                    </div>
                    <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>
                    <h5>
                      <router-link :to="{path:item.prefix+'/'+item.id}">
                        <span  class="single-line">
                           {{ item.title }}
                        </span>
                      </router-link>
                    </h5>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                <span>
                          {{ item.publishDate| moment }}
                </span>
                      </li>

                    </ul>


                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- End Popular news category -->


      <!-- Popular news category -->
      <div class="mt-4">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <aside class="wrapper__list__article mb-0">
                <h4 class="border_section">主机游戏</h4>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-4" v-for="(item,index) in this.data.mainPageInfo.themeArticle.theme2PresentList.slice(0,3)" :key="index">
                      <!-- Post Article -->
                      <div class="article__entry" >
                        <div class="article__image" >
                          <router-link
                              :to="{path:item.prefix+'/'+item.id}">
                            <img onerror="this.src='/tupianbujianle.png'" :src="item.coverPicPath"
                                 alt="" class="img-fluid" style="max-height: 100px;width: 250px">
                          </router-link>
                        </div>
                        <div class="article__content">
                          <div style="margin-right: 10px" class="article__category">
                            {{ item.category }}
                          </div>
                          <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>
                          <h5>
                            <router-link
                                :to="{path:item.prefix+'/'+item.id}">
                              {{ item.title }}
                            </router-link>
                          </h5>
                          <ul class="list-inline">
<!--                            <li class="list-inline-item">-->
<!--                <span class="text-primary">-->
<!--                    by {{ item.articleAuthor }}-->
<!--                </span>-->
<!--                            </li>-->
                            <li class="list-inline-item">
                <span>
                          {{ item.publishDate| moment }}
                </span>
                            </li>

                          </ul>


                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <div class="mb-4"  v-for="(item,index) in this.data.mainPageInfo.themeArticle.theme2PresentList.slice(3,6)" :key="index">
                      <!-- Post Article -->
                      <div class="article__entry">
                        <div class="article__image">
                          <router-link
                              :to="{path:item.prefix+'/'+item.id}">
                            <img onerror="this.src='/tupianbujianle.png'" :src="item.coverPicPath"
                                 alt="" class="img-fluid" style="max-height: 100px;width: 250px">
                          </router-link>
                        </div>
                        <div class="article__content">
                          <div style="margin-right: 10px" class="article__category">
                            {{ item.category }}
                          </div>
                          <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>
                          <h5>
                            <router-link
                                :to="{path:item.prefix+'/'+item.id}">
                              {{ item.title }}
                            </router-link>
                          </h5>
                          <ul class="list-inline">
<!--                            <li class="list-inline-item">-->
<!--                <span class="text-primary">-->
<!--                    by {{ item.articleAuthor }}-->
<!--                </span>-->
<!--                            </li>-->
                            <li class="list-inline-item">
                <span>
                          {{ item.publishDate| moment }}
                </span>
                            </li>

                          </ul>


                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </aside>
              <aside class="wrapper__list__article">
                <h4 class="border_section">安卓游戏</h4>
                <div class="wrapp__list__article-responsive">
                  <!-- Post Article List -->
                  <div class="card__post card__post-list card__post__transition mt-30"  v-for="(item,index) in this.data.mainPageInfo.themeArticle.theme3PresentList" :key="index">
                    <div class="row w-100">
                      <div class="col-md-6 w-100">
                        <div class="card__post__transition">
                          <router-link
                              :to="{path:item.prefix+'/'+item.id}">
                            <img :src="item.coverPicPath"
                                 class="img-fluid" alt="" style="height: 200px;width: 400px">
                          </router-link>
                        </div>
                      </div>
                      <div class="col-md-6 my-auto pl-0">
                        <div class="card__post__body w-100">
                          <div class="card__post__content  ">
                            <div style="margin-right: 10px" class="card__post__category">
                              {{ item.category }}
                            </div>
                            <a :href="'/gameStore?isGameTypeActive='+item.gameTypeId"><strong>{{item.gameType}}</strong></a>
                            <div class="card__post__title">
                              <h5>
                                <router-link
                                    :to="{path:item.prefix+'/'+item.id}" style="">
                                  {{ item.title }}
                                </router-link>
                              </h5>
                              <div class="card__post__author-info mb-2">
                                <ul class="list-inline">
                                  <li class="list-inline-item">
                                <span class="text-dark text-capitalize">
                          {{ item.publishDate| moment }}
                                </span>
                                  </li>

                                </ul>
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </aside>

            </div>

            <div class="col-md-4">
              <div class="sticky-top">
                <aside class="wrapper__list__article">
                  <h4 class="border_section">游戏排行</h4>
                  <div class="wrapper__list-number">

                    <!-- List Article -->
                    <div class="card__post__list"
                         v-for="(item,index) in this.data.mainPageInfo.popularGame.popularPresentList" :key="index">


                      <ul class="list-inline">
                        <li class="list-inline-item" style="display: flex;align-items: center">
                          <div class="list-number" style="margin-right: 10px">
                          <span  style="">
                              {{ index + 1 }}
                          </span>
                          </div>
                          <div class="game-title" style="display: flex;align-items: center">
                            <h5>
                              <router-link :to="{path:'/game/'+item.id}">
                                <span  style="font-size: medium;max-width: 100px">  {{ item.title }}</span>
                              </router-link>
                            </h5>
                          </div>
                        </li>
                      </ul>

                    </div>

                  </div>
                </aside>
                <aside class="wrapper__list__article">
                  <h4 class="border_section">
                    最新更新</h4>
                  <div class="wrapper__list__article-small">

                    <!-- Post Article -->
                    <div class="article__entry">
                      <div class="article__image">
                        <router-link
                            :to="{path:'/game/'+this.data.mainPageInfo.themeArticle.theme4PresentList[0].id}">
                          <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.themeArticle.theme4PresentList[0].coverPicPath"
                               alt="" class="img-fluid" style="max-height: 100px;min-width: 200px">
                        </router-link>
                      </div>
                      <div class="article__content">
                        <div class="article__category">
                          {{ this.data.mainPageInfo.themeArticle.theme4PresentList[0].category }}
                        </div>
                        <ul class="list-inline">
<!--                          <li class="list-inline-item">-->
<!--                <span class="text-primary">-->
<!--                    by {{ this.data.mainPageInfo.themeArticle.theme4PresentList[0].articleAuthor }}-->
<!--                </span>-->
<!--                          </li>-->
                          <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                          {{ this.data.mainPageInfo.themeArticle.theme3PresentList[0].publishDate| moment }}
                </span>
                          </li>

                        </ul>
                        <h5>
                          <router-link
                              :to="{path:'/game/'+this.data.mainPageInfo.themeArticle.theme4PresentList[0].id}">
                            {{ this.data.mainPageInfo.themeArticle.theme4PresentList[0].title }}
                          </router-link>
                        </h5>

                      </div>
                    </div>
                    <div class="mb-3">
                      <!-- Post Article -->
                      <div class="card__post card__post-list">
                        <div class="image-sm">
                          <router-link
                              :to="{path:'/game/'+this.data.mainPageInfo.themeArticle.theme4PresentList[1].id}">
                            <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.themeArticle.theme4PresentList[1].coverPicPath"
                                 class="img-fluid" alt="">
                          </router-link>
                        </div>


                        <div class="card__post__body ">
                          <div class="card__post__content">

                            <div class="card__post__author-info mb-2">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                        <span class="text-primary">
                            by {{ this.data.mainPageInfo.themeArticle.theme4PresentList[1].articleAuthor }}
                        </span>
                                </li>
                                <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                          {{ this.data.mainPageInfo.themeArticle.theme3PresentList[1].publishDate| moment }}
                        </span>
                                </li>

                              </ul>
                            </div>
                            <div class="card__post__title">
                              <h5>
                                <a :href="'/game/'+this.data.mainPageInfo.themeArticle.theme3PresentList[1].id" style="color: #000">
                                  {{ this.data.mainPageInfo.themeArticle.theme3PresentList[1].title }}
                                </a>
                              </h5>
                              <!-- <p class="d-none d-lg-block d-xl-block">
                                  Maecenas accumsan tortor ut velit pharetra mollis. Proin eu nisl et arcu iaculis placerat
                                  sollicitudin ut est. In fringilla dui dui.
                              </p> -->

                            </div>

                          </div>


                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <!-- Post Article -->
                      <div class="card__post card__post-list">
                        <div class="image-sm">
                          <router-link
                              :to="{path:'/game/'+this.data.mainPageInfo.themeArticle.theme4PresentList[2].id}">
                            <img onerror="this.src='/tupianbujianle.png'" :src="this.data.mainPageInfo.themeArticle.theme4PresentList[2].coverPicPath"
                                 class="img-fluid" alt="">
                          </router-link>
                        </div>


                        <div class="card__post__body ">
                          <div class="card__post__content">

                            <div class="card__post__author-info mb-2">
                              <ul class="list-inline">
                                <li class="list-inline-item">
                        <span class="text-primary">
                            by {{ this.data.mainPageInfo.themeArticle.theme4PresentList[2].articleAuthor }}
                        </span>
                                </li>
                                <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                          {{ this.data.mainPageInfo.themeArticle.theme3PresentList[2].publishDate| moment }}
                        </span>
                                </li>

                              </ul>
                            </div>
                            <div class="card__post__title">
                              <h5>
                                <a :href="'/game/'+this.data.mainPageInfo.themeArticle.theme3PresentList[2].id" style="color: #000">
                                  {{ this.data.mainPageInfo.themeArticle.theme3PresentList[2].title }}
                                </a>
                              </h5>
                              <!-- <p class="d-none d-lg-block d-xl-block">
                                  Maecenas accumsan tortor ut velit pharetra mollis. Proin eu nisl et arcu iaculis placerat
                                  sollicitudin ut est. In fringilla dui dui.
                              </p> -->

                            </div>

                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </aside>



                <aside class="wrapper__list__article">
                  <h4 class="border_section">热门标签</h4>
                  <div class="blog-tags p-0">
                    <ul class="list-inline">

                      <li class="list-inline-item"  v-for="(item,index) in this.data.mainPageInfo.popularTagList" :key="index">
                        <a :href="'/tag/'+item.id">
                          {{item.tagName}}
                        </a>
                      </li>

                    </ul>
                  </div>
                </aside>


              </div>
            </div>

            <div class="clearfix"></div>
          </div>
        </div>
      </div>


      <div class="mt-4">
        <div class="container">
          <div class="row">
            <div class="col-md-8"></div>
            <!-- 右边流行排行-->
            <div class="col-md-12 col-lg-4">

            </div>
          </div>
        </div>
      </div>
    </section>

    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>

    <!-- End Popular news category -->
    <!-- 网站底部   -->
    <home-footer></home-footer>


  </div>
</template>

<script>
import http from "@/api/http";
import portal from "@/api/portal";
import moment from 'moment'
import HomeFooter from "@/components/homeFooter";
import HeadTop from "@/components/headTop.vue";

export default {
  watch:{

  },
  components: { HeadTop, HomeFooter},
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },


  beforeCreate() {
    // if (this.$store.state.pubParam.needMainPageRefresh===1) {
    //   this.$store.commit('setNeedMainPageRefresh', 0);
    //   location.reload();
    // }
  },

  beforeMount() {

  },
  mounted() {
    // 初始化 previousPath 变量为当前路由的路径
    console.log("nowPath",window.location.pathname)
    if (this.$store.state.pubParam.nowPath !== window.location.pathname){
      console.log("this.$store.state.nowPath",this.$store.state.pubParam.nowPath)
      console.log("window.location.href",window.location.pathname)
      console.log("刷新")
      window.location.reload();

    }
  },

  created() {

    try {
      http.get2(portal.getMainPageInfo, {}).catch((data) => {
        console.log(data)
        this.data.mainPageInfo = data.data;
        $(".loading-container").delay(400).fadeOut();
      }).then(() => {
        //mainpagrinfo 必须有值
        if(this.data.mainPageInfo.themeArticle.theme1PresentList[0].id!=="")
        {
          this.slickShow()
          // this.articleThree()
        }

      })
    }catch (err) {
      this.$message.error(err);
    }

  },

  methods: {



    articleThree(){

      // $(".card__post-carousel").slick({
      //   slidesToShow: 1,
      //   autoplay: !0,
      //   dots: !1,
      //   lazyLoad: "progressive",
      //   prevArrow: "<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      //   nextArrow: "<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
      // });

      $(".wrapp__list__article-responsive-carousel").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        dots: false,
        lazyLoad: "progressive",
        prevArrow: false,
        nextArrow: false,
        // mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    },

    slickShow(){

      $(".top__news__slider").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        loop: true,
        dots: false,
        lazyLoad: "progressive",
        prevArrow: false,
        nextArrow: false,
        // mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });



      $(".article__entry-carousel").slick({
      	slidesToShow: 4,
      	autoplay: true,
      	dots: false,
      	lazyLoad: "progressive",
      	prevArrow:
      		"<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      	nextArrow:
      		"<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
      	responsive: [
      		{
      			breakpoint: 1024,
      			settings: {
      				slidesToShow: 4,
      				slidesToScroll: 3,
      				infinite: true,
      				dots: false,
      			}
      		},
      		{
      			breakpoint: 768,
      			settings: {
      				slidesToShow: 2,
      				slidesToScroll: 2
      			}
      		},
      		{
      			breakpoint: 480,
      			settings: {
      				slidesToShow: 2,
      				slidesToScroll: 1
      			}
      		}
      	]
      });

    },


  },
  data() {
    return {
      data: {
        mainPageInfo: {
          headArticle: {
            bsPortalPresentList: [
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
                prefix:"",
              },{
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
                prefix:"",
              },{
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
                prefix:"",
              }]
          },
          broadArticle: {
            centerPresentList: [
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              },{
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              },{
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              },{
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              },],
            rightPresentList: [
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              }, {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              }],
            belowPresentList: [
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              }]
          },
          popularGame: {
            recentTopPresentList: [
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              },
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              }
            ],
            recentBelowPresentList: [
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              },
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              },
              {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              }, {
                id: '',
                author: '',
                publishDate: '',
                title: "",
                coverPicPath: "",
                category: "",
                articleAbstract: "",
              }
            ],
            popularPresentList: [
              {
              }, {

              }, {

              }, {

              },
            ],
            freePresentList: [
              {},
              {},
              {},
              {},
              {},
            ],
          },
          themeArticle: {
            theme1PresentList: [{
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            },{
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            },{
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            },{
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            },],
            theme2PresentList: [{
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            },],
            theme3PresentList: [{
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            },],
            theme4PresentList: [{
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            }, {
              id: '',
              author: '',
              publishDate: '',
              title: "",
              coverPicPath: "",
              category: "",
              articleAbstract: "",
            },],
          },
          popularTagList:[{
            id:'',
            tagName:''
          }]
        }
      }
    };
  },
};
</script>
<style lang="less">
@import '../style/styles.css';
.single-line {
  width: 30px; /* 设置容器宽度 */
  overflow: hidden; /* 隐藏溢出内容 */
  text-overflow: ellipsis; /* 使用省略号表示溢出文本 */
}
</style>
