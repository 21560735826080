export default {
    state: {
        nowPath: '/',

    },
    mutations: {

        setNowPath(state, val){
            state.nowPath = val
        },




    }
}