export default {
    state: {
        breadCrumb: [{
            name:'',
            to:'',
        }],

    },
    mutations: {

        setBreadCrumb(state, val){
            state.breadCrumb = val
        },




    }
}