<template>
  <div class="widget__form-search-bar  ">
    <div class="row no-gutters">
      <div class="col">
        <input class="form-control border-secondary border-right-0 rounded-0" value="" placeholder="Search">
      </div>
      <div class="col-auto">
        <button class="btn btn-outline-secondary border-left-0 rounded-0 rounded-right">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "formSearchbar"
}
</script>

<style scoped>

</style>