import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/PortalHome.vue";
import ArticleDetail from "@/views/ArticleDetail";
import loginPage from "@/views/loginPage.vue";
import registerPage from "@/views/registerPage.vue";
import GameDetail from "@/views/GameDetail.vue";
import SearchPage from "@/views/SearchPage";
import categoryPage from "@/views/categoryPage.vue";
import gameStorePage from "@/views/GameStorePage.vue";
import gameTagPage from "@/views/GameTagPage.vue";
import articleTagPage from "@/views/ArticleTagPage.vue";
import profileBasicInfo from "@/views/profileBasicInfo.vue";
import profileBalance from "@/views/profileBalance.vue";
import profileMember from "@/views/profileMember.vue";
import profileDownLoadDetail from "@/views/profileDownLoadDetail.vue";
import profileIssue from "@/views/profileIssue.vue";
import profilelikes from "@/views/profilelikes.vue";
import logout from "@/views/logout.vue";
import gameDownLoad from "@/views/gameDownLoad.vue";
import memberAds from "@/views/memberAds.vue";

const routes = [
    //主路由
    {
        path: '/',
        component: Home,
    },
    {
        path: '/article/:id',
        component: ArticleDetail,
    },
    {
        path: '/login',
        component: loginPage,
    },
    {
        path: '/register',
        component: registerPage,
    },
    {
        path: '/game/:id',
        component: GameDetail,
    },
    {
        path: '/search',
        component: SearchPage,
    },
    {
        path: '/category/:id',
        component: categoryPage,
    },
    {
        path: '/gameTag/:id',
        component: gameTagPage,
    },
    {
        path: '/articleTag/:id',
        component: articleTagPage,
    },
    {
        path: '/gameStore',
        component: gameStorePage,
    },
    {
        path: '/profileBasicInfo',
        component: profileBasicInfo
    },
    {
        path: '/profileBalance',
        component: profileBalance
    },
    {
        path: '/profileMember',
        component: profileMember
    },
    {
        path: '/profileDownLoadDetail',
        component: profileDownLoadDetail
    },
    {
        path: '/profileIssue',
        component: profileIssue
    },
    {
        path: '/profileLikes',
        component: profilelikes
    },
    {
        path: '/logout',
        component: logout
    },
    {
        path: '/downLoad/gameDownLoad',
        component: gameDownLoad
    },
    {
        path: '/ad/member',
        component: memberAds
    }
]

const router = new VueRouter({
    routes, // (缩写) 相当于 routes: routes
    mode: 'history'
})

// router.beforeEach((to,from,next)=>{
//     const token = Cookies.get('token')
//     if(!token && to.name !=='login'){
//         next({name:'login'})
//     }else if(token && to.name=='login'){
//         next({name:'home'})
//     }else{
//         next()
//     }
// })

Vue.use(VueRouter)

export default router