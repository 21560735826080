<template>
  <div>
    <header class="bg-light">
      <!-- navbar -->
      <navbar-kit></navbar-kit>
      <!-- end navbar -->
    </header>

    <login></login>

    <section class="wrapper__section p-0">
      <div class="wrapper__section__components">
        <home-footer></home-footer>
      </div>
    </section>
  </div>

</template>

<script>
import Login from "@/components/login.vue";
import NavbarKit from "@/components/navbarKit.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "loginPage",
  components: {HomeFooter, NavbarKit, Login}
}
</script>

<style scoped>
@import '../style/styles.css';
</style>