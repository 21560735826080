<template>
  <div>

    <header class="bg-light">
      <!-- navbar -->
      <navbar-kit></navbar-kit>
      <!-- end navbar -->
    </header>


    <section class="bg-white pb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- Breadcrumb -->
            <bread-crumbs></bread-crumbs>
          </div>

        </div>
        <div class="row">
          <div class="col-md-8">
            <!-- Social media animation -->
            <!-- Article detail -->


            <card-game-detail></card-game-detail>

            <!-- News Tags -->
            <refer-tag-game></refer-tag-game>

            <!--游戏下载-->
            <br>
            <div class="article-down">
              <div class="title"><h3>下载地址</h3></div>

              <ul class="list-inline" v-if="this.gameDetail.downLoadList!==undefined&&this.gameDetail.downLoadList.length>0">
                <li v-for="(item,index) in this.gameDetail.downLoadList" :key="index">
                  <a class="downbtn normal" style="cursor: pointer" @click.prevent="handleDownload(item)">
                    {{ item.downLoadType }}
                  </a>
                </li>
              </ul>

              <img src="../assets/image/empty.png" style="background-color: transparent;"
                   v-if="this.gameDetail.downLoadList===undefined||this.gameDetail.downLoadList.length===0">


            </div>

            <!--mod下载-->

            <!--            </section>-->

            <!-- Profile author -->
            <!--            <profile-author></profile-author>-->


            <!-- Comment  -->
            <!--            <comment2></comment2>-->
            <!-- Comment -->

            <!--            <div class="row">-->
            <!--              <div class="col-md-6">-->
            <!--                <div class="single_navigation-prev">-->
            <!--                  <a href="#">-->
            <!--                    <span>previous post</span>-->
            <!--                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem, similique.-->
            <!--                  </a>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="col-md-6">-->
            <!--                <div class="single_navigation-next text-left text-md-right">-->
            <!--                  <a href="#">-->
            <!--                    <span>next post</span>-->
            <!--                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis, nesciunt.-->
            <!--                  </a>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="clearfix"></div>-->

          </div>

          <div class="col-md-4">
            <aside class="wrapper__list__article">
              <h4 class="border_section">相关推荐</h4>
              <div class="wrapper__list__article-small">
                <!-- Post Article -->
                <div class="article__entry">
                  <div class="article__image">
                    <a :href="'/game/'+this.gameDetail.referGameList[0].id">
                      <img :src="this.gameDetail.referGameList[0].coverPicPath" alt="" class="img-fluid">
                    </a>
                  </div>
                  <div class="article__content">
                    <div class="article__category">
                      {{ this.gameDetail.referGameList[0].category }}
                    </div>
                    <ul class="list-inline">
                      <!--                      <li class="list-inline-item">-->
                      <!--                <span class="text-primary">-->
                      <!--&lt;!&ndash;                    by david hall&ndash;&gt;-->
                      <!--                </span>-->
                      <!--                      </li>-->
                      <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                  {{ this.gameDetail.referGameList[0].publishDate| moment }}
                </span>
                      </li>

                    </ul>
                    <h5>
                      <a :href="'/game/'+this.gameDetail.referGameList[0].id">
                        {{ this.gameDetail.referGameList[0].title }}
                      </a>
                    </h5>
                    <!--                    <p>-->
                    <!--                      Maecenas accumsan tortor ut velit pharetra mollis. Proin eu nisl et arcu iaculis placerat sollicitudin ut-->
                    <!--                      est. In fringilla dui dui.-->
                    <!--                    </p>-->
                    <!--                    <a :href="'/game/'+this.gameDetail.referGameList[0].id" class="btn btn-outline-primary mb-4 text-capitalize"> 查看更多</a>-->
                  </div>
                </div>
                <div class="mb-3" v-for="(item,index) in this.gameDetail.referGameList.slice(1,5)" :key="index">
                  <!-- Post Article -->
                  <div class="card__post card__post-list">
                    <div class="image-sm">
                      <a :href="'/game/'+item.id">
                        <img :src="item.coverPicPath" class="img-fluid" alt="">
                      </a>
                    </div>


                    <div class="card__post__body ">
                      <div class="card__post__content">

                        <div class="card__post__author-info mb-2">
                          <ul class="list-inline">
                            <!--                            <li class="list-inline-item">-->
                            <!--                        <span class="text-primary">-->
                            <!--                            by david hall-->
                            <!--                        </span>-->
                            <!--                            </li>-->
                            <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                  {{ item.publishDate| moment }}
                        </span>
                            </li>

                          </ul>
                        </div>
                        <div class="card__post__title">
                          <h6>
                            <a :href="'/game/'+item.id">
                              {{ item.title }}
                            </a>
                          </h6>

                        </div>

                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </aside>


            <div class="sidebar-section">
              <aside class="wrapper__list__article">
                <h4 class="border_section">热门游戏</h4>

                <!-- Post Article List -->
                <div class=""
                     v-for="(game,index) in this.gameDetail.popularGameList" :key="index">
                  <article class="post-item item-grid">

                    <div class="tips-badge position-absolute top-0 start-0 z-1 m-2">


                    </div>


                    <div class="entry-media ratio ratio-21x9">
                      <a target="_blank" class="media-img lazy bg-cover bg-center entered loaded"
                         :href="'/game/'+game.id" title="game.title" data-ll-status="loaded"
                         :style="{ backgroundImage: 'url(' + game.coverPicPath + ')'}" style="width: 298px;">


                        <!-- 音视频缩略图 -->

                      </a>
                    </div>
                    <div class="entry-wrapper">
                      <div class="entry-cat-dot"><a
                          :href="'/gameStore?isGameTypeActive='+game.gameTypeId">{{ game.gameType }}</a></div>

                      <h2 class="entry-title">
                        <a target="_blank" :href="'/game/'+game.id" :title="game.title">{{ game.title }}</a>
                      </h2>

                      <div class="entry-desc">{{ game.gameAbstract }}</div>


                      <div class="entry-meta">

                        <span class="meta-date"><i class="far fa-clock me-1"></i><time
                            class="pub-date">{{ game.publishDate }}</time></span>

                        <span class="meta-likes d-none d-md-inline-block"><i class="fa fa-heart me-1"></i>0</span>

                        <span class="meta-fav d-none d-md-inline-block"><i class="fa fa-star me-1"></i>12</span>

                        <span class="meta-views"><i class="fa fa-eye me-1"></i>6.0K</span>

                        <span class="meta-price"><i class="fa "></i></span>

                      </div>
                    </div>
                  </article>
                </div>
                <!-- Post Article List -->


              </aside>

              <!-- Tags news -->
              <aside class="wrapper__list__article">
                <h4 class="border_section">热门标签</h4>
                <refer-tag-game></refer-tag-game>
              </aside>
              <!-- End Tags news -->

              <!-- Category news -->
              <aside class="wrapper__list__article">
                <h4 class="border_section">分类</h4>
                <!-- Widget Category -->
                <div class="widget widget__category">
                  <ul class="list-unstyled ">
                    <li v-for="(item,index) in this.gameDetail.categoryList" :key="index">
                      <a :href="'/category/'+item.id">
                        {{ item.categoryName }}
                        <span class="badge">{{ item.size }}</span>
                      </a>
                    </li>


                  </ul>
                </div>
              </aside>
              <!-- End Category news -->

              <!-- Banner news -->
              <!--              <aside class="wrapper__list__article">-->
              <!--                <h4 class="border_section">Advertise</h4>-->
              <!--                <a href="#">-->
              <!--                  <figure>-->
              <!--                    <img src="images/placeholder/600x600.jpg" alt="" class="img-fluid">-->
              <!--                  </figure>-->
              <!--                </a>-->

              <!--              </aside>-->
              <!-- End Banner news -->


            </div>
          </div>
          <div class="clearfix"></div>

        </div>

      </div>
    </section>


    <section class="wrapper__section p-0">
      <div class="wrapper__section__components">
        <home-footer></home-footer>
      </div>
    </section>


    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>
  </div>
</template>

<script>

import NavbarKit from "@/components/navbarKit.vue";
import HomeFooter from "@/components/homeFooter.vue";
import BreadCrumbs from "@/components/breadCrumbs.vue";
import http from "@/api/http";
import CardGameDetail from "@/components/cardGameDetail";
import ReferTagGame from "@/components/referTagGame";
import PopularTag from "@/components/popularTag";
import moment from "moment/moment";
import portal from "@/api/portal";
import login from "@/api/login";

export default {
  methods:{
    handleDownload(item) {
      if (!this.userInfo.login) {
        // 显示提示登录
        this.$confirm('获取当前资源需要登录,是否跳转登录页？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = '/login'
        }).catch(() => {

        });
      }else if (this.userInfo.memberType === '永久会员'||this.userInfo.memberType === '临时会员') {
        window.location.href = '/downLoad/gameDownLoad?gameId=' + item.gameId + '&downLoadType=' + item.downLoadType+'&chargeType=CT01';
      } else if (this.userInfo.memberType === '普通用户') {
        // 显示提示充值会员或积分下载
        if (this.userInfo.integration>=5){
          this.$confirm('您当前为普通用户,<a href="/profileMember">充值会员</a>将不耗费积分,是否仍选择消耗5积分下载该资源?', '提示', {
            dangerouslyUseHTMLString:true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            window.location.href = '/downLoad/gameDownLoad?gameId=' + item.gameId + '&downLoadType=' + item.downLoadType+'&chargeType=CT02';
          }).catch(() => {

          });
        }
        this.$confirm('您当前为普通用户，推荐您<a href="/profileMember">充值会员</a>，全站8000+游戏无限下载', '提示', {
          dangerouslyUseHTMLString:true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = '/profileMember'
        }).catch(() => {

        });
      }
    },
  },
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  mounted() {
    this.$store.commit('setBreadCrumb', this.breadCrumb);
    http.get2(login.getUserInfo, {}).catch((data) => {
      this.userInfo = data.data;
      console.log("userInfo",data.data)
    }).then(() => {

    })
    try {
      http.get2(portal.getGameDetail + "?id=" + this.$parent.$route.params.id, {}).catch((data) => {
        console.log(data)
        this.gameDetail = data.data
        console.log('raw2', data.data.articleTextRaw)
      }).then(() => {
        // if(this.gameDetail.mayLikeArticleList[0].id!==''){
        //   this.slickShow()
        // }
      })
    } catch (err) {
      this.$message.error(err);
    }
  },

  data() {
    return {
      userInfo: {
        id:0,
        memberType:0,
        integration:0,
        login:false,
      },
      gameDetail: {
        gameTitle: '标题',
        gameAuthor: '作者',
        category: '分类',
        categoryId: '',
        gameCoverPicPath: '',
        deliverDate: '2024-1-1',
        gameVersion: '',
        gameType: '',
        gameSize: '',
        gamePortal: '',
        publishDate: '2024-1-1',
        gameView: '999',
        gameTextRaw: '',
        referGameList: [{
          id: '',
          title: '',
          articleAuthor: '',
          category: '',
          coverPicPath: '',
          publishDate: '2024-1-1',
        }, {
          id: '',
          title: '',
          articleAuthor: '',
          category: '',
          coverPicPath: '',
          publishDate: '2024-1-1',
        }, {
          id: '',
          title: '',
          articleAuthor: '',
          category: '',
          coverPicPath: '',
          publishDate: '2024-1-1',
        }, {
          id: '',
          title: '',
          articleAuthor: '',
          category: '',
          coverPicPath: '',
          publishDate: '2024-1-1',
        }, {
          id: '',
          title: '',
          articleAuthor: '',
          category: '',
          coverPicPath: '',
          publishDate: '2024-1-1',
        }],
        popularGameList: [{
          id: '',
          gameTitle: '',
          gameAuthor: '',
          category: '',
          gameCoverPicPath: '',
          deliverDate: '2024-1-1',
          publishDate: '2024-1-1',
        }, {
          id: '',
          gameTitle: '',
          gameAuthor: '',
          category: '',
          gameCoverPicPath: '',
          deliverDate: '2024-1-1',
          publishDate: '2024-1-1',
        }, {
          id: '',
          gameTitle: '',
          gameAuthor: '',
          category: '',
          gameCoverPicPath: '',
          deliverDate: '2024-1-1',
          publishDate: '2024-1-1',
        }, {
          id: '',
          gameTitle: '',
          gameAuthor: '',
          category: '',
          gameCoverPicPath: '',
          deliverDate: '2024-1-1',
          publishDate: '2024-1-1',
        }],
        referTagList: [{
          id: '',
          tagName: ''
        }],
        popularTagList: [{
          id: '',
          tagName: ''
        }],
        categoryList: [{
          id: '1',
          categoryName: 'category',
          size: '1'
        }],
        downLoadList: [
          {
            gameId: '',
            downLoadType: '',
            sharedPerson: '',
          }
        ]
      },
      gameId: this.$route.params.id,
      breadCrumb: [{
        name: '首页',
        to: '/',
      },
        {
          name: '游戏库',
          to: '/',
        },
        {
          name: '当前页',
          to: '',
        },
      ],
    }
  },

  name: "GameDetail",

  components: {
    PopularTag,
    ReferTagGame,
    CardGameDetail,
    BreadCrumbs,
    HomeFooter,
    NavbarKit
  }

}
</script>

<style scoped>
.spanStyle {
  display: inline-block;
  white-space: normal;
  width: 300px;
  overflow: hidden; /*超出的文本隐藏*/
  text-overflow: ellipsis; /* 溢出用省略号*/

}

.title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;
}

.article-down .title {
  font-size: 18px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 8px
}

.article-down ul li {
  float: left;
  width: 110px;
  height: 40px;
  line-height: 40px;
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin: 0 5px 5px 0;
  text-align: center;
  transition: all .3s;
  overflow: hidden
}

.article-down ul li a {
  display: block;
  transition: all .3s
}

.article-down ul li a i {
  margin-right: 3px
}

.article-down ul li:hover {
  background: #007bf5 !important;
  border: 1px solid #007bf5 !important
}

.article-down ul li a:hover, .article-down ul li:hover a {
  color: #fff
}

a {
  color: #333;
  text-decoration: none;
}

</style>