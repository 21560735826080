<template>
  <div>
    <!-- Article Detail -->
    <div class="wrap__article-detail">
      <div class="wrap__article-detail-title">
        <h1>
          {{this.gameDetail.gameTitle}}
        </h1>
<!--        <h3>-->
<!--          <span>游戏类别:{{this.gameDetail.gameType}} &nbsp;</span>   <span>发行日期:{{ this.gameDetail.deliverDate| moment }}&nbsp;</span>  <span>版本:{{this.gameDetail.gameVersion}}&nbsp;</span> <span>大小:{{this.gameDetail.gameSize}}&nbsp;</span>  <span>登录平台:{{this.gameDetail.gamePortal}}</span>-->
<!--        </h3>-->
      </div>
      <hr>
      <div class="wrap__article-detail-info">
        <ul class="list-inline">



          <li class="list-inline-item">
            <figure class="image-profile">
              <img src="../assets/pic/gscmini.jpg">
            </figure>
          </li>
<!--          <li class="list-inline-item">-->

<!--&lt;!&ndash;                <span>&ndash;&gt;-->
<!--&lt;!&ndash;                    by&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="#">&ndash;&gt;-->
<!--&lt;!&ndash;              john doe,&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--          </li>-->
          <li

              class="list-inline-item">
                <span class="text-dark text-capitalize ml-1">
                   &nbsp; &nbsp; 发布时间: {{ this.gameDetail.publishDate| moment }}
                </span>
          </li>

          <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                    &nbsp; &nbsp; 分类:
                </span>
            <a :href="'/category/'+this.gameDetail.categoryId">
              {{this.gameDetail.category}}
            </a>


          </li>
          <li class="list-inline-item" style="text-align: right">

            &nbsp; <span class="meta-fav d-none d-md-inline-block"><i class="fas fa-star me-1"></i>{{gameDetail.gameCollectTime}}</span>&nbsp;

            <span class="meta-likes d-none d-md-inline-block"><i class="fas fa-heart me-1"></i>{{gameDetail.likeNum}}</span>&nbsp;
            <span class="meta-likes d-none d-md-inline-block"><i class="fas fa-coins me-1"></i>5</span>&nbsp;
            <span class="meta-likes d-none d-md-inline-block"><i class="fas fa-download me-1"></i>{{gameDetail.gameDownloadTime}}</span>&nbsp;
            <span class="meta-views" style="text-align: right"><i class="fas fa-eye me-1"></i>{{gameDetail.gameView}}</span>&nbsp;

          </li>
        </ul>
      </div>

      <div class="wrap__article-detail-image mt-4">
        <figure>
          <img :src="this.gameDetail.articleCoverPicPath" alt="" class="img-fluid">
        </figure>
      </div>

<!--        <h3> 游戏介绍 </h3>-->
        <!-- 文章内容-->
        <div v-html="this.gameDetail.gameTextRaw"/>
<!--        </div>-->

      <div class="entry-copyright">
        <i class="fas fa-info-circle me-1"></i>本站所有資源來源均來自網絡分享，所有資源均免費提供給會員進行學習研究用，請於下載24小時內刪除資源，所有資源請勿用於商業行為！</div>
    </div>

    <div class="entry-social">

      <div class="row mt-2 mt-lg-3">

        <div class="col">
          <a class="share-author" href="">
            <div class="avatar me-1"><img class="avatar-img rounded-circle border border-white border-3 shadow" src="../assets/pic/gscmini.jpg" alt="">
            </div>网友分享            </a>
        </div>

        <div class="col-auto">

          <a class="btn btn-sm btn-info-soft post-share-btn" href="javascript:void(0);"><i class="fas fa-share-alt me-1"></i>分享</a>

          <a class="btn btn-sm btn-success-soft post-fav-btn" href="javascript:void(0);" data-is="0"><i class="far fa-star me-1"></i>收藏</a>

          <a class="btn btn-sm btn-danger-soft post-like-btn" href="javascript:void(0);" data-text="已点赞"><i class="far fa-heart me-1"></i>点赞(<span class="count">4</span>)</a>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "cardGameDetail",
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  computed:{
    getArticleDetail(){
      return this.$parent.gameDetail;
    }
  },
  watch:{
    getArticleDetail(newValue,oldValue){
      this.gameDetail = newValue;
    }
  },
  data(){
    return{
      gameDetail:{
        gameTitle:'',
        gameAuthor:'',
        category:'',
        categoryId:'',
        gameCoverPicPath:'',
        publishDate:'2024-1-1',
        deliverDate:'2024-1-1',
        gameVersion:'',
        gameType: '',
        gameSize:'',
        gamePortal:'',
        gameView:'',
        gameTextRaw:''
      }
    }
  }
}
</script>

<style scoped>
.btn-info-soft {
  color: #4f9ef8;
  background-color: rgba(79, 158, 248, .1)
}
.btn-success-soft {
  color: #0cbc87;
  background-color: rgba(12, 188, 135, .1)
}
.btn-danger-soft {
  color: #d6293e;
  background-color: rgba(214, 41, 62, .1)
}

.btn-success-soft:active, .btn-success-soft:hover {
  color: #fff;
  background-color: #0cbc87;
  border-color: #0cbc87
}

.btn-success-soft:active, .btn-success-soft:hover {
  color: #fff;
  background-color: #0cbc87;
  border-color: #0cbc87
}
.btn-info-soft:active, .btn-info-soft:hover {
  color: #fff;
  background-color: #4f9ef8;
  border-color: #4f9ef8
}
.btn-danger-soft:active, .btn-danger-soft:hover {
  color: #fff;
  background-color: #d6293e;
  border-color: #d6293e
}
</style>