<template>

  <div>
    <div class="navigation-wrap navigation-shadow bg-white">
      <nav class="navbar navbar-hover navbar-expand-lg navbar-soft">
        <div class="container">
          <div class="offcanvas-header">
            <div data-toggle="modal" data-target="#modal_aside_right" class="btn-md">
              <span class="navbar-toggler-icon"></span>
            </div>
          </div>
          <figure class="mb-0 mx-auto">
            <a class="atag" @click="refreshPage()">
              <img src="../assets/pic/cangshu.png" style="height: 80px; ">
              <img src="../assets/pic/youxicangshu.png" style="height: 55px; margin-top: 10px;">

<!--              <img src="../assets/image/placeholder/logo.jpg" alt="" class="img-fluid logo">-->
            </a>
          </figure>

          <div class="collapse navbar-collapse justify-content-between" id="main_nav99">
            <ul class="navbar-nav ml-auto ">
              <li class="nav-item" style="font-size:large"><a href="/" class="nav-link atag" >首页</a></li>
<!--              <li class="nav-item" style="font-size:large"><a class="nav-link"  href="/"> 游戏新闻 </a></li>-->
              <li class="nav-item" style="font-size:large"><a class="nav-link"  href="#mianfei"> 免费下载 </a></li>
              <li class="nav-item" style="font-size:large"><a class="nav-link"  href="/gameStore"> 游戏库 </a></li>
              <li class="nav-item" style="font-size:large"><a class="nav-link"  href="/category/4"> 教程工具 </a></li>
              <li class="nav-item" key="close" v-if="showSearch" style="font-size:large"><a class="nav-link"  href="javascript:void (0)" @click="toggleSearch()"> <i style="width: 13.5px" class="fa fa-close"></i></a></li>
              <li class="nav-item" key="search" v-if="!showSearch" style="font-size:large"><a class="nav-link"  href="javascript:void (0)" @click="toggleSearch()"> <i style="width: 13.5px" class="fa fa-search"></i> </a></li>
<!--              <li class="nav-item" style="font-size:large"><a class="nav-link"  href="/"> 高清图集 </a></li>-->
<!--              <li class="nav-item"><a class="nav-link" href="#"> Category </a></li>-->
<!--              <li class="nav-item"><a class="nav-link" href="/contact.html"> contact </a></li>-->
            </ul>


            <!-- Search bar.// -->
            <div class="navbar-nav">

              <!-- Search content bar -->
              <div v-if="this.showSearch" class="top-search navigation-shadow" style="display: block;margin-top: 0">
                <div class="container" >
                  <div class="input-group">
                    <form @submit.prevent="search">
                      <div class="row no-gutters mt-3">
                        <div class="col-auto" >
                          <el-select class="my-el-select" v-model="searchSelect" placeholder="请选择搜索分类" >
                            <el-option style="height: 45px"
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="col" >
                          <input style="background-color: transparent;border-color: #DCDFE6" class="form-control border-secondary-normal border-right-0 rounded-0"
                                 type="search" v-model="searchWord" placeholder="输入你想搜索的内容" id="example-search-input4" >
                        </div>
                        <div class="col-auto">
                          <button type="submit" class="btn btn-outline-secondary border-left-0 rounded-0 rounded-right" style="height: 45px;width: 70px; border-color: #DCDFE6">
                            <i class="fa fa-search" style="margin: 0 auto"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- End Search content bar -->
            </div>
          </div>
          </div>




        <!-- Search content bar.// -->

        <div class="navbar" v-if="userInfo.login">
          <!-- logo -->
          <div class="actions">

            <div class="action-hover-menu d-inline-block">
              <a class="avatar-warp" href="/profileMember" rel="nofollow noopener noreferrer">
                <img class="avatar-img rounded-circle" src="//hanbaoyx.com/wp-content/uploads/1234/01/avatar-62af085b18266b98.png" width="30" alt="avatar">
                <span class="ms-2 d-none d-md-block">{{userInfo.name}}</span>
                <span class="badge  text-warning bg-opacity-10 d-none d-md-block ms-2"><i class="far fa-gem" v-if="userInfo.memberType==='永久会员'||userInfo.memberType==='临时会员'"></i>{{userInfo.memberType}}</span>
              </a>

              <div class="hover-warp">
                <div class="hover-info">
                  <div class="d-flex align-items-center">
                    <div class="me-2">
                      <img class="avatar rounded-circle border border-white border-3 shadow" src="//hanbaoyx.com/wp-content/uploads/1234/01/avatar-62af085b18266b98.png" alt="avatar">
                    </div>
                    <div class="ms-2 lh-1">
                      <span  class="badge text-warning bg-opacity-10 ">        <i class="far fa-gem"></i>{{userInfo.memberType}}</span>				<b class="d-block mt-2">{{userInfo.name}}</b>
                    </div>
                  </div>
                  <div class="balance-qiandao">
                    <a class="user-qiandao-action text-danger" href="javascript:"><i class="fa fa-check-square"></i>签到领取积分</a>
                  </div>
                </div>

                <div class="hover-balance small p-3 pb-1">

                  <div class="row g-2">

                    <div class="col-6">
                      <div class="text-center bg-info text-white bg-opacity-75 rounded-2 p-3">
                        <div class="mb-2">积分余额</div>
                        <div class="mb-2"><i class="fas fa-coins me-1"></i>{{userInfo.integration}}</div>						<div>
                        <a class="btn btn-sm btn-white text-primary w-100 rounded-pill" href="/profileBalance" rel="nofollow noopener noreferrer">充值</a>
                      </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <a class="btn btn-sm d-block bg-success text-white bg-opacity-75 rounded-2 p-2 py-3 mb-2" href="/profileMember"><i class="far fa-gem me-1"></i>本站一年会员</a><a class="btn btn-sm d-block bg-warning text-white bg-opacity-75 rounded-2 p-2 py-3 mb-2" href="/profileMember"><i class="far fa-gem me-1"></i>本站永久会员</a>				</div>

                  </div>

                </div>

                <div class="hover-item mt-0 p-3 pt-0">
                  <div class="hover-link">
                    <a href="/profileBasicInfo"><i class="far fa-user"></i>基本信息</a><a href="/profileBalance"><i class="fas fa-coins"></i>我的余额</a><a href="/profileMember"><i class="fas fa-gem"></i>我的会员</a><a href="/profileLikes"><i class="fas fa-star"></i>我的收藏</a><a href="/profileIssue"><i class="fab fa-shopify"></i>我的工单</a>	    	</div>
                </div>

                <div class="abstop-item">
                  <a href="/logout"><i class="fas fa-sign-out-alt me-1"></i>退出登录</a><a href="/logout"><i class=" me-1"></i></a>		</div>
              </div>
            </div>

            <div class="burger d-flex d-lg-none"><i class="fas fa-bars"></i></div>
          </div>


        </div>
    <!--        头像栏-->

        <div v-if="!userInfo.login">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
            <i class="far fa-user"></i>
              欢迎登录
            </span>
            <el-dropdown-menu>
              <el-dropdown-item command="login">
                  登录
              </el-dropdown-item>
              <el-dropdown-item command="register">注册</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


        </div>


      </nav>
    </div>
    <!-- End Navbar menu  -->

    <!-- Navbar sidebar menu  -->


  </div>

</template>

<script>
import Fingerprint2 from 'fingerprintjs2';
import router from "@/router";
import http from "@/api/http";
import portal from "@/api/portal";
import Vue from 'vue';
import login from "@/api/login";

export default {
  name: "navbarKit",
  created() {
    this.createFingerprint()
  },
  mounted() {
    console.log("当前路径:", window.location.pathname);
    if (window.location.pathname!=='/'){
      console.log("setNowPath",window.location.pathname)
      this.$store.commit('setNowPath',window.location.pathname);
    }
    console.log("this.$store.state.nowPath",this.$store.state.pubParam.nowPath)

    try {
      http.get2(portal.getCategoryOptions, {}).catch((data) => {
        this.options = data.data;
      }).then(() => {

      })
    }catch (err) {
      this.$message.error(err);
    }

    try {
      http.get2(login.getUserInfo, {}).catch((data) => {
        this.userInfo = data.data;
        console.log("userInfo",data.data)
      }).then(() => {

      })
    }catch (err) {
      this.$message.error(err);
    }
  },
  data(){
    return{
      showUserInfo: false,
      userInfo: {
        login: false
      },

      browserPrint:'',
      isSearch:false,
      searchWord: '', // 搜索框输入的文本
      showSearch: false, // 控制搜索框的显示和隐藏
      searchSelect: '',
      options: [{
        value: 'CT01',
        label: '文章',
      },{
        value: 'CT02',
        label: '图片',
      },{
        value: 'CT03',
        label: '游戏',
      }],

    }

  },



  methods: {

    // refreshPage() {
    //   console.log("beforeRouteEnter");
    //     // this.$store.commit('setNeedMainPageRefresh', 1);
    //     console.log("down")
    //   router.push("/")
    // },
    toggleSearch() {
      this.showSearch = !this.showSearch; // 切换搜索框的显示状态
      console.log(this.showSearch);
    },
    search() {
      if (this.searchSelect ===''){
        Vue.prototype.$message.warning('请选择搜索分类')
        return;
      }
      if (this.searchText ===''){
        Vue.prototype.$message.warning('请输入搜索内容')
        return;
      }
      // 执行搜索操作
      console.log('searchSelect',this.searchSelect);
      console.log('Search text:', this.searchWord);
      // 在这里添加您执行搜索的逻辑，比如发送请求等
      this.$store.commit('setSearchDetail', [this.searchSelect,this.searchWord]);
      console.log('AAAA',this.$store.state.searchStore.searchDetail.searchText);
      console.log('BBBB',this.$store.state.searchStore.searchDetail.searchType);
      // this.$router.push("/search")
      this.$router.push({
        path: '/search',
        query: {searchSelect: this.searchSelect,searchText:this.searchText,time:new Date().getTime()},  // 传递参数，放在url?后面的
      }).then(this.toggleSearch)

    },
    handleCommand(command){
      if (command==='login'){
        router.push({path: '/login'})
      }else if (command==='register'){
        router.push({path: '/register'})
      }
    },

    // 创建浏览器指纹
    createFingerprint() {
      // 浏览器指纹
      const fingerPrint = Fingerprint2.get((components) => { // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
        const values = components.map(component => component.value); // 配置的值的数组
        const murmur = Fingerprint2.x64hash128(values.join(''), 31); // 生成浏览器指纹
        // console.log(components);
        // console.log(values);
        // console.log(murmur);
        this.browserPrint = murmur;
        this.$cookies.set('BROWSERPRINT',murmur,60 * 60 * 24)
        localStorage.setItem('browserId', murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
        this.$store.commit('setBrowserPrint',murmur)
        // console.log(murmur)
      })
    },

  }
}
import '../assets/css/main.min.css';
</script>

<style scoped>

/deep/ .el-input__inner{
  height: 45px;
  border-radius: 0;
}
.fa-search:hover{
  color: red;
}
.fa-close:hover{
  color: red;
}
.fa-i:hover{
  color: red;
}
.atag:hover {
  cursor: pointer;
}
</style>

<style lang="stylus">
/* 自定义宽度 */
/* 隐藏鼠标点击这些元素时出现的光标 */
label,h1,h2,h3,h4,h5,h6,span, p ,ul , li{
  caret-color: transparent;
}
.col-auto{
  caret-color: transparent;
}
@import '../assets/css/main.min.css';



</style>