<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <aside class="wrapper__list__article ">
          <h4 class="border_section">标签：{{this.tagDetail.tagName}}</h4>

          <!-- Post Article List -->
          <div class="card__post card__post-list card__post__transition mt-30" v-for="(item,index) in this.tagDetail.tagPage.detailList" :key="index">
            <div class="row ">
              <div class="col-md-5">
                <div class="card__post__transition">
                  <a :href="tagDetail.prefix+item.id">
                    <img :src="item.coverPicPath" class="img-fluid w-100" alt="">
                  </a>
                </div>
              </div>
              <div class="col-md-7 my-auto pl-0">
                <div class="card__post__body ">
                  <div class="card__post__content  ">
                    <div class="card__post__category ">
                      {{item.categoryName}}
                    </div>
                    <div class="card__post__author-info mb-2">
                      <ul class="list-inline">
                        <li class="list-inline-item">
                                <span class="text-primary">
                                    by {{item.author}}
                                </span>
                        </li>
                        <li class="list-inline-item">
                                <span class="text-dark text-capitalize">
                                    {{ item.publish_date| moment }}
                                </span>
                        </li>

                      </ul>
                    </div>
                    <div class="card__post__title">
                      <h5>
                        <a :href="tagDetail.prefix+item.id">
                          {{item.title}}
                        </a>
                      </h5>
                      <p class="d-none d-lg-block d-xl-block mb-0">
                        {{item.abstract}}
                      </p>

                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- Post Article List -->


        </aside>
      </div>
      <div class="col-md-4">
        <div class="sidebar-sticky">
          <aside class="wrapper__list__article ">
            <h4 class="border_section">热门文章</h4>
            <div class="wrapper__list__article-small">
              <div class="mb-3">
                <!-- Post Article -->
                <div class="card__post card__post-list">
                  <div class="image-sm">
                    <a :href="'/article/'+this.tagDetail.popularArticleList[0].id">
                      <img :src="tagDetail.popularArticleList[0].articleCoverPicPath" class="img-fluid" alt="">
                    </a>
                  </div>


                  <div class="card__post__body ">
                    <div class="card__post__content">

                      <div class="card__post__author-info mb-2">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                            {{ this.tagDetail.popularArticleList[0].publishDate| moment }}
                        </span>
                          </li>

                        </ul>
                      </div>
                      <div class="card__post__title">
                        <h6>
                          <a :href="'/article/'+this.tagDetail.popularArticleList[0].id">
                            {{tagDetail.popularArticleList[0].articleTitle}}
                          </a>
                        </h6>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
              <div class="mb-3">
                <!-- Post Article -->
                <div class="card__post card__post-list">
                  <div class="image-sm">
                    <a :href="'/article/'+tagDetail.popularArticleList[1].id">
                      <img :src="tagDetail.popularArticleList[1].articleCoverPicPath" class="img-fluid" alt="">
                    </a>
                  </div>


                  <div class="card__post__body ">
                    <div class="card__post__content">

                      <div class="card__post__author-info mb-2">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                            {{ this.tagDetail.popularArticleList[1].publishDate| moment }}
                        </span>
                          </li>

                        </ul>
                      </div>
                      <div class="card__post__title">
                        <h6>
                          <a :href="'/article/'+tagDetail.popularArticleList[1].id">
                            {{tagDetail.popularArticleList[1].articleTitle}}
                          </a>
                        </h6>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
              <div class="mb-3">
                <!-- Post Article -->
                <div class="card__post card__post-list">
                  <div class="image-sm">
                    <a :href="'/article/'+tagDetail.popularArticleList[2].id">
                      <img :src="tagDetail.popularArticleList[2].articleCoverPicPath" class="img-fluid" alt="">
                    </a>
                  </div>


                  <div class="card__post__body ">
                    <div class="card__post__content">

                      <div class="card__post__author-info mb-2">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                        <span class="text-dark text-capitalize">
                            {{ this.tagDetail.popularArticleList[2].publishDate| moment }}
                        </span>
                          </li>

                        </ul>
                      </div>
                      <div class="card__post__title">
                        <h6>
                          <a :href="'/article/'+tagDetail.popularArticleList[2].id">
                            {{tagDetail.popularArticleList[2].articleTitle}}
                          </a>
                        </h6>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

              <!-- Post Article -->
              <div class="article__entry">
                <div class="article__image">
                  <a :href="'/article/'+tagDetail.popularArticleList[3].id">
                    <img :src="tagDetail.popularArticleList[3].articleCoverPicPath" alt="" class="img-fluid">
                  </a>
                </div>
                <div class="article__content">
                  <div class="article__category">
                    {{tagDetail.popularArticleList[3].category}}
                  </div>
                  <ul class="list-inline">

                    <li class="list-inline-item">
                <span class="text-dark text-capitalize">
                    {{ this.tagDetail.popularArticleList[3].publishDate| moment }}
                </span>
                    </li>

                  </ul>
                  <h5>
                    <a :href="'/article/'+tagDetail.popularArticleList[3].id">
                      {{tagDetail.popularArticleList[3].articleTitle}}
                    </a>
                  </h5>
                  <a :href="'/article/'+tagDetail.popularArticleList[3].id" class="btn btn-outline-primary mb-4 text-capitalize"> 查看详情</a>
                </div>
              </div>
            </div>
          </aside>

          <aside class="wrapper__list__article">
            <h4 class="border_section">热门标签</h4>
            <div class="blog-tags p-0">
              <ul class="list-inline">

                <li class="list-inline-item" v-for="(item,index) in this.tagDetail.tagList" :key="index">
                  <a :href="'/articleTag/'+item.id">
                    {{item.tagName}}
                  </a>
                </li>



              </ul>
            </div>
          </aside>


        </div>
      </div>

    </div>

    <!-- Pagination -->
    <!-- Pagination -->
    <div class="pagination-area" v-if="tagDetail.tagPage.totalSize!==0">
      <el-pagination class="pagination wow fadeIn animated"
                     layout="prev, pager, next"
                     @current-change="handleCurrentChange"
                     @prev-click="handlePrevClick"
                     @next-click="handleNextClick"
                     :total="tagDetail.tagPage.totalSize" :current-page="page"   style="margin-right: 50%">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import http from "@/api/http";
import portal from "@/api/portal";

export default {
  name: "articleTagCard",
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  created() {

    try {
      http.get2(portal.getArticleTagDetail+"?id="+this.$parent.$route.params.id, {}).catch((data) => {
        console.log(data)
        this.tagDetail = data.data;
      }).then(() => {
        this.page = this.tagDetail.tagPage.page
      })
    }catch (err) {
      this.$message.error(err);
    }

  },
  methods:{

    // 当前页码改变事件处理函数
    handleCurrentChange(newPage) {
      console.log('当前页: ' + newPage);
      // 可以在这里更新数据，例如重新请求数据
      try {
        http.get2(portal.getArticleTagDetail+"?id="+this.$parent.$route.params.id+"&current="+newPage, {}).catch((data) => {
          console.log(data)
          this.tagDetail = data.data;
        }).then(() => {
          this.page = this.tagDetail.tagPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    // 上一页按钮点击事件处理函数
    handlePrevClick() {
      console.log('上一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.tagDetail.tagPage.page -1
      try {
        http.get2(portal.getArticleTagDetail+"?id="+this.$parent.$route.params.id+"&current="+newPage, {}).catch((data) => {
          console.log(data)
          this.tagDetail = data.data;
        }).then(() => {
          this.page = this.tagDetail.tagPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    // 下一页按钮点击事件处理函数
    handleNextClick() {
      console.log('下一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.tagDetail.tagPage.page +1
      try {
        http.get2(portal.getArticleTagDetail+"?id="+this.$parent.$route.params.id+"&current="+newPage, {}).catch((data) => {
          console.log(data)
          this.tagDetail = data.data;
        }).then(() => {
          this.page = this.tagDetail.tagPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }

    }

  },
  data(){
    return {
      prefix:'',
      page:1,
      tagDetail:{
        prefix:'',
        id:'',
        tagName:'',
        tagPage:{
          totalSize:0,
          pageSize:'',
          page:1,
          detailList:[{
            id:'',
            coverPicPath:'',
            categoryName:'',
            author:'',
            publish_date:'',
            title:'',
            abstract:''
          }]

        },
        tagList: [{
          id:'',
          tagName: '',
        }],
        popularArticleList:[{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        },{
          id:'',
          articleTitle:'',
          articleAuthor:'',
          category:'',
          articleCoverPicPath:'',
          publishDate:'2024-1-1',
        }],
      }
    }
  }
}
</script>

<style scoped>

</style>