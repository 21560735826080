<template>
  <div class="wrapper__section__components">
    <footer>
      <div class="wrapper__footer bg__footer-dark pb-0">
        <div class="container">

        </div>
        <div class="mt-4">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <figure class="image-logo">
                  <img src="../assets/pic/gscmini.jpg" alt="" class="logo-footer">
                </figure>
              </div>
              <div class="col-md-8 my-auto ">
                <div class="social__media">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper__footer-bottom bg__footer-dark">
        <div class="container ">
          <div class="row">
            <div class="col-md-12">
              <div class="border-top-1 bg__footer-bottom-section">
                <ul class="list-inline link-column">
                  <li class="list-inline-item">
                    <a href="/contact-us.html">
                      contact us
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#"> terms of use</a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      adchoice
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="/about-us.html">
                      about us
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      newsletters
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      sitemap
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      magrenvi store
                    </a>
                  </li>
                </ul>
                <ul class="list-inline">
                  <li class="list-inline-item">
<span>
Copyright © 2019 News and Magazine template based on Bootstrap 4 Theme by <a href="#">retenvi</a>
</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "homeFooter"
}
</script>

<style lang="less">
@import '../style/styles.css';
</style>