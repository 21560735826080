<template>
  <div class="d-none d-lg-block col-md-12 col-lg-3 h-100" data-sticky=""
       style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">


    <div class="theiaStickySidebar"
         style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 588px;">
      <div class="bg-white rounded overflow-hidden">
        <div class="card text-center" style="text-align: center">
          <div class="avatar avatar-xl mb-2" style="margin: 5px auto">
            <img class="avatar-img rounded-circle border border-white border-3 shadow" src="" alt="">
          </div>
          <h5 class="d-flex justify-content-center align-items-center mb-1">
            {{this.profileLeftInfo.name}} </h5>
          <!--              <p class="mb-1">kurt</p>-->
          <p class="mb-1"><span title="9999-09-09到期" class="badge bg-warning text-warning bg-opacity-10 mb-0"><i
              class="far fa-gem me-1"></i>{{this.profileLeftInfo.memberType}}</span></p>

          <sub>到期时间：{{this.profileLeftInfo.memberDueDateStr}}</sub></div>

        <div class="card small text-center bg-success bg-opacity-10">
          <p class="mb-2-hb text-success">每天可下载数({{this.profileLeftInfo.downLoadLimit}})</p>
          <span class="badgehb bg-primary-hb bg-opacity-10 text-primary-hb mb-1-hb">今日已用({{this.profileLeftInfo.downLoadToday}})</span>
          <span class="badgehb bg-primary-hb bg-opacity-10 text-primary-hb mb-1-hb">今日剩余({{this.profileLeftInfo.downLoadTodayLeft}})</span>
        </div>
      </div>
      <div class="bg-white rounded overflow-hidden mt-4 mb-4">
        <ul class="uc-menu-warp">
          <li class="menu-item" :class="[this.activeItem == 1? 'current-menu-item':'']" ><a href="/profileBasicInfo"><i
              class="far fa-user me-1"></i>基本信息</a></li>
          <li class="menu-item" :class="[this.activeItem == 2? 'current-menu-item':'']" ><a href="/profileBalance"><i class="fas fa-coins me-1"></i>我的余额</a>
          </li>
          <li class="menu-item" :class="[this.activeItem == 3? 'current-menu-item':'']" ><a href="/profileMember"><i class="fas fa-gem me-1"></i>我的会员</a></li>

          <li class="menu-item" :class="[this.activeItem == 4? 'current-menu-item':'']" ><a href="/profileDownLoadDetail"><i class="fas fa-cloud-download-alt me-1"></i>下载记录</a>
          </li>
          <li class="menu-item" :class="[this.activeItem == 5? 'current-menu-item':'']" ><a href="/profileLikes"><i class="fas fa-star me-1"></i>我的收藏</a>
          </li>
          <li class="menu-item" :class="[this.activeItem == 6? 'current-menu-item':'']" ><a href="/profileIssue"><i class="fas fa-question-circle me-1"></i>我的工单</a>
          </li>
          <li class="menu-item" :class="[this.activeItem == 7? 'current-menu-item':'']" ><a
              href="/logout"><i
              class="fas fa-sign-out-alt me-1"></i>退出登录</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/api/http";
import login from "@/api/login";
import profile from "@/api/profile";

export default {
  name: "profileLeft",
  computed: {
    isMenuItemChanged () {
      return this.$store.state.menuItem.activeItem;//需要监听的数据
    }
  },
  watch: {
    isMenuItemChanged(newVal, oldVal) {
      console.log(newVal,oldVal);
      this.activeItem = newVal;
    }
  },
  mounted() {
    console.log("aaa",this.$store.state.menuItem.activeItem)

    try {
      http.get2(profile.getMenuUserInfo, {}).catch((data) => {
        this.profileLeftInfo = data.data;
        console.log("profileLeftInfo", data.data)
      }).then(() => {

      })
    } catch (err) {
      this.$message.error(err);
    }

  },

  data() {
    return {
      activeItem : this.$store.state.menuItem.activeItem,
      profileLeftInfo: {},
    }
  }


}
</script>

<style scoped>

</style>