<template>
  <div class="blog-tags p-0">
    <ul class="list-inline">

      <li class="list-inline-item" v-for="(item,index) in this.popularTagList" :key="index">
        <a :href="'/tag/'+item.id">
          {{item.tagName}}
        </a>
      </li>

    </ul>
  </div>
</template>

<script>

export default {
  name: "popularTag",

  computed:{

    getTagList(){
      return this.$parent.articleDetail.popularTagList;
    }

  },
  watch:{
    getTagList(newValue,oldValue){
      this.popularTagList = newValue;
      console.log('333333',newValue);
    }
  },
  data(){
    return{
      popularTagList:[{
        id:'',
        tagName:''
      }]
    }


  }
}
</script>

<style scoped>

</style>