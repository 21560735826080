<template>
  <body class="logged-in uc-page" style="transform: none;">

  <head-top></head-top>



  <main style="transform: none;">


    <div class="container mt-2 mt-sm-4" style="transform: none;">

      <div class="row g-2 g-md-3 g-lg-4" style="transform: none;">

        <profile-left></profile-left>

        <div class="col-md-12 col-lg-9" data-sticky-content="">


          <div class="card mb-2 mb-md-4 coin-balance-body p-4">

            <div class="balance-qiandao">
              <a class="user-qiandao-action btn btn-sm text-danger" href="javascript:;"><i class="fa fa-check-square-o me-1"></i>签到领取积分</a>
            </div>

            <div class="balance-info">
              <div class="fs-5">当前账户余额</div>
              <hr>
              <div class="fs-2"><i class="fas fa-coins me-1"></i>{{profileBalanceInfo.integration}}积分</div>
            </div>
          </div>

          <div class="card mb-2 mb-md-4">

            <div class="card-body mb-4">
              <div class="card-header mb-3">
                <h5 class="fw-bold mb-0">充值余额</h5>
              </div>

              <div class="row row-cols-2 row-cols-md-4 g-2 g-md-4">

                <div class="col"
                    v-for="item in items"
                    :key="item.num">
                  <div class="coin-pay-card text-center"
                      :class="{ active: activeItem === item.num }"
                      @click="setActive(item.num)">
                    <h5 class="mb-1 text-warning">{{ item.num }} 积分</h5>
                    <p class="m-0 text-muted">￥{{ item.num }}</p>
                  </div>
                </div>
              </div>

              <div class="py-4 text-center" style="cursor: pointer">
                <button class="btn btn-warning px-5 js-pay-action" data-id="0" data-type="2" data-info="0" data-text="充值" disabled="" ><i class="fab fa-shopify me-1"></i><span>{{rechargeNotice}}</span></button>
              </div>

            </div>

            <div class="card-body mb-4">
              <div class="card-header mb-3">
                <h5 class="fw-bold mb-0">充值说明</h5>
              </div>
              <ol class="list-group list-group-numbered">
                <li class="list-group-item list-group-item-light text-muted">充值最低额度为1积分</li>		</ol>
            </div>


          </div>


          <div class="card mb-2 mb-md-4 vip-cdk-body">
            <div class="card-header mb-3">
              <h5 class="fw-bold mb-0">积分兑换</h5>
            </div>

            <div class="card-body">
              <h5 class="text-center mb-4 text-muted">使用CDK码兑换站内币</h5>
              <form class="row" id="vip-cdk-action">
                <div class="col-12 mb-3">
                  <input type="text" class="form-control" name="cdk_code" placeholder="兑换码/CDK卡号" value="">
                </div>
                <div class="col-12 input-group mb-3">
                  <input type="text" class="form-control rounded-2" name="captcha_code" placeholder="验证码">
                  <img id="captcha-img" @click="reloadCaptcha" class="rounded-2 lazy entered loaded" role="button" data-src="https://hanbaoyx.com/wp-content/themes/ripro-v5/assets/img/captcha.png" title="点击刷新验证码" data-ll-status="loaded" :src="captchaUrl">
                </div>
                <div class="col-12 mb-3 mt-3 text-center">
                  <input type="hidden" name="action" value="zb_vip_cdk_action">
                  <button type="submit" id="vip-cdk-submit" class="btn btn-danger text-white px-4" @click="this.cdKeyVerify"><i class="fas fa-gift me-1"></i>立即兑换</button>
                  <a class="btn btn-warning" target="_blank" href="" rel="nofollow noopener noreferrer"><i class="fas fa-external-link-alt me-1"></i>购买CDK</a>

                </div>
              </form>
            </div>
          </div>

          <div class="card mb-2 mb-md-4">
            <div class="card-header mb-2">
              积分获取记录（最近10条）
            </div>

            <div class="card-body pay-vip-log" v-if="integrationDealList[0] ===null">
              <p class="p-4 text-center">暂无记录</p>	</div>
            <div class="card-body pay-vip-log" v-if="integrationDealList[0] !==null">
              <ul class="list-group mt-2">
                <div v-for="(deal, index) in integrationDealList" :key="index" class="list-group-item list-group-item-action">
                  <div class="d-flex w-100 justify-content-between" v-if="null!==deal&&undefined!==deal">
                    <h6 class="mb-1">订单类型：{{ deal.dealType }}</h6>
                    <small class="text-muted">{{ deal.dealTimeStr }}</small>
                  </div>
                  <small class="text-muted" v-if="null!==deal&&undefined!==deal">支付金额：￥{{ deal.costAmt }}.00（{{ deal.costAmt }}积分）</small>
                  <small class="text-muted" v-if="null!==deal&&undefined!==deal">支付方式：卡密支付</small>
                </div>
              </ul>
            </div>
          </div>


               </div>

      </div>

    </div>

  </main>
  <home-footer></home-footer>

  </body>
</template>

<script>
import HeadTop from "@/components/headTop.vue";
import HomeFooter from "@/components/homeFooter.vue";
import ProfileLeft from "@/components/profileLeft.vue";
import http from "@/api/http";
import profile from "@/api/profile";
import Vue from "vue";

export default {
  name: "profileBalance",
  components: {ProfileLeft, HomeFooter, HeadTop},
  data() {
    return {
      items: [
        { num: 5 },
        { num: 9 },
        { num: 18 },
        { num: 38 }
      ],
      captchaUrl:'/web/portal/api/captcha',
      activeItem: null,
      rechargeNotice:"请选择充值数量",
      profileBalanceInfo: {
        userName:'',
        memberType:'',
        memberName:'',
        memberDueDate:'',
        integration: '',
      },
      integrationDealList:{

      }
    };
  },
  mounted() {
    this.loadCSS('a.css');
    this.$store.commit('setActiveItem', 2);
    console.log("111",this.$store.state.menuItem.activeItem)

    http.get2(profile.getProfileBalance, {}).catch((data) => {
      this.profileBalanceInfo = data.data;
      console.log("profileBalance",data.data)
    }).then(() => {

    })
    http.get2(profile.getIntegrationDealBalance, {}).catch((data) => {
      this.integrationDealList = data.data;
      console.log("integration",data.data)
    }).then(() => {
      console.log("1111",this.integrationDealList.length)
      console.log("2222",this.integrationDealList)
    })


  },
  beforeDestroy() {
    this.unloadCSS('a.css');
  },
  methods: {
    loadCSS(filename) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = require(`../assets/css/${filename}`);
      link.id = filename;
      document.head.appendChild(link);
    },
    unloadCSS(filename) {
      const link = document.getElementById(filename);
      if (link) {
        document.head.removeChild(link);
      }
    },
    setActive(num) {
      this.activeItem = num;
      this.rechargeNotice = "充值"+num+"积分";
    },
    reloadCaptcha() {
      this.captchaUrl = '/web/portal/api/captcha?' + new Date().getTime();
    },

    cdKeyVerify(){
      if (this.verifyCode.length === 0){
        Vue.prototype.$message.warning('验证码不能为空');
        this.reloadCaptcha();

      }else if (this.cdKey.length ===0){
        Vue.prototype.$message.warning('cdKey不能为空');
        this.reloadCaptcha();

      }else if (this.verifyCode.length !==4){
        Vue.prototype.$message.warning('输入的验证码长度有误');
        this.reloadCaptcha();
      }else {
        http.get2('/portal/api/verifyCaptcha', {
          captchaCode: this.verifyCode
        }, null).catch(response =>{
          console.log("验证码结果",response.data)
          if (response.data) {
            try {
              http.post(profile.verifyCdKeyBalance, {
                captchaCode: this.verifyCode,
                cdkey:this.cdKey,
              }, null).then(resp => {
                console.log("验证cdKey结果",resp)
                if (resp.code === 200) {
                  this.$message.success("cdKey兑换成功")
                  location.reload()
                }
              })
            } catch (err) {
              this.$message.error(err);
              this.reloadCaptcha();
            }
          }  else {
            Vue.prototype.$message.warning('验证码错误,请重试');
            this.reloadCaptcha();
          }
        })
      }

    },
  },
}
</script>

<style scoped>
/*.coin-balance-body {*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  background: linear-gradient(50deg, #fff, #fffde9)*/
/*}*/

/*.coin-pay-card {*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  border: 2px solid rgba(0, 0, 0, .1);*/
/*  border-radius: .5rem;*/
/*  overflow: hidden;*/
/*  background: rgba(0, 0, 0, .03);*/
/*  padding: 1rem .3rem;*/
/*  transition: all .3s ease-in-out*/
/*}*/

/*.balance-qiandao {*/
/*  position: absolute;*/
/*  right: .3rem;*/
/*  top: .3rem*/
/*}*/
/*.uc-page .coin-balance-body .balance-info {*/
/*  text-align: center;*/
/*  color: #ff9800*/
/*}*/
/*.uc-page .coin-balance-body .balance-info hr {*/
/*  margin: .5rem 0*/
/*}*/

</style>