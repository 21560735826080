<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <!-- Search result -->
          <div class="wrap__search-result">
            <div class="wrap__search-result-keyword">
              <h5>搜索发现:&nbsp; <span class="text-primary"> {{this.searchDetailPage.searchText}} </span> 共找到 <span style="color: crimson">{{this.searchDetailPage.totalSize}}</span>  个结果 </h5>
            </div>

            <!-- Post Article List -->
            <div v-if="searchDetailPage.totalSize!==0">
              <div  class="card__post card__post-list card__post__transition mt-30" v-for="(item,index) in this.searchDetailPage.detailList" :key="index">
                <div class="row ">
                  <div class="col-md-5">
                    <div class="card__post__transition">
                      <a href="#">
                        <img :src="item.coverPicPath" class="img-fluid w-100" alt="">
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 my-auto pl-0">
                    <div class="card__post__body ">
                      <div class="card__post__content  ">
                        <div class="card__post__category ">
                          {{item.categoryName}}
                        </div>
                        <div class="card__post__author-info mb-2">
                          <ul class="list-inline">
                            <li class="list-inline-item">
                                <span class="text-dark text-capitalize">
                                    {{ item.publish_date| moment }}
                                </span>
                            </li>

                          </ul>
                        </div>
                        <div class="card__post__title">
                          <h5>
                            <a :href="searchDetailPage.prefix+item.id">
                              {{item.title}}
                            </a>
                          </h5>
                          <p class="d-none d-lg-block d-xl-block mb-0">
                            {{item.abstract}}
                          </p>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>




          </div>

          <!-- pagination -->
          <div class="mt-4" v-if="searchDetailPage.totalSize!==0">
            <!-- Pagination -->
            <div class="pagination-area">
              <el-pagination class="pagination wow fadeIn animated"
                             layout="prev, pager, next"
                             @current-change="handleCurrentChange"
                             @prev-click="handlePrevClick"
                             @next-click="handleNextClick"
                             :total="searchDetailPage.totalSize" :current-page="page"   style="margin-right: 20%">
              </el-pagination>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import http from "@/api/http";
import portal from "@/api/portal";
import moment from "moment";

export default {
  name: "searchResult",
  mounted() {
    console.log(this.$store.state.searchStore.searchDetail.searchText)
    console.log(this.$store.state.searchStore.searchDetail.searchType)

  },
  filters: {//自定义的过滤器
    moment(date) {
      return moment(date).format('YYYY/MM/DD');
    }
  },
  methods:{
    // 当前页码改变事件处理函数
    handleCurrentChange(newPage) {
      console.log('当前页: ' + newPage);
      // 可以在这里更新数据，例如重新请求数据
      try {
        http.get2(portal.getSearchResult+"?searchType="+this.$store.state.searchStore.searchDetail.searchType+"&page="+newPage+"&searchText="+this.$store.state.searchStore.searchDetail.searchText, {}).catch((data) => {
          console.log(data)
          this.searchDetailPage = data.data;
        }).then(() => {
          this.page = this.searchDetailPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    // 上一页按钮点击事件处理函数
    handlePrevClick() {
      console.log('上一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.searchDetailPage.page -1
      try {
        http.get2(portal.getSearchResult+"?searchType="+this.$store.state.searchStore.searchDetail.searchType+"&page="+newPage+"&searchText="+this.$store.state.searchStore.searchDetail.searchText, {}).catch((data) => {
          console.log(data)
          this.searchDetailPage = data.data;
        }).then(() => {
          this.page = this.searchDetailPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }
    },
    // 下一页按钮点击事件处理函数
    handleNextClick() {
      console.log('下一页');
      // 可以在这里更新数据，例如重新请求数据
      let newPage;
      newPage = this.searchDetailPage.page +1
      try {
        http.get2(portal.getSearchResult+"?searchType="+this.$store.state.searchStore.searchDetail.searchType+"&page="+newPage+"&searchText="+this.$store.state.searchStore.searchDetail.searchText, {}).catch((data) => {
          console.log(data)
          this.searchDetailPage = data.data;
        }).then(() => {
          this.page = this.searchDetailPage.page
        })
      }catch (err) {
        this.$message.error(err);
      }

    }

  },
  data(){
    return {
      page:1,
      searchDetailPage:{
        prefix:'',
        totalSize:0,
        pageSize:'',
        page:1,
        searchType:'',
        searchText:'',
        detailList:[{
          id:'',
          coverPicPath:'',
          categoryName:'',
          abstract:'',
          author:'',
          publish_date:'',
          title:''
        }]

      },
    }

  }
}
</script>

<style scoped>

</style>