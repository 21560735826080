<template>
  <div>
    <div class="card mx-auto" style="max-width: 520px; margin-top: 5%; margin-bottom: 5%">
      <article class="card-body">
        <header class="mb-4">
          <h4 class="card-title" align="center">注册</h4>
        </header>
        <el-form :model="registerForm" label-width="80px" ref="registerForm" :rules="rules">
          <el-form-item label="用户名" prop="userName" class="form-item-inline">
            <el-input placeholder="请输入您的用户名" v-model="registerForm.userName"></el-input>
          </el-form-item>
<!--          <el-form-item label="昵称" prop="nickName" class="form-item-inline">-->
<!--            <el-input placeholder="请输入您的昵称" v-model="registerForm.nickName"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="邮箱" prop="email" class="form-item-inline">
            <el-input placeholder="请输入您的邮箱" v-model="registerForm.email">
              <el-button :disabled="codeCd"  slot="append" @click="handleCaptcha('email')">
                {{verifySum}}
                <span v-if="codeCd">({{long}})</span>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="verifyCode" class="form-item-inline">
            <el-input class="check-code-box"  v-model.number="registerForm.verifyCode" placeholder="请输入验证码"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" class="form-item-inline">
            <el-input type="password" placeholder="请输入您的密码" v-model="registerForm.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword" class="form-item-inline">
            <el-input type="password" placeholder="请再输入一次您的密码" v-model="registerForm.confirmPassword"></el-input>
          </el-form-item>
        </el-form>
        <div class="form-group" style="width: 90%; margin-left: 5%; margin-top: 3%">
          <button type="button" class="btn btn-primary btn-block" @click="registerEmailClick">注册</button>
        </div>
        <p class="text-center mt-4">已有账号? <a href="/login">登录</a></p>
      </article>
    </div>
    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>
  </div>
</template>

<script>
import http from "@/api/http";
import register from "@/api/register";
import Vue from "vue";
import login from "@/api/login";

export default {
  name: "register",
  data() {
    return {
      codeCd: false,
      long: '0',
      verifySum: "获取验证码",
      registerForm: {
        nickName: '',
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        verifyCode: '',
        picCaptcha: ''
      },
      rules: {
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' },
          { pattern: /^\S+$/, message: '用户名不能包含空格', trigger: 'blur' },
          { pattern: /^[a-zA-Z][a-zA-Z0-9]*$/, message: '用户名只能包含字母和数字且不能以数字开头', trigger: 'blur' },

        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '验证码必须为6个数字', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 8, max: 30, message: '密码长度必须在8到30个字符之间', trigger: 'blur' },
          { pattern: /^\S+$/, message: '密码不能包含空格', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9@#&]*$/, message: '密码只能包含字母、数字和特殊符号@ # &', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (value !== this.registerForm.password) {
                callback(new Error('确认密码必须和密码一致'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ]
      },
      captchaCode: ''
    };
  },

  methods: {
    handleCaptcha(form) {
      if (form === "email") {
        this.$refs['registerForm'].validateField('email', async (valid) => {
          if (valid === '') {
            try {
              this.long = 60;
              http.post(register.registerEmailVerify, {
                email: this.registerForm.email,
              }, null).then((data) => {
                if (data.success) {
                  Vue.prototype.$message.success("已发送验证码");
                  this.codeCd = true;
                  const timer = setInterval(() => {
                    this.long--;
                    if (this.long <= 0) {
                      this.long = 60;
                      this.codeCd = false;
                      this.verifySum = "重新获取";
                      clearInterval(timer);
                    }
                  }, 1000);
                } else {
                  Vue.prototype.$message.error("获取验证码失败");
                }
              });
            } catch (err) {
              Vue.prototype.$message.error("获取验证码失败");
            }
          } else {
            return false;
          }
        });
      }
    },
    registerEmailClick() {

          try {
            http.post(register.registerEmailConfirm, {
              captchaCode: this.registerForm.picCaptcha,
              userName: this.registerForm.userName,
              nickName: this.registerForm.nickName,
              email: this.registerForm.email,
              password: this.registerForm.password,
              password2: this.registerForm.confirmPassword,
              verifyCode: this.registerForm.verifyCode,
            }, null).then((data) => {
              if (data.success) {
                Vue.prototype.$message.success("注册成功，请登录");
                this.$router.push({
                  path: '/login',
                  query: {},  // 传递参数，放在url?后面的
                })
              } else {
                // Vue.prototype.$message.error("获取验证码失败");
              }
            })
          } catch (err) {
            this.$message.error(err);
          }


    },

  }
};
</script>

<style scoped>
.el-form{
  margin-right: 10px;
}
</style>
